import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import {
  CngModalService,
  CngContentConfigType,
  CngModalSize,
} from "@cisco/cui-ng";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationBoxComponent } from "src/app/shared/components/confirmation-box/confirmation-box.component";
import { Location } from "@angular/common";
import { Subject, Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { UrlConstant } from "src/constants/url.constants";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { OrderByPipe } from "./../order-by.pipe";
import { server } from "src/constants/server";
import { ProjectService } from "src/app/services/project.service";
import { v4 as uuidv4 } from "uuid";
import { Router } from "@angular/router";
import { errorCodes } from "src/constants/errorCodes";
import { SaveTemplateComponent } from "src/app/shared/components/save-template/save-template.component";
import { AppConstant } from "src/constants/app.constants";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-dynamic-form",
  templateUrl: "./dynamic-form.component.html",
  styleUrls: ["./dynamic-form.component.css"],
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  dataObject: any;
  @BlockUI() blockUI: NgBlockUI;

  @Output() serverName = new EventEmitter<string>();
  defaultImage = "../../../../assets/images/Cisco-logo.png";
  dynamicForm: UntypedFormGroup;
  objectProps;
  showLoader = false;
  parentPowerSupplyId: any;
  parentPowerSupplyCount: any;
  parentVoltageId: any;
  parentChassisId: any;
  projectId: string;
  subscription: Subscription = new Subscription();

  productId: string;
  optionsObject = {
    processorQuantity: [],
    memoryQuantity: [],
    processorConfiguration: [],
    memoryConfiguration: [],
    iomModulesConfiguration: [],
    powerSupplyQuantity: [],
    powerSupplyConfiguration: [],
    noOfPowerSupplyConfiguration: [],
    ioQuantity: [],
    ioConfiguration: [],
    storageQuantity: [],
    storageConfiguration: [],
    dedicatedStorageQuantity: [],
    dedicatedStorageConfiguration: [],
    expansionsQuantity: [],
    expansionsConfiguration: [],
  };
  errorMsg: any;
  systemWorkLoad: number;
  maxInputPower: any;
  inputPower: any;
  idleInputPower: any;
  inputCurrent: any;
  cooling: any;
  airFlow: any;
  weight: any;
  maxInputPowerUnit: string;
  systemWorkloadUnit: string;
  currentUnit: string;
  airflowUnit: string;
  coolingUnit: string;
  weightUnit: string;
  globalUnit: string;
  projectData: any;
  updateFlag = false;
  server: any;
  selectedVoltage: any;
  coolingImperial: any;
  coolingMetric: any;
  airFlowImperial: any;
  airFlowMetric: any;
  serverWeightImperial: any;
  serverWeightMetric: any;
  sequenceId: string;
  voltage: string;
  addToProjectLabel = "";
  operatingTemp: any;
  hasValidConfig = false;
  previousStorageCount = -1;
  reqData1: any;
  currentServer: any;
  minAirflowImperial: number;
  minAirflowMetric: number;
  maxAirflowImperial: number;
  maxAirflowMetric: number;
  maxAcoustics: number;
  minAcoustics: number;
  acousticsUnit: string;
  hasPowerSupplyStatusWarning = false;
  hasPowerCapWarning = false; //chk once
  selectedFanPolicy: string = "";
  isFormValid: boolean = true;
  isFormIncomplete: boolean = false;
  isFormInvalidated: boolean = false;
  systemWorkloadChanged: Subject<number> = new Subject<number>();
  projectType: string = 'UCS.UserProjects';
  constructor(
    private modalService: CngModalService,
    private translate: TranslateService,
    private _location: Location,
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private toaster: ToasterService,
    private projectService: ProjectService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.projectType = this.router.url.includes('ucs-resizer') ? 'UCS.SizerProjects' : 'UCS.UserProjects';
    this.blockUI.start("Loading...");
    this.addToProjectLabel = this.translate.instant("ADD_TO_PROJECT");
    this.productId = sessionStorage.getItem("currentProductId"); // base product id
    this.sequenceId = sessionStorage.getItem("currentSequenceId"); //product sequence id
    if (!this.sequenceId) this.navigateToProjectDetails("Compute");
    this.voltage = sessionStorage.getItem("voltage"); // input voltage of parent in case of blade n node
    this.projectId = sessionStorage.getItem("myProjectId");
    this.subscription.add(
      this.projectService.currentUnit.subscribe((unit: any) => {
        this.globalUnit = unit
          ? unit
          : localStorage.getItem("UCS.GlobalMeasurmentUnit");
        this.setUnit();
      })
    );

    this.subscription.add(
      this.projectService.getChassisPid().subscribe((data) => {
        if (data && data.length > 0) {
          //used to get parent details in case of blade or cartridge
          this.parentPowerSupplyId = data[1];
          this.parentVoltageId = data[4];
          this.parentPowerSupplyCount = data[2];
        }
      })
    );
    this.subscription.add(
      this.projectService.getCurrentServer().subscribe((data) => {
        this.sequenceId = sessionStorage.getItem("currentSequenceId");
        if (!this.sequenceId) this.navigateToProjectDetails("Compute");
        this.voltage = sessionStorage.getItem("voltage");
        if (data !== null) {
          this.productId = data
            ? data.pid
            : sessionStorage.getItem("currentProductId");
          this.gerServerDetails(this.sequenceId, this.voltage);
          if (data[1] && data[1] == "template") {
            this.server = data[0];
            this.server["template"] = true;
          } else {
            this.server = data;
          }
        } else {
          this.gerServerDetails(this.sequenceId, this.voltage);
        }
      })
    );
    let isFirstChange = false;
    this.systemWorkloadChanged
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((systemWorkload) => {
        if (systemWorkload == this.dataObject?.systemWorkloadPercent && !isFirstChange) {
          isFirstChange = true; return;
        }
        this.calculatePlatformPower(systemWorkload);
      });
  }
  filterUniqueOptions() {
    for (let option of this.dataObject.others) {
      for (let control of option.controls) {
        if (control.label == "Quantity") {
          let options = control.options.filter(
            (e, i) =>
              control.options.findIndex((a) => a["label"] === e["label"]) === i
          );
          control.options = options;
        }
      }
    }
  }
  gerServerDetails(productId: string, voltage: string, isRefresh?: boolean) {
    let url = "";
    if (this.router.url.includes("admin/")) {
      url = UrlConstant.SERVER_DETAILS_ADMIN + `?id=${productId}`;
    } else {
      url = UrlConstant.SERVER_DETAILS + `?id=${productId}`;
    }
    if (isRefresh) {
      this.blockUI.start("Loading..");
    }
    this.subscription.add(
      this.apiService.getMethod(url).subscribe(
        (result: any) => {
          this.dataObject = result;
          /** filter for unique options in additional components */
          this.filterUniqueOptions();
          if (this.dataObject) {
            this.serverName.emit(this.dataObject?.serverName); // emit servername to parent component to dispaly on UI
            this.addTranslations();
            this.bindDropdowns();
            this.server = JSON.parse(sessionStorage.getItem("setCurrentServer"));
            this.updateFlag = this.projectService.getServerFlag();
            let serverEditStatus = JSON.parse(
              sessionStorage.getItem("setServerEditStatus")
            );
            this.updateFlag =
              this.updateFlag ||
              JSON.parse(sessionStorage.getItem("setServerEditStatus")) == true;
            this.server ? this.updateForm() : this.createForm();
            this.blockUI.stop();
          }
        },
        (error) => {
          console.error(error);
          if (error?.error?.message !== undefined) {
            let errorMsg = error?.error?.message;
            if (this.isValidJSON(errorMsg)) {
              let messages = JSON.parse(errorMsg);
              if (messages && messages.messageKey) {
                this.toaster.show("error", messages.messageKey, "");
              }
            } else if (typeof errorMsg == "string" && errorMsg.length > 0) {
              this.toaster.show("error", errorMsg, "");
            } else {
              this.toaster.show("error", errorCodes.GENERIC_CONFIG_ADD_ERROR_MESSAGE, "");
            }
          } else {
            this.errorMsg = error?.error
              ? error.error?.error?.errorString
              : errorCodes.GENERIC_CONFIG_ADD_ERROR_MESSAGE;
            let secondaryMessage = error ? error?.message : "";
            if (secondaryMessage.includes("Http failure response"))
              secondaryMessage = "";
            this.toaster.show("error", this.errorMsg, secondaryMessage, 10000);
          }
          this.subscription.add(this.projectService.setServerEditStatus(false));
          this.subscription.add(this.projectService.setIsAddedOrEdited(false));
          localStorage.setItem("setServerEditStatus", "false");
          sessionStorage.setItem("setServerEditStatus", "false");
          window.scroll(0, 0);
          this._location.back();
          this.blockUI.stop();
        }
      )
    );
  }
  /**
   * Sort alphanumeric options
   * @param options
   * @returns sortedAlphanumericOptions
   */
  getSortedDefaultAlphanumOptions(options, isNum?: boolean, key?: string) {
    if (options && options.length > 0) {
      const defaultOption = options.splice(0, 1);
      if (options.length > 0) {
        if (!(isNum && key)) options = this.alphanumSort(options);
        else if (isNum && key) options = this.numericSort(options, key);
      }
      options.splice(0, 0, defaultOption[0]);
    }
    return options;
  }
  alphanumSort(list: any[]) {
    return list.sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase(), undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
  }
  numericSort(list: any[], key: string) {
    return list.sort((a, b) => Number(a[`${key}`]) - Number(b[`${key}`]));
  }
  sortDependentAlphanumChildOptions(options) {
    if (options && options.length > 0) {
      options.forEach((o) => {
        if (o.childOptions && o.childOptions.length > 0) {
          o.childOptions = this.getSortedDefaultAlphanumOptions(o.childOptions);
        }
      });
    }
  }
  sortDependentAlphanumOptions(groupName: string, controlName: string) {
    let options;
    if (groupName == "storage")
      options = this.getSortedDefaultAlphanumOptions(
        this.optionsObject[groupName + controlName],
        true,
        "capacity"
      );
    else
      options = this.getSortedDefaultAlphanumOptions(
        this.optionsObject[groupName + controlName]
      );
    this.sortDependentAlphanumChildOptions(options);
  }
  getOptionsForEntity(groupName, controlName) {
    this.optionsObject[groupName + controlName] = this.dataObject?.others
      ?.filter((v) => v.group == groupName)[0]
      ?.controls?.filter((x) => x.label == controlName)[0].options;

    // sort options
    if (
      ["processor", "memory", "io", "dedicatedStorage", "storage"].includes(
        groupName
      ) &&
      controlName == "Configuration"
    ) {
      this.sortDependentAlphanumOptions(groupName, controlName);
    }
    // set default quantity in case of MSERIES dedicated storage controller
    if (this.optionsObject[groupName + controlName] && groupName === "dedicatedStorage" && controlName == "Quantity" && this.dataObject?.type == "MSERIES") {
      if (this.optionsObject[groupName + controlName].length <= 1 && this.dataObject?.others
        ?.filter((v) => v.group == groupName)[0].visible == true) {
        let option = JSON.parse(JSON.stringify(this.optionsObject[groupName + controlName][0]));
        option.label = "1"; option.value = 1;
        this.optionsObject[groupName + controlName].push(option);
      }
    }
  }

  bindDropdowns() {
    this.getOptionsForEntity("processor", "Quantity");
    this.getOptionsForEntity("processor", "Configuration");
    this.getOptionsForEntity("memory", "Quantity");
    this.getOptionsForEntity("memory", "Configuration");
    this.getOptionsForEntity("iomModules", "Configuration");
    this.getOptionsForEntity("powerSupply", "Quantity");
    this.getOptionsForEntity("io", "Quantity");
    this.getOptionsForEntity("io", "Configuration");
    this.getOptionsForEntity("storage", "Configuration");
    this.getOptionsForEntity("storage", "Quantity");
    this.getOptionsForEntity("dedicatedStorage", "Configuration");
    this.getOptionsForEntity("dedicatedStorage", "Quantity");
    this.getOptionsForEntity("maxPorts", "Quantity");
    this.getOptionsForEntity("expansions", "Configuration");
    this.getOptionsForEntity("expansions", "Quantity");
    // sort voltage options
    this.sortDependentAlphanumChildOptions(this.dataObject?.voltage?.options);
    this.optionsObject.powerSupplyConfiguration =
      this.dataObject?.voltage?.options[0].childOptions;
    this.optionsObject.noOfPowerSupplyConfiguration =
      this.dataObject?.redundancy?.options[0].childOptions;
    this.optionsObject.powerSupplyQuantity =
      this.dataObject?.redundancy?.options[0].childOptions;
  }

  addTranslations() {
    this.dataObject.others.forEach((element) => {
      element.displayName = this.translate.instant(element.label);
    });

    //options translate
    this.dataObject.others.forEach((element) => {
      element.controls?.forEach((element2) => {
        if (element2.label == "Configuration") {
          if (element2.options?.length > 0) {
            element2.options[0].label = this.translate.instant(
              element2.options[0].label
            );
            element2.options.forEach((options) => {
              if (options.childOptions?.length > 0) {
                options.childOptions[0].label = this.translate.instant(
                  options.childOptions[0].label
                );
              }
            });
          }
        }
      });
    });
    if (this.dataObject?.voltage?.options.length > 0) {
      this.dataObject.voltage.options[0].label = this.translate.instant(
        this.dataObject.voltage.options[0].label
      );
      this.dataObject.voltage.options.forEach((v) => {
        if (v.childOptions?.length > 0) {
          v.childOptions[0].label = this.translate.instant(
            v.childOptions[0].label
          );
        }
      });
      let defaultOption = JSON.parse(JSON.stringify(this.dataObject.voltage.options.find(o => o.value == "")));
      this.dataObject.voltage.options = this.numericSort(this.dataObject.voltage.options.slice(1), "value");
      this.dataObject.voltage.options.unshift(defaultOption);
    }
  }
  createForm() {
    this.systemWorkLoad = this.dataObject?.systemWorkloadPercent;
    this.systemWorkloadChanged.next(this.systemWorkLoad);
    this.operatingTemp = this.dataObject?.defaultOpTemp;
    let staticFields = {
      systemWorkLoad: this.dataObject?.systemWorkloadPercent,
      operatingTemp: this.dataObject?.defaultOpTemp,
      fanFailure: "No",
    };
    if (this.dataObject?.fanPolicies?.visible) {
      staticFields["fanPolicy"] = [
        this.dataObject?.fanPolicies?.options[0],
        "",
      ];
    }
    if (this.dataObject?.voltage?.visible) {
      staticFields["voltage"] = [
        this.dataObject?.voltage?.options[0],
        [this.ValidateControl(this.dataObject?.voltage?.validation)],
      ];
    }
    if (this.dataObject?.redundancy?.visible) {
      staticFields["redundancyMode"] = [
        this.dataObject?.redundancy?.options[0],
      ];
    }
    let dynamicFields = this.createFormArrayOfObjects();

    staticFields = Object.assign(staticFields, dynamicFields);
    this.dynamicForm = this.formBuilder.group(staticFields);
    this.bindAdditionalDropdowns();
    this.isFormValid = this.dynamicForm.valid;
    this.checkForChange();
  }

  private createFormArrayOfObjects() {
    let obj = {};
    for (let arrayGroup of this.dataObject?.others) {
      let controlObj = {};
      if (arrayGroup?.controls && arrayGroup?.visible == true) {
        for (let controls of arrayGroup.controls) {
          let value = this.optionsObject[arrayGroup?.group + controls?.label]
            ? this.optionsObject[arrayGroup?.group + controls?.label][0]
            : "";
          controlObj[controls.label] = new UntypedFormControl(value, [
            this.ValidateControl(controls?.validation),
          ]);
        }
      }
      obj[arrayGroup.group] = this.formBuilder.array([
        new UntypedFormGroup(controlObj),
      ]);
    }
    return obj;
  }

  private createdynamicArrayGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      Quantity: new UntypedFormControl(""),
      Configuration: new UntypedFormControl(""),
    });
  }

  public addProcesoorFormGroup(type: string, i: number) {
    const dynamicArray = this.dynamicForm.get(type) as UntypedFormArray;
    dynamicArray.push(this.createdynamicArrayGroup());
    if (type == "memory") {
      const length = this.dynamicForm.controls["memory"]["controls"].length;
      <UntypedFormGroup>(
        this.dynamicForm.controls["memory"]["controls"][length - 1][
          "controls"
        ].Quantity.setValue(this.optionsObject.memoryQuantity[0])
      );
      <UntypedFormGroup>(
        this.dynamicForm.controls["memory"]["controls"][length - 1][
          "controls"
        ].Configuration.setValue(this.optionsObject.memoryConfiguration[0])
      );
    }
    if (type == "io") {
      const length = this.dynamicForm.controls["io"]["controls"].length;
      <UntypedFormGroup>(
        this.dynamicForm.controls["io"]["controls"][length - 1][
          "controls"
        ].Quantity.setValue(this.optionsObject.ioQuantity[0])
      );
      <UntypedFormGroup>(
        this.dynamicForm.controls["io"]["controls"][length - 1][
          "controls"
        ].Configuration.setValue(this.optionsObject.ioConfiguration[0])
      );
    }
    if (type == "storage") {
      const length = this.dynamicForm.controls["storage"]["controls"].length;
      <UntypedFormGroup>(
        this.dynamicForm.controls["storage"]["controls"][length - 1][
          "controls"
        ].Quantity.setValue(this.optionsObject.storageQuantity[0])
      );
      <UntypedFormGroup>(
        this.dynamicForm.controls["storage"]["controls"][length - 1][
          "controls"
        ].Configuration.setValue(this.optionsObject.storageConfiguration[0])
      );
    }
    if (type == "expansions") {
      const length = this.dynamicForm.controls["expansions"]["controls"].length;
      <UntypedFormGroup>(
        this.dynamicForm.controls["expansions"]["controls"][length - 1][
          "controls"
        ].Quantity.setValue(this.optionsObject.expansionsQuantity[0])
      );
      <UntypedFormGroup>(
        this.dynamicForm.controls["expansions"]["controls"][length - 1][
          "controls"
        ].Configuration.setValue(this.optionsObject.expansionsConfiguration[0])
      );
    }
    if (
      ![
        "memory",
        "io",
        "storage",
        "expansions",
        // "dedicatedStorage",
        "powerSupply",
        "processor",
        "maxPorts",
        "iomModules",
        "noOfPowerSupply",
      ].includes(type)
    ) {
      const length = this.dynamicForm.controls[type]["controls"].length;
      <UntypedFormGroup>(
        this.dynamicForm.controls[type]["controls"][length - 1][
          "controls"
        ].Quantity.setValue(
          this.dataObject.others.find((f) => f.group == type).controls[0]
            .options[0]
        )
      );
      <UntypedFormGroup>(
        this.dynamicForm.controls[type]["controls"][length - 1][
          "controls"
        ].Configuration.setValue(
          this.dataObject.others.find((f) => f.group == type).controls[1]
            .options[0]
        )
      );
    }
  }
  removeRedundancyWarnings() {
    // remove any existing redundancy warnings 
    this.hasPowerSupplyStatusWarning = false;
    this.hasPowerCapWarning = false;

    this.toaster.remove(errorCodes.INSUFFICIENT_REDUNDANCY);
    this.toaster.remove(errorCodes.NON_REDUNDANT);
    this.toaster.remove(errorCodes.POWER_CAPPED);
    this.toaster.remove(errorCodes.ERROR_TITLE);
    this.toaster.remove(errorCodes.WARNING_TITLE);

    if (this.dynamicForm.controls["redundancyMode"]) this.dynamicForm.controls["redundancyMode"].setErrors(null);
    if (this.dynamicForm.controls["powerSupply"] && this.dynamicForm.controls["powerSupply"]["controls"][0]["controls"].Configuration) this.dynamicForm.controls["powerSupply"]["controls"][0]["controls"].Configuration.setErrors(null);
  }
  onSubmit(form) { }

  public async openModal(type: string) {
    let confirmationText = `Do you really want to ${type} configuration for ${this.dataObject?.serverName} ?`;
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText:
              type == "reset"
                ? this.translate.instant(confirmationText)
                : this.translate.instant(confirmationText),
            key: type,
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();
    type == "reset" ? this.resetForm(result) : this.cancelForm(result);
  }
  cancelForm(response: any) {
    if (response == "ok") {
      this.removeRedundancyWarnings();
      this.subscription.add(this.projectService.setServerEditStatus(false));
      this.subscription.add(this.projectService.setIsAddedOrEdited(false));
      localStorage.setItem("setServerEditStatus", "false");
      sessionStorage.setItem("setServerEditStatus", "false");
      this._location.back();
    }
  }
  resetForm(response: any) {
    if (response == "ok") {
      this.removeRedundancyWarnings();
      this.dynamicForm.reset();
      this.bindDropdowns();
      this.createForm();
      this.subscription.add(this.projectService.setCurrentChassis([null]));
      this.addTranslations();
    }
  }

  changeVoltage(event) {
    this.optionsObject.powerSupplyConfiguration =
      this.getSortedDefaultAlphanumOptions(event.childOptions);
    <UntypedFormGroup>(
      this.dynamicForm.controls["powerSupply"]["controls"][0][
        "controls"
      ].Configuration.setValue(event.childOptions[0])
    );
  }
  checkIndependentDropdownsForChange(control) {
    let selectedQty = control?.value?.Quantity?.value;
    let selectedConfig = control?.value?.Configuration?.value;
    /**
     * check for change in independent dropdowns
     * if either they are fully selected or
     * they are not selected at all
     */
    if (
      (selectedQty !== "" && selectedConfig !== "") ||
      (selectedQty == "" && selectedConfig == "")
    ) {
      this.checkForChange();
    }
  }
  checkForChange() {
    let doCheckForChange = true;
    /**
     * for each form group in dynamic form
     * checking whether it's either fully empty / fully filled,
     * otherwise marking it incomplete
     * +corner case when user can add more before filling values
     */
    let formControlsFilled = 0;
    let formControlsTotal = 0;
    Object.keys(this.dynamicForm.controls).forEach((key) => {
      if (Array.isArray(this.dynamicForm.controls[key]?.value)) {
        let countQtyCfg = 0,
          countTotalQtyCfg = 0;
        let isFormInvalid = false;
        for (let value of this.dynamicForm.controls[key]?.value) {
          if (value?.Configuration && value?.Quantity) {
            if (
              typeof value?.Configuration == "object" &&
              typeof value?.Quantity == "object"
            ) {
              if (
                value?.Configuration?.value !== "" &&
                value?.Quantity?.value !== ""
              )
                countQtyCfg += 1;
              countTotalQtyCfg += 1;
              if (
                this.server &&
                (["RACK", "BLADE", "CARTRIDGE", "MSERIES"].includes(
                  this.server.type
                ) || this.server.type.includes("AdditionalPlatform"))
              ) {
                if (
                  (value?.Configuration?.value == "" &&
                    value?.Quantity?.value !== "") ||
                  (value?.Configuration?.value !== "" &&
                    value?.Quantity?.value == "")
                ) {
                  isFormInvalid = true;
                  break;
                }
              }
            }
          } else {
            /**
             * In case form is opened for update
             * but pre-existing data is invalidated
             * set form error and display message.
             *
             * However if the form control is independent
             * the data shall be invalidated, but power
             * will still be calculated as mandatory
             * info is still valid.
             */
            let dataControl = this.dataObject.others.find(
              (d) => d.group == key
            );
            if (dataControl && dataControl.controlType == "controlArray") {
              if (
                !value?.Quantity &&
                this.server &&
                this.server.type == "CHASSIS" &&
                this.updateFlag == true

              ) {
                if (!value?.Configuration) this.setError(key);
              } else {

                //BLADES (BLADE) and NODES (CARTRIDGES) don't require power supply

                if (
                  !(
                    key == "powerSupply" &&
                    this.server &&
                    (this.server.type == "BLADE" ||
                      this.server.type == "CARTRIDGE")
                  )
                ) {
                  if (this.updateFlag) this.setError(key);
                }
              }
            } else if (
              dataControl &&
              dataControl.controlType == "controlIndependent" &&
              ![
                "memory",
                "io",
                "storage",
                "expansions",
                "dedicatedStorage",
                "powerSupply",
                "processor",
                "maxPorts",
                "iomModules",
                "noOfPowerSupply",
              ].includes(key)
            ) {
              /**
               * In case data is invalidated,
               * reset additional formValues to defaults
               */
              this.bindAdditionalDropdowns();
            }
          }
        }
        let isFormComplete = false;
        formControlsFilled += countQtyCfg;
        formControlsTotal += countTotalQtyCfg;
        if (countQtyCfg == 0 || countQtyCfg == countTotalQtyCfg) {
          if (countQtyCfg == 0 && countTotalQtyCfg > 1) {
            isFormComplete = false;
          } else {
            isFormComplete = true;
          }
        }
        if (isFormInvalid) isFormComplete = false;
        if (!isFormComplete) {
          doCheckForChange = false;
        }
      }
    });

    if (
      formControlsTotal == 0 ||
      formControlsFilled > 0 ||
      this.dataObject.type == "CHASSIS"
    ) {
      if (doCheckForChange)
        this.calculatePlatformPower(this.dynamicForm.value.systemWorkLoad);
      else this.isFormIncomplete = true;
    } else this.isFormIncomplete = true;
  }
  onControlChange(event, name, type, control) {
    if (name == "processor") {
      if (type == "Quantity") {
        this.optionsObject.memoryQuantity = OrderByPipe.prototype.transform(
          event.childOptions,
          { property: "value", descending: false }
        );
        for (let i = 0; i < this.dynamicForm.value["memory"].length; i++) {
          this.dynamicForm.controls["memory"]["controls"][0][
            "controls"
          ].Configuration.setValue(this.optionsObject.memoryConfiguration[0]);
          this.dynamicForm.controls["memory"]["controls"][0][
            "controls"
          ].Quantity.setValue(this.optionsObject.memoryQuantity[0]);
          this.dynamicForm.controls["memory"]["controls"].length = 1;
          this.dynamicForm.controls["memory"][
            "controls"
          ][0].controls.Quantity.setValidators(
            this.ValidateControl(
              this.dataObject.others.filter((v) => {
                return v.label == "Memory";
              })[0].controls[0].validation
            )
          );
          this.dynamicForm.controls["memory"][
            "controls"
          ][0].controls.Configuration.setValidators(
            this.ValidateControl(
              this.dataObject.others.filter((v) => {
                return v.label == "Memory";
              })[0].controls[0].validation
            )
          );
          this.dynamicForm.controls["memory"][
            "controls"
          ][0].controls.Quantity.updateValueAndValidity();
        }
      }
      if (type == "Configuration") {
        this.optionsObject.memoryConfiguration = event.childOptions;
        for (let i = 0; i < this.dynamicForm.value["memory"].length; i++) {
          this.dynamicForm.controls["memory"]["controls"][0][
            "controls"
          ].Configuration.setValue(this.optionsObject.memoryConfiguration[0]);
          this.dynamicForm.controls["memory"]["controls"][0][
            "controls"
          ].Quantity.setValue(this.optionsObject.memoryQuantity[0]);
          this.dynamicForm.controls["memory"]["controls"].length = 1;
          this.dynamicForm.controls["memory"][
            "controls"
          ][0].controls.Quantity.setValidators(
            this.ValidateControl(
              this.dataObject.others.filter((v) => {
                return v.label == "Memory";
              })[0].controls[0].validation
            )
          );
          this.dynamicForm.controls["memory"][
            "controls"
          ][0].controls.Configuration.setValidators(
            this.ValidateControl(
              this.dataObject.others.filter((v) => {
                return v.label == "Memory";
              })[0].controls[0].validation
            )
          );
          this.dynamicForm.controls["memory"][
            "controls"
          ][0].controls.Quantity.updateValueAndValidity();
        }
      }
    }
    if (name == "memory") {
      this.checkMemoryControlValidity();
    }
    let selectedQty = control?.value?.Quantity?.value;
    let selectedConfig = control?.value?.Configuration?.value;
    if (
      (selectedQty !== "" && selectedConfig !== "") ||
      (selectedConfig !== "" && this.dataObject.type == "CHASSIS")
    ) {
      this.checkForChange();
    }
  }
  checkMemoryControlValidity() {
    let memory = [];
    let filteredMemory = [];
    memory = this.dynamicForm.value["memory"].map(
      ({ Configuration, Quantity }) => {
        return {
          memoryTypeName: Configuration.value,
          memoryCount: Quantity.value,
          memoryId: Configuration.id,
        };
      }
    );

    filteredMemory = this.filterData(memory);

    if (
      this.dynamicForm.value["memory"].length > 1 &&
      filteredMemory.length >= 1
    ) {
      for (let i = 0; i < this.dynamicForm.value["memory"].length; i++) {
        this.dynamicForm.controls["memory"]["controls"][
          i
        ].controls.Configuration.setValidators(null);
        this.dynamicForm.controls["memory"]["controls"][
          i
        ].controls.Configuration.updateValueAndValidity();
        this.dynamicForm.controls["memory"]["controls"][
          i
        ].controls.Quantity.setValidators(null);
        this.dynamicForm.controls["memory"]["controls"][
          i
        ].controls.Quantity.updateValueAndValidity();
      }
    }
    if (
      this.dynamicForm.value["memory"].length == 1 ||
      filteredMemory.length == 0
    ) {
      this.dynamicForm.controls["memory"][
        "controls"
      ][0].controls.Quantity.setValidators(
        this.ValidateControl(
          this.dataObject.others.filter((v) => {
            return v.label == "Memory";
          })[0].controls[0].validation
        )
      );
      this.dynamicForm.controls["memory"][
        "controls"
      ][0].controls.Configuration.setValidators(
        this.ValidateControl(
          this.dataObject.others.filter((v) => {
            return v.label == "Memory";
          })[0].controls[0].validation
        )
      );
      this.dynamicForm.controls["memory"][
        "controls"
      ][0].controls.Quantity.updateValueAndValidity();
    }
  }

  public removeOrClearProcessor(i: number, type: string) {
    const dynamicArray = this.dynamicForm.get(type) as UntypedFormArray;
    if (dynamicArray.length > 1) {
      dynamicArray.removeAt(i);
      if (type == "memory") {
        this.checkMemoryControlValidity();
      }
    } else {
      dynamicArray.reset();
    }
    this.checkForChange();
  }

  changeRMode(event: any, type: string) {
    if (type == "BLADE" || type == "CHASSIS") {
      this.optionsObject.noOfPowerSupplyConfiguration = event.childOptions; //event.childOptions;
      if (
        typeof this.dynamicForm.controls["noOfPowerSupply"]["controls"] !==
        undefined
      ) {
        <UntypedFormGroup>(
          this.dynamicForm.controls["noOfPowerSupply"]["controls"][0][
            "controls"
          ].Configuration.setValue(event.childOptions[0])
        );
        this.dynamicForm.controls["noOfPowerSupply"]["controls"][0][
          "controls"
        ].Configuration.setValue(event.childOptions[0]);
      }
    }
    if (
      type == "MSERIES" ||
      type == "CHASSIS" ||
      type == "RACK" ||
      type == "FI" ||
      type == "NEXUS"
    ) {
      this.optionsObject.powerSupplyQuantity = event.childOptions;
      <UntypedFormGroup>(
        this.dynamicForm.controls["powerSupply"]["controls"][0][
          "controls"
        ].Quantity.setValue(event.childOptions[0])
      );
      this.dynamicForm.controls["powerSupply"]["controls"][0][
        "controls"
      ].Quantity.setValue(event.childOptions[0]);
      this.isFormIncomplete = true;
    }
    let selectedQty =
      this.dynamicForm.controls["powerSupply"]["controls"][0]["controls"]
        .Quantity?.value?.value;
    let selectedConfig =
      this.dynamicForm.controls["powerSupply"]["controls"][0]["controls"]
        .Configuration?.value?.value;
    if (selectedQty !== "" && selectedConfig !== "") {
      this.checkForChange();
    }
  }

  ValidateControl(validators): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (validators) {
        for (const key of Object.keys(validators)) {
          if (key === "required" && validators[key] == true) {
            if (control?.value?.value == "" || control?.value == "") {
              return { validControl: false };
            }
          }
        }
      }
    };
  }

  checkMaxSlots(formValue: any, apiObject: any) {
    let storageSlots = 0;
    let memorySlots = 0;
    let ioSlots = 0;
    let dedicatedStorageSlots = 0;
    let expansionSlots = 0;
    let maxMemorySlots = { dimmCount: 0 };
    if (formValue.storage) {
      for (let i = 0; i < formValue.storage.length; i++) {
        storageSlots +=
          formValue.storage[i].Quantity && formValue.storage[i].Quantity.value
            ? formValue.storage[i].Quantity.value
            : 0;
      }
      this.showErrorMessage(
        storageSlots,
        apiObject?.maxStorageSlots,
        "storage",
        "storage disks"
      );
    }
    if (formValue.processor) {
      this.showErrorMessage(
        formValue.processor[0].Quantity,
        apiObject?.maxCPUSlots,
        "processor",
        "processors"
      );
    }
    if (formValue.memory) {
      for (let i = 0; i < formValue.memory.length; i++) {
        memorySlots +=
          formValue.memory[i]?.Quantity && formValue.memory[i]?.Quantity?.value
            ? formValue?.memory[i]?.Quantity?.value
            : 0;
      }
      const processorQuantity = formValue.processor[0].Quantity.value;
      if (processorQuantity) {
        maxMemorySlots = apiObject.configs
          .filter(({ cpuCount }) => cpuCount == processorQuantity)
          .reduce((prev, current) => {
            return prev.dimmCount > current.dimmCount ? prev : current;
          });
        this.showErrorMessage(
          memorySlots,
          maxMemorySlots?.dimmCount,
          "memory",
          "memory slots"
        );
      }
    }
    if (formValue.io) {
      for (let i = 0; i < formValue.io.length; i++) {
        ioSlots +=
          formValue?.io[i]?.Quantity && formValue?.io[i]?.Quantity.value
            ? formValue?.io[i]?.Quantity.value
            : 0;
      }
      this.showErrorMessage(
        ioSlots,
        apiObject?.maxIOSlots,
        "io",
        "adapter slots"
      );
    }
    if (
      formValue.dedicatedStorage &&
      formValue.dedicatedStorage.length > 0
    ) {
      for (let i = 0; i < formValue.dedicatedStorage.length; i++) {
        dedicatedStorageSlots +=
          formValue.dedicatedStorage[i].Quantity &&
            formValue.dedicatedStorage[i].Quantity.value
            ? formValue.dedicatedStorage[i].Quantity.value
            : 0;
      }
      this.showErrorMessage(
        dedicatedStorageSlots,
        apiObject?.maxMezzSlots,
        "dedicatedStorage",
        "dedicated storage slots"
      );
    }
    if (formValue.expansions) {
      for (let i = 0; i < formValue.expansions.length; i++) {
        expansionSlots +=
          formValue.expansions[i].Quantity &&
            formValue.expansions[i].Quantity.value
            ? formValue.expansions[i].Quantity.value
            : 0;
      }
      this.showErrorMessage(
        expansionSlots,
        apiObject?.maxExpansionSlots,
        "expansions",
        "expansions slots"
      );
    }
    if (formValue.iomModules || formValue.maxPorts) {
      this.hasValidConfig = true;
    }
  }
  showErrorMessage(
    selectedSlots: number,
    maxSlots: any,
    group: string,
    property: string
  ) {
    if (Number(selectedSlots) && Number(maxSlots) && selectedSlots > maxSlots) {
      if (group == "memory") {
        this.toaster.show(
          "error",
          `${selectedSlots} ` +
          this.translate.instant(
            `selected memory quantity exceeds available`
          ) +
          ` ${maxSlots} ` +
          this.translate.instant(
            `slots with the selected processor quantity`
          ),
          "",
          10000
        );
      } else
        this.toaster.show(
          "error",
          `${selectedSlots} ` +
          this.translate.instant(`selected`) +
          ` ` +
          this.translate.instant(`${property}`) +
          ` ` +
          this.translate.instant(`exceeds available`) +
          ` ${maxSlots}` +
          ` ` +
          this.translate.instant(`slots`),
          "",
          10000
        );
      window.scroll(0, 0);
      this.dynamicForm.get(group)["controls"][0].setErrors("error");
      this.hasValidConfig = false;
    } else {
      this.dynamicForm.get(group)["controls"][0].setErrors(null);
      this.hasValidConfig = true;
    }
  }
  doesAdditionalPlatformExist(i: number) {
    return (
      this.projectData[`AdditionalPlatform${i}`] &&
      this.projectData[`AdditionalPlatform${i}`].length > 0
    );
  }
  getAdditionalPlatform(i: number) {
    return this.projectData[`AdditionalPlatform${i}`];
  }
  doesServerExistInProject(server, projectData) {
    let currentServer;
    let parentSequenceId = sessionStorage.getItem("parentSequenceId");
    switch (server.type) {
      case "FI":
      case "NEXUS":
        currentServer = projectData.fabrics.filter((v) => {
          return v.id == server.id && v.serverId == server.serverId;
        });
        break;
      case "MSERIES":
        currentServer = projectData.mseriesList.filter((v) => {
          return v.id == server.id && v.serverId == server.serverId;
        });
        break;
      case "CHASSIS":
        currentServer = projectData.chassisList.filter((v) => {
          return v.id == server.id && v.serverId == server.serverId;
        });
        break;
      case "RACK":
        currentServer = projectData.rackServers.filter((v) => {
          return v.id == server.id && v.serverId == server.serverId;
        });
        break;
      case "BLADE":
        currentServer = projectData.chassisList.filter((v) => {
          return v.id == parentSequenceId;
        });
        let ind = -1;
        for (let s of currentServer) {
          ind = s.blades.findIndex((v) => v.id == server.id && v.serverId == server.serverId);
          if (s.blades[ind]) return true;
        }
        return false;
      case "CARTRIDGE":
        currentServer = projectData.mseriesList.filter((v) => {
          return v.id == parentSequenceId;
        });
        let idx = -1;
        for (let s of currentServer) {
          idx = s.nodes.findIndex((v) => v.id == server.id && v.serverId == server.serverId);
          if (s.nodes[idx]) return true;
        }
        return false;
      default: {
        let serverFound = false;
        for (let i = 1; i <= 5; i++) {
          if (this.doesAdditionalPlatformExist(i)) {
            let platform = this.getAdditionalPlatform(i);
            for (let s of platform) {
              if (s.serverType == server.type && s.serverId == server.serverId) {
                currentServer = s;
                serverFound = true;
                break;
              }
            }
          }
          if (serverFound) break;
        }
        return currentServer ? true : false;
      }
    }
    return currentServer.length > 0 ? true : false;
  }
  createServerObject(result: any, apidata: any, formValue: any) {
    try {
      this.checkProjectExists();
      this.projectData = JSON.parse(
        localStorage.getItem(`${this.projectType}-${this.projectId}`)
      );
      let serverExistsInProject = false;
      if (this.server) {
        // check if it exists in user projects
        serverExistsInProject = this.doesServerExistInProject(this.server, this.projectData);
      }
      const tempServer = JSON.parse(JSON.stringify(server));
      this.server = JSON.parse(sessionStorage.getItem("setCurrentServer"));
      this.updateFlag = this.server && serverExistsInProject ? true : false;
      this.server = this.server ? this.server : tempServer;
      const guid = uuidv4();
      this.server.serverId =
        this.server.serverId && !this.server.template
          ? this.server.serverId
          : guid;
      this.server = this.server.template
        ? { ...this.server, ...tempServer }
        : this.server;
      this.server.projectReferenceId = this.projectId;
      this.server.projectName = this.projectData?.projectName;
      this.server.displayName = apidata?.serverName;
      this.server.generation = apidata?.generation;
      this.server.userProvidedName = this.server.userProvidedName
        ? this.server.userProvidedName
        : apidata?.serverName;
      this.server.serverType = apidata?.series;
      this.server.seriesDisplayName = result?.seriesName;
      this.server.pid = apidata?.basePid;
      this.server.type = apidata?.type;
      this.server.quantity = 1;
      this.server.level = result?.level;
      this.server.hasChildren = result?.hasChildren;
      this.server.id = apidata.id;
      this.server.primaryClass = apidata.primaryClass;
      if (!this.updateFlag) {
        this.addToProjectLabel = this.translate.instant("ADD_TO_PROJECT");
      }
      if (!this.updateFlag &&
        (this.server.type == "BLADE" || this.server.type == "CARTRIDGE")
      ) {
        this.addToProjectLabel = this.translate.instant("ADD_TO_CHASSIS");
      }
      if (this.updateFlag && this.server.templateId !== "") {
        this.addToProjectLabel = this.translate.instant("UPDATE");
      }
      this.server.config.quantity = this.server.config.quantity
        ? this.server.config.quantity
        : 1;
      this.server.config.systemWorkloadPercent = formValue?.systemWorkLoad; // take from result later
      this.server.config.voltageName = formValue?.voltage
        ? formValue?.voltage.label
        : "";
      this.server.config.voltageValue = formValue?.voltage
        ? formValue?.voltage.value
        : 0;
      this.server.config.inputVoltageId = formValue?.voltage
        ? formValue?.voltage.id
        : 0;
      this.server.config.redundancyMode = formValue?.redundancyMode
        ? formValue?.redundancyMode.value
        : "";
      this.server.config.powerSupplyId =
        formValue.powerSupply && formValue.powerSupply[0].Configuration
          ? formValue?.powerSupply[0].Configuration.id
          : 0;
      this.server.config.powerSupplyTypeName =
        formValue.powerSupply && formValue.powerSupply[0].Configuration
          ? formValue?.powerSupply[0].Configuration.value
          : 0;
      this.server.config.powerSupplyCount =
        formValue?.powerSupply[0]?.Quantity?.value;
      if (apidata.type == "BLADE" || apidata.type == "CHASSIS") {
        if (formValue?.iomModules && formValue?.iomModules.length > 0) {
          this.checkFormValidity(
            formValue?.iomModules[0].Configuration,
            "",
            "iom"
          );
        }
        this.server.config.ioModuleTypeName = formValue?.iomModules
          ? formValue?.iomModules[0].Configuration.value
          : 0;
        this.server.config.iomCount = 2;
        this.server.config.iom = formValue?.iomModules
          ? formValue?.iomModules[0].Configuration.id
          : 0;
        this.server.config.powerSupplyCount = formValue?.noOfPowerSupply
          ? formValue?.noOfPowerSupply[0].Configuration.value
          : 0;
        this.server.config.powerSupplyId =
          formValue?.powerSupply[0]?.Configuration?.id;
        this.server.maxBladeSlots = apidata?.maxBladeSlots;
      }

      this.globalUnit = localStorage.getItem("UCS.GlobalMeasurmentUnit");
      this.systemWorkLoad = formValue?.systemWorkLoad;
      this.systemWorkloadChanged.next(this.systemWorkLoad);
      this.server.power = result;
      this.coolingImperial = result.coolingImperial;
      this.coolingMetric = result.coolingMetric;
      this.airFlowImperial = result.airflowImperial;
      this.airFlowMetric = result.airflowMetric;

      this.maxInputPower = result.maxInputPower;
      this.inputPower = result.inputPower;
      this.idleInputPower = result.idleInputPower;
      this.inputCurrent = result.inputCurrentLoad;
      this.serverWeightImperial = result.weightImperial;
      this.serverWeightMetric = result.weightMetric;

      if (apidata.type == "NEXUS" || apidata.type == "FI") {
        this.server.config.activePorts = formValue.maxPorts[0]?.Quantity.value
          ? formValue.maxPorts[0]?.Quantity.value
          : 0;

        if (formValue.expansions) {
          this.server.config.expansionCards = this.filterData(
            formValue.expansions.map(({ Configuration, Quantity }) => {
              this.checkFormValidity(Configuration, Quantity, "expansions");
              return {
                extenderTypeName: Configuration.value,
                quantity: Quantity.value,
                id: Configuration.id,
              };
            })
          );
          this.server.config.expansionQuantity = 0;
          for (let i = 0; i < formValue.expansions.length; i++) {
            this.server.config.expansionQuantity += formValue.expansions[i]
              .Quantity
              ? formValue.expansions[i].Quantity.value
              : 0;
          }
        }
      }
      if (apidata.type == "MSERIES" || apidata.type == "CARTRIDGE") {
        this.server.config.adapterQuantity = 0;
        this.server.config.storageQuantity = 0;
        if (formValue.storage) {
          this.server.config.storage = this.filterData(
            formValue.storage.map(({ Configuration, Quantity }) => {
              this.checkFormValidity(Configuration, Quantity, "storage");
              return {
                storageTypeName: Configuration.value,
                storageCount: Quantity.value ? Quantity.value : 0,
                storageId: Configuration.id,
              };
            })
          );
          for (let i = 0; i < formValue.storage.length; i++) {
            this.server.config.storageQuantity += formValue.storage[i].Quantity
              ? formValue.storage[i].Quantity?.value
              : 0;
          }
        }
        if (formValue.io) {
          this.server.config.adaptors = this.filterData(
            formValue.io.map(({ Configuration, Quantity }) => {
              this.checkFormValidity(Configuration, Quantity, "io");
              return {
                adaptorTypeName: Configuration.value,
                adaptorCount: Quantity.value ? Quantity.value : 0,
                adaptorId: Configuration.id,
              };
            })
          );
          for (let i = 0; i < formValue.io.length; i++) {
            this.server.config.adapterQuantity += formValue.io[i].Quantity
              ? formValue.io[i].Quantity?.value
              : 0;
          }
        }
        this.server.maxCartridgeSlots = apidata?.maxCartridgeSlots;

        this.server.config.mezzanineControllerTypeName =
          formValue.dedicatedStorage
            ? formValue.dedicatedStorage[0]?.Configuration?.value
            : "";
        this.server.config.mezzanineControllerId = formValue.dedicatedStorage
          ? formValue.dedicatedStorage[0]?.Configuration?.id
          : "";
        this.server.config.mezzanineControllerCount =
          formValue?.dedicatedStorage
            ? Number(formValue?.dedicatedStorage[0]?.Quantity?.value)
            : 0;
        if (formValue.dedicatedStorage) {
          this.updateServerConfig('dedicatedStorage', formValue);
        }
      }
      if (apidata.type == "BLADE" || apidata.type == "CARTRIDGE") {
        /** number of slots consumed */
        this.server.slotsConsumed = apidata?.chassisSlots;
      }
      if (
        formValue.processor &&
        (apidata.type == "RACK" ||
          apidata.type == "BLADE" ||
          apidata.type == "CARTRIDGE" ||
          apidata.type.includes("AdditionalPlatform"))
      ) {
        this.checkFormValidity(
          formValue?.processor[0]?.Configuration,
          formValue?.processor[0]?.Quantity,
          "processor"
        );
        this.server.config.processorId =
          formValue?.processor[0]?.Configuration?.id;
        this.server.config.processorTypeName =
          formValue?.processor[0]?.Configuration?.label;
        this.server.config.processorCount =
          formValue?.processor[0]?.Quantity?.label;
        let totalDimmCount = 0;
        for (let i = 0; i < formValue.memory.length; i++) {
          totalDimmCount += formValue.memory[i].Quantity.value
            ? formValue.memory[i].Quantity.value
            : 0;
        }
        this.server.config.memoryQuantity = totalDimmCount;
        this.server.powerSupplyId =
          formValue.powerSupply && formValue.powerSupply[0].Configuration
            ? formValue?.powerSupply[0].Configuration.id
            : 0;
        this.server.config.powerSupplyTypeName =
          formValue?.powerSupply[0]?.Configuration?.label;
        this.server.config.powerSupplyCount =
          formValue?.powerSupply[0]?.Quantity?.value;
        this.server.config.adapterQuantity = 0;
        this.server.config.storageQuantity = 0;
        this.checkFormValidity(
          formValue?.powerSupply[0]?.Configuration,
          formValue?.powerSupply[0]?.Quantity,
          "powerSupply"
        );
        if (formValue.storage) {
          this.server.config.storage = this.filterData(
            formValue.storage.map(({ Configuration, Quantity }) => {
              this.checkFormValidity(Configuration, Quantity, "storage");
              return {
                storageTypeName: Configuration.value,
                storageCount: Quantity.value ? Quantity.value : 0,
                storageId: Configuration.id,
              };
            })
          );
          for (let i = 0; i < formValue.storage.length; i++) {
            this.server.config.storageQuantity += formValue.storage[i].Quantity
              ? formValue.storage[i].Quantity?.value
              : 0;
          }
        }
        if (formValue.io) {
          this.server.config.adaptors = this.filterData(
            formValue.io.map(({ Configuration, Quantity }) => {
              this.checkFormValidity(Configuration, Quantity, "iomModules");
              return {
                adaptorTypeName: Configuration.value,
                adaptorCount: Quantity.value ? Quantity.value : 0,
                adaptorId: Configuration.id,
              };
            })
          );
          for (let i = 0; i < formValue.io.length; i++) {
            this.server.config.adapterQuantity += formValue.io[i].Quantity
              ? formValue.io[i].Quantity?.value
              : 0;
          }
        }
        this.server.config.memory = this.filterData(
          formValue.memory.map(({ Configuration, Quantity }) => {
            return {
              memoryTypeName: Configuration.value,
              memoryCount: Quantity.value,
              memoryId: Configuration.id,
            };
          })
        );
        this.server.config.mezzanineControllerTypeName =
          formValue.dedicatedStorage
            ? formValue.dedicatedStorage[0]?.Configuration?.value
            : "";
        this.server.config.mezzanineControllerId = formValue.dedicatedStorage
          ? formValue.dedicatedStorage[0]?.Configuration?.id
          : "";
        this.server.config.mezzanineControllerCount = formValue.dedicatedStorage
          ? Number(formValue.dedicatedStorage[0]?.Quantity?.value)
          : 0;
        if (formValue.dedicatedStorage) {
          this.updateServerConfig('dedicatedStorage', formValue);
        }
      }
      if (apidata?.legacyPlatform == false && result?.level == "Parent") {
        this.minAirflowImperial = result?.airFlowRange.minAirflowImperial;
        this.minAirflowMetric = result?.airFlowRange.minAirflowMetric;
        this.maxAirflowImperial = result?.airFlowRange.maxAirflowImperial;
        this.maxAirflowMetric = result?.airFlowRange.maxAirflowMetric;
        this.maxAcoustics = result?.acousticsRange.maxAcoustics;
        this.minAcoustics = result?.acousticsRange.minAcoustics;
      }
      if (apidata?.legacyPlatform == false) {
        const fanPolicyDto = {
          opTemprature: formValue?.operatingTemp,
          fanFailure: formValue?.fanFailure,
          fanPolicy:
            formValue?.fanFailure == "Yes"
              ? "Fan-Failure"
              : formValue?.fanPolicy?.value,
        };
        this.server.config.fanPolicyDto = fanPolicyDto;
      }
      /** add additional components data */
      let additionalCompStrings = ["One", "Two", "Three", "Four", "Five"];
      let idx = 0;
      this.dataObject.others.forEach((d) => {
        let type = d.group;
        if (
          ![
            "memory",
            "io",
            "storage",
            "expansions",
            "dedicatedStorage",
            "powerSupply",
            "processor",
            "maxPorts",
            "iomModules",
            "noOfPowerSupply",
          ].includes(type)
        ) {
          this.server[`additionalComponent${additionalCompStrings[idx]}`] =
            formValue[type].map((f) => {
              return {
                componentId: f?.Configuration?.id,
                quantity: f?.Quantity?.value,
                type: type,
                label: d.label,
                configuration: f?.Configuration?.value,
              };
            });

          idx++;
          if (idx == 5) return;
        }
      });
      localStorage.setItem(`setCurrentServer`, JSON.stringify(this.server));
      sessionStorage.setItem(`setCurrentServer`, JSON.stringify(this.server));
    } catch (error) {
      console.error(error);
      this.dynamicForm.setErrors({ invalid: true });
      this.isFormValid = false;
      this.toaster.show(
        "error",
        errorCodes.ERRROR_CALCULATION,
        errorCodes.GENERIC_ERROR_MESSAGE,
        10000
      );
      window.scroll(0, 0);
    }
    this.setUnit();
  }

  updateServerConfig(type: string, formValue: any) {
    this.server.config.dedicatedStorageQuantity = 0;
    this.server.config.dedicatedStorage = this.filterData(
      formValue.dedicatedStorage.map(({ Configuration, Quantity }) => {
        this.checkFormValidity(Configuration, Quantity, "dedicatedStorage");
        return {
          mezzanineControllerTypeName: Configuration.value,
          mezzanineControllerCount: Quantity.value ? Quantity.value : 0,
          mezzanineControllerId: Configuration.id,
        };
      })
    );
    for (let i = 0; i < formValue.dedicatedStorage.length; i++) {
      this.server.config.dedicatedStorageQuantity += formValue.dedicatedStorage[i].Quantity
        ? formValue.dedicatedStorage[i].Quantity?.value
        : 0;
    }
  }
  /**
   * TODO:
   * @param message message to be displayed
   * To set dynamic form as invalid when data
   * gets invalidated.
   */
  setError(message?: string) {
    if (this.dynamicForm) this.dynamicForm.setErrors({ invalid: true });
    this.isFormValid = false;
    if (message) {
      this.toaster.show(
        "error",
        errorCodes.ERROR_FORM_INVALIDATED,
        errorCodes.ERROR_FORM_INVALIDATED_INFO + message,
        10000
      );
    } else {
      this.toaster.show(
        "error",
        errorCodes.ERRROR_CALCULATION,
        errorCodes.GENERIC_ERROR_MESSAGE,
        10000
      );
    }
  }
  /**
   * Check whether pre-existing quantity or configuration (update form)
   * are currently valid [user updates config from admin].
   * Invoked when form value is set from storage.
   * @param Configuration
   * @param Quantity
   * @param type [specific control type]
   */
  checkFormValidity(Configuration, Quantity, type) {
    /**
     * if server type is chassis : only config required
     * if fabric extenders / interconnects : ports doesn't require config
     */
    let control = this.dynamicForm.controls[type];
    if (
      control &&
      control["controls"][0]["controls"].Quantity &&
      control["controls"][0]["controls"].Configuration
    ) {
      if (
        (!Quantity || (Quantity && Quantity.length == 0)) &&
        this.server &&
        this.server.type !== "CHASSIS"
      )
        this.dynamicForm.controls[type]["controls"][0][
          "controls"
        ].Quantity.setErrors({ invalid: true });
      if (
        (!Configuration || (Configuration && Configuration.length == 0)) &&
        this.server &&
        !["FI", "NEXUS"].includes(this.server.type)
      )
        this.dynamicForm.controls[type]["controls"][0][
          "controls"
        ].Configuration.setErrors({ invalid: true });
    }
  }
  setUnit() {
    this.maxInputPowerUnit =
      this.globalUnit == "Imperial"
        ? this.server?.power?.imperial?.powerUnit
        : this.server?.power?.metric?.powerUnit;
    this.systemWorkloadUnit =
      this.globalUnit == "Imperial"
        ? this.server?.power?.imperial?.systemWorkload
        : this.server?.power?.metric?.systemWorkload;
    this.currentUnit =
      this.globalUnit == "Imperial"
        ? this.server?.power?.imperial?.currentUnit
        : this.server?.power?.metric?.currentUnit;
    this.airflowUnit =
      this.globalUnit == "Imperial"
        ? this.server?.power?.imperial?.airflowUnit
        : this.server?.power?.metric?.airflowUnit;
    this.coolingUnit =
      this.globalUnit == "Imperial"
        ? this.server?.power?.imperial?.coolingUnit
        : this.server?.power?.imperial?.coolingUnit;
    this.weightUnit =
      this.globalUnit == "Imperial"
        ? this.server?.power?.imperial?.weightUnit
        : this.server?.power?.metric?.weightUnit;
    this.acousticsUnit =
      this.globalUnit == "Imperial"
        ? this.server?.power?.imperial?.acousticsUnit
        : this.server?.power?.metric?.acousticsUnit;
  }
  onSystemWorkloadChange(event) {
    this.systemWorkLoad = event.value;
    this.systemWorkloadChanged.next(this.systemWorkLoad);
    this.dynamicForm["controls"].systemWorkLoad.patchValue(event.value);
    // this.calculatePlatformPower(event.value);
  }
  checkProjectExists() {
    let projectType = this.router.url.includes('ucs-resizer') ? 'UCS.SizerProjects' : 'UCS.UserProjects';
    if (!JSON.parse(localStorage.getItem(`${projectType}-${this.projectId}`))) {
      if (projectType.includes('Sizer')) {
        this.router.navigate(["/ucs-resizer/projectList"]);
      } else this.router.navigate(["/projectList"]);
    }
  }
  addToProject(event: any, id: string, type: string) {
    this.checkProjectExists();
    if (event.target.innerText == "Update") {
      this.updateProjectData(id, type);
    } else {
      this.projectData = JSON.parse(
        localStorage.getItem(`${this.projectType}-${this.projectId}`)
      );
      const parentServerId = sessionStorage.getItem("editServerId");
      const paretSequenceId = sessionStorage.getItem("parentSequenceId");
      switch (this.server.type) {
        case "CHASSIS":
          this.server.index = this.projectData.chassisList
            ? this.projectData.chassisList.length + 1
            : 1;
          this.projectData.chassisList.push(this.server);
          this.toaster.show("success", errorCodes.ADD_BLADE_MESSAGE, "", 20000);
          window.scroll(0, 0);
          break;
        case "RACK":
          this.server.index = this.projectData.rackServers
            ? this.projectData.rackServers.length + 1
            : 1;
          this.projectData.rackServers.push(this.server);
          break;
        case "MSERIES":
          this.server.index = this.projectData.mseriesList
            ? this.projectData.mseriesList.length + 1
            : 1;
          this.projectData.mseriesList.push(this.server);
          this.toaster.show("success", errorCodes.ADD_NODE_MESSAGE, "", 20000);
          window.scroll(0, 0);
          break;
        case "CARTRIDGE":
          let modularServer = this.projectData.mseriesList.filter((v) => {
            return v.id == paretSequenceId && v.serverId == parentServerId;
          });
          this.server.chassisId = paretSequenceId;
          this.server.index = modularServer[0].nodes.length;
          modularServer[0].nodes.push(this.server);
          this.subscription.add(this.projectService.setCurrentChassis([null]));
          break;
        case "BLADE":
          let chassis = this.projectData.chassisList.filter((v) => {
            return v.id == paretSequenceId && v.serverId == parentServerId;
          });
          this.server.chassisId = paretSequenceId;
          this.server.index = chassis[0].blades.length;
          chassis[0].blades.push(this.server);
          this.subscription.add(this.projectService.setCurrentChassis([null]));
          break;
        case "NEXUS":
        case "FI":
          this.server.index = this.projectData.fabrics
            ? this.projectData.fabrics.length + 1
            : 1;
          this.projectData.fabrics.push(this.server);
          break;
        default: {
          if (!this.projectData[`${this.server.type}`])
            this.projectData[`${this.server.type}`] = [];
          this.server.index =
            this.projectData[`${this.server.type}`].length + 1;
          this.projectData[`${this.server.type}`].push(this.server);
        }
      }

      localStorage.setItem(
        `${this.projectType}-${this.projectId}`,
        JSON.stringify(this.projectData)
      );
      this.subscription.add(
        this.projectService.setProjectData(this.projectData)
      );

      setTimeout(() => {
        this.navigateToProjectDetails("Compute");
      }, 200);
    }
    if (this.server) {
      localStorage.setItem("currentServerType", this.server.type);
      localStorage.setItem("currentServerId", this.server.serverId);
      sessionStorage.setItem("currentServerType", this.server.type);
      sessionStorage.setItem("currentServerId", this.server.serverId);
      this.projectService.setIsAddedOrEdited(true);
    }
  }
  updateProjectData(serverId: string, type: string) {
    this.projectData = JSON.parse(
      localStorage.getItem(`${this.projectType}-${this.projectId}`)
    );
    let curServer = JSON.parse(sessionStorage.getItem(`setCurrentServer`));
    if (curServer) this.server = curServer;
    const parentServerId = sessionStorage.getItem("editServerId");
    const paretSequenceId = sessionStorage.getItem("parentSequenceId");
    let ind = 0;
    switch (type) {
      case "FI":
      case "NEXUS":
        ind = this.projectData.fabrics.findIndex((v) => {
          return v.serverId == serverId;
        });
        this.projectData.fabrics[ind] = this.server;
        break;
      case "MSERIES":
        ind = this.projectData.mseriesList.findIndex((v) => {
          return v.serverId == serverId;
        });
        this.projectData.mseriesList[ind] = this.server;
        this.toaster.show("success", errorCodes.ADD_NODE_MESSAGE, "", 20000);
        window.scroll(0, 0);
        break;
      case "CHASSIS":
        ind = this.projectData.chassisList.findIndex((v) => {
          return v.serverId == serverId;
        });
        this.projectData.chassisList[ind] = this.server;
        this.toaster.show("success", errorCodes.ADD_BLADE_MESSAGE, "", 20000);
        window.scroll(0, 0);
        break;
      case "RACK":
        ind = this.projectData.rackServers.findIndex((v) => {
          return v.serverId == serverId;
        });
        this.projectData.rackServers[ind] = this.server;
        break;
      case "CARTRIDGE":
        let modularServer = this.projectData.mseriesList.filter((v) => {
          return v.id == paretSequenceId;
        });
        let curModular = modularServer.find((m) =>
          m.nodes.find((v) => v.serverId == serverId)
        );
        let idxModular = curModular.nodes.findIndex(
          (v) => v.serverId == serverId
        );
        curModular.nodes[idxModular] = this.server;
        break;
      case "BLADE":
        let chassis = this.projectData.chassisList.filter((v) => {
          return v.id == paretSequenceId;
        });
        let curChassis = chassis.find((c) =>
          c.blades.find((v) => v.serverId == serverId)
        );
        let idxChassis = curChassis.blades.findIndex(
          (v) => v.serverId == serverId
        );
        curChassis.blades[idxChassis] = this.server;
        break;
      default: {
        if (!this.projectData[`${this.server.type}`]) {
          this.projectData[`${this.server.type}`] = [];
          this.projectData[`${this.server.type}`].push(this.server);
        } else {
          let idxAdditionalPlatform = this.projectData[
            `${this.server.type}`
          ].findIndex((v) => v.serverId == serverId);
          this.projectData[`${this.server.type}`][idxAdditionalPlatform] =
            this.server;
        }
      }
    }
    localStorage.setItem(
      `${this.projectType}-${this.projectId}`,
      JSON.stringify(this.projectData)
    );
    this.subscription.add(this.projectService.setProjectData(this.projectData));
    this.subscription.add(this.projectService.setServerEditStatus(false));
    localStorage.setItem("setServerEditStatus", "false");
    sessionStorage.setItem("setServerEditStatus", "false");
    setTimeout(() => {
      this.navigateToProjectDetails("Compute");
    }, 200);
  }
  navigateToProjectDetails(fragment?: string) {
    if (this.router.url.includes('admin/')) {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/admin/ucs-resizer/projectDetails"], { fragment: fragment });
      } else this.router.navigate(["/admin/projectDetails"], { fragment: fragment });
    } else {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/ucs-resizer/projectDetails"], { fragment: fragment });
      } else this.router.navigate(["/projectDetails"], { fragment: fragment });
    }
  }
  updateForm() {
    if (!this.server?.template) {
      this.addToProjectLabel = this.translate.instant("UPDATE");
    } else {
      this.addToProjectLabel = this.translate.instant("ADD_TO_PROJECT");
    }
    // this.systemWorkLoad = this.server?.config?.systemWorkloadPercent;
    this.systemWorkLoad = this.projectService.calculateSystemWorkloadForChassis(this.server);
    this.systemWorkloadChanged.next(this.systemWorkLoad);

    let serverOpTemp = this.server?.config?.fanPolicyDto?.opTemprature;
    let dataObjectDefaultOpTemp = this.dataObject?.defaultOpTemp;
    let operatingTemp =
      serverOpTemp !== undefined && serverOpTemp !== null
        ? serverOpTemp
        : dataObjectDefaultOpTemp;
    let fanFailureDefault = "No";
    let serverFanFailure = this.server?.config?.fanPolicyDto?.fanFailure;
    let fanFailure =
      serverFanFailure !== undefined && serverFanFailure !== null
        ? serverFanFailure
        : fanFailureDefault;

    let staticFields = {
      // systemWorkLoad: this.server?.config?.systemWorkloadPercent,
      systemWorkLoad: this.projectService.calculateSystemWorkloadForChassis(this.server)
    };

    if (operatingTemp !== null && operatingTemp !== undefined)
      staticFields["operatingTemp"] = operatingTemp;
    if (fanFailure !== null && fanFailure !== undefined)
      staticFields["fanFailure"] = fanFailure;

    let serverFanPolicy = this.server?.config?.fanPolicyDto?.fanPolicy;
    let dataObjectdefaultFanPolicy = this.dataObject?.fanPolicies?.options[0];
    this.selectedFanPolicy =
      serverFanPolicy !== undefined && serverFanPolicy !== null
        ? serverFanPolicy
        : dataObjectdefaultFanPolicy;
    let selectedIdx = 0;
    let fanPolicyValue = "";
    if (
      this.dataObject?.fanPolicies?.options !== null &&
      this.dataObject?.fanPolicies?.options !== undefined
    ) {
      let fanPolicyOpts = this.dataObject?.fanPolicies?.options;
      for (let i = 0; i < fanPolicyOpts.length; i++)
        if (fanPolicyOpts[i].label == this.selectedFanPolicy) {
          selectedIdx = i;
          break;
        }
      if (this.dataObject?.fanPolicies?.visible) {
        staticFields["fanPolicy"] = [
          this.dataObject?.fanPolicies?.options[selectedIdx],
          "",
        ];
        fanPolicyValue = fanPolicyOpts[selectedIdx];
      }
    }
    let bindVoltage = [];
    if (this.dataObject.voltage && this.dataObject?.voltage?.visible) {
      bindVoltage = this.dataObject?.voltage.options.filter(
        (v) => v.value == this.server?.config?.voltageValue
      );
      if (bindVoltage.length == 0) {
        bindVoltage = [this.dataObject?.voltage.options[0]];
        this.setError("Input Voltage");
      }

      staticFields["voltage"] = [bindVoltage[0]];
    }

    let rmode = [];
    if (this.dataObject?.redundancy?.visible) {
      rmode = this.dataObject?.redundancy.options.filter(
        (v) => v.value == this.server?.config?.redundancyMode
      );
      if (rmode.length == 0) {
        rmode = [this.dataObject?.redundancy.options[0]];
        this.setError("Redundancy Mode");
      }
      staticFields["redundancyMode"] = [rmode[0]];
    }

    let dynamicFields = this.createFormArrayOfObjects();

    staticFields = Object.assign(staticFields, dynamicFields);
    this.dynamicForm = this.formBuilder.group(staticFields);
    if (fanPolicyValue !== "")
      this.dynamicForm.controls["fanPolicy"].setValue(fanPolicyValue);
    let bindPowerSupply = [];
    if (this.dataObject?.type == "CHASSIS") {
      if (
        typeof this.dynamicForm.controls["noOfPowerSupply"]["controls"] !==
        undefined
      ) {
        this.optionsObject.noOfPowerSupplyConfiguration = rmode[0].childOptions;
        let bindConfiguration = rmode[0].childOptions.filter(
          (v) => v.value == this.server?.config?.powerSupplyCount
        );
        if (bindConfiguration.length == 0) {
          bindConfiguration = rmode[0].childOptions;
          this.setError("Number of Power Supplies");
        }
        <UntypedFormGroup>(
          this.dynamicForm.controls["noOfPowerSupply"]["controls"][0][
            "controls"
          ].Configuration.setValue(bindConfiguration[0])
        );
      }
    }
    if (
      this.dataObject?.type == "MSERIES" ||
      this.dataObject?.type == "RACK" ||
      this.dataObject?.type.includes("AdditionalPlatform") ||
      this.dataObject?.type == "NEXUS" ||
      (this.dataObject?.type == "FI" && this.dataObject?.type !== "CARTRIDGE")
    ) {
      bindPowerSupply = rmode[0].childOptions.filter(
        (v) => v.value == this.server?.config?.powerSupplyCount
      );
      if (bindPowerSupply.length == 0) {
        bindPowerSupply = [rmode[0].childOptions[0]];
        this.setError("Power Supply Quantity");
      }
      this.optionsObject.powerSupplyQuantity = rmode[0].childOptions;
      <UntypedFormGroup>(
        this.dynamicForm.controls["powerSupply"]["controls"][0][
          "controls"
        ].Quantity.setValue(bindPowerSupply[0])
      );
    }
    if (this.dataObject.voltage) {
      let powerSupply = bindVoltage[0].childOptions.filter(
        (v) => v.value == this.server?.config?.powerSupplyTypeName
      );
      if (powerSupply.length == 0) {
        powerSupply = [bindVoltage[0].childOptions[0]];
        this.setError("Power Supply Configuration");
      }
      this.optionsObject.powerSupplyConfiguration = bindVoltage[0].childOptions;
      <UntypedFormGroup>(
        this.dynamicForm.controls["powerSupply"]["controls"][0][
          "controls"
        ].Configuration.setValue(powerSupply[0])
      );
      this.checkFormValidity(powerSupply[0], bindPowerSupply[0], "powerSupply");
    }
    if (this.dataObject?.type == "CHASSIS") {
      if (
        this.dataObject?.others.filter((v) => {
          return v.group == "iomModules";
        }).length > 0
      ) {
        let k = this.dataObject?.others.filter((v) => {
          return v.group == "iomModules";
        })[0].controls[1].options;
        let ioModule = k.filter(
          (v) => v.value == this.server?.config?.ioModuleTypeName
        );
        if (ioModule.length == 0) {
          this.setError("IO Module");
          ioModule = k;
        }
        <UntypedFormGroup>(
          this.dynamicForm.controls["iomModules"]["controls"][0][
            "controls"
          ].Configuration.setValue(ioModule[0])
        );
      }
    }
    if (this.dataObject?.type == "FI" || this.dataObject?.type == "NEXUS") {
      this.bindQuantityOnlyDD(this.dataObject.others, "maxPorts");
      this.bindExpandableDropdowns(
        "expansions",
        "extenderTypeName",
        "quantity",
        "expansions",
        "expansionCards"
      );
    }
    if (
      this.dataObject?.type == "RACK" ||
      this.dataObject?.type.includes("AdditionalPlatform") ||
      this.dataObject?.type == "BLADE" ||
      this.dataObject?.type == "CARTRIDGE"
    ) {
      if (
        this.dataObject?.others.filter((v) => {
          return v.group == "processor";
        }).length > 0
      ) {
        let bindProcessorQuantity = this.dataObject?.others
          .filter((v) => {
            return v.group == "processor";
          })[0]
          .controls[0].options.filter(
            (v) => v.value == this.server?.config?.processorCount
          );
        let bindProcessorConfiguration = this.dataObject?.others
          .filter((v) => {
            return v.group == "processor";
          })[0]
          .controls[1].options.filter(
            (v) => v.value == this.server?.config?.processorTypeName
          );
        if (
          bindProcessorQuantity.length == 0 ||
          bindProcessorConfiguration.length == 0
        ) {
          bindProcessorQuantity = [
            this.dataObject?.others.filter((v) => {
              return v.group == "processor";
            })[0].controls[0].options[0],
          ];
          bindProcessorConfiguration = [
            this.dataObject?.others.filter((v) => {
              return v.group == "processor";
            })[0].controls[1].options[0],
          ];
          this.setError("Processor");
        }
        if (
          bindProcessorQuantity.length > 0 &&
          bindProcessorConfiguration.length > 0
        ) {
          <UntypedFormGroup>(
            this.dynamicForm.controls["processor"]["controls"][0][
              "controls"
            ].Quantity.setValue(bindProcessorQuantity[0])
          );
          <UntypedFormGroup>(
            this.dynamicForm.controls["processor"]["controls"][0][
              "controls"
            ].Configuration.setValue(bindProcessorConfiguration[0])
          );
        }
        this.checkFormValidity(
          bindProcessorConfiguration[0],
          bindProcessorQuantity[0],
          "processor"
        );
        let isInvalidMemory = false;
        const bindMemoryConfiguration = this.server?.config.memory.map((p) => {
          if (
            !bindProcessorConfiguration ||
            bindProcessorConfiguration.length == 0
          )
            return undefined;
          let x = bindProcessorConfiguration[0].childOptions.filter((v) => {
            return v.value == p.memoryTypeName;
          });
          if (x && x.length > 0) return x[0];
          if (x && x.length == 0) {
            isInvalidMemory = true;
            return bindProcessorConfiguration[0].childOptions[0];
          }
          return undefined;
        });
        const bindMemoryQuantity = this.server?.config.memory.map((p) => {
          if (!bindProcessorQuantity || bindProcessorQuantity.length == 0)
            return undefined;
          let x = bindProcessorQuantity[0].childOptions.filter((v) => {
            return v.value == p.memoryCount;
          });
          if (x && x.length > 0) return x[0];
          if (x && x.length == 0) {
            isInvalidMemory = true;
            return bindProcessorQuantity[0].childOptions[0];
          }
          return undefined;
        });
        if (isInvalidMemory) {
          this.setError("Memory");
        }
        this.optionsObject.memoryConfiguration =
          bindProcessorConfiguration.length > 0
            ? bindProcessorConfiguration[0].childOptions
            : [];
        this.optionsObject.memoryQuantity =
          bindProcessorQuantity.length > 0
            ? bindProcessorQuantity[0].childOptions
            : [];
        for (let i = 0; i < bindMemoryConfiguration.length; i++) {
          if (i > 0) {
            this.addProcesoorFormGroup("memory", 0);
          }
          <UntypedFormGroup>(
            this.dynamicForm.controls["memory"]["controls"][i][
              "controls"
            ].Quantity.setValue(bindMemoryQuantity[i])
          );
          <UntypedFormGroup>(
            this.dynamicForm.controls["memory"]["controls"][i][
              "controls"
            ].Configuration.setValue(bindMemoryConfiguration[i])
          );
          this.checkFormValidity(
            bindMemoryConfiguration,
            bindMemoryQuantity,
            "memory"
          );
        }
      }
    }
    if (
      this.dataObject?.type == "RACK" ||
      this.dataObject?.type.includes("AdditionalPlatform") ||
      this.dataObject?.type == "MSERIES" ||
      this.dataObject?.type == "CARTRIDGE" ||
      this.dataObject?.type == "BLADE"
    ) {
      this.bindExpandableDropdowns("dedicatedStorage", "mezzanineControllerTypeName", "mezzanineControllerCount", "dedicatedStorage", "dedicatedStorage");
    }
    if (
      this.dataObject?.type == "RACK" ||
      this.dataObject?.type.includes("AdditionalPlatform") ||
      this.dataObject?.type == "MSERIES" ||
      this.dataObject?.type == "CARTRIDGE" ||
      this.dataObject?.type == "BLADE"
    ) {
      if (
        this.dataObject.others.filter((v) => {
          return v.group == "storage";
        }).length > 0
      )
        this.bindExpandableDropdowns(
          "storage",
          "storageTypeName",
          "storageCount",
          "storage",
          "storage"
        );
    }
    if (
      this.dataObject?.type == "RACK" ||
      this.dataObject?.type.includes("AdditionalPlatform") ||
      this.dataObject?.type == "MSERIES" ||
      this.dataObject?.type == "CARTRIDGE" ||
      this.dataObject?.type == "BLADE"
    ) {
      this.dataObject.others.filter((v) => {
        return v.group == "io";
      }).length > 0;
      this.bindExpandableDropdowns(
        "io",
        "adaptorTypeName",
        "adaptorCount",
        "io",
        "adaptors"
      );
    }
    /** bind additional dropdowns on refresh
     *  if data already present defaults
     *  are overwritten
     */
    this.bindAdditionalDropdowns();
    // this.cdRef.detectChanges();

    /** bind additional dropdowns if already present */
    let additionalCompStrings = ["One", "Two", "Three", "Four", "Five"];
    let idx = 0;
    this.dataObject.others.forEach((d) => {
      let type = d.group;
      if (
        ![
          "memory",
          "io",
          "storage",
          "expansions",
          "dedicatedStorage",
          "powerSupply",
          "processor",
          "maxPorts",
          "iomModules",
          "noOfPowerSupply",
        ].includes(type)
      ) {
        if (this.server[`additionalComponent${additionalCompStrings[idx]}`]) {
          for (
            let i = 0;
            i <
            this.server[`additionalComponent${additionalCompStrings[idx]}`]
              .length;
            i++
          ) {
            let componentId =
              this.server[`additionalComponent${additionalCompStrings[idx]}`][i]
                .componentId;
            let quantity =
              this.server[`additionalComponent${additionalCompStrings[idx]}`][i]
                .quantity;
            if (componentId && quantity) {
              if (i < this.dynamicForm.controls[type]["controls"].length) {
                <UntypedFormGroup>(
                  this.dynamicForm.controls[type]["controls"][i][
                    "controls"
                  ].Quantity.setValue(
                    d.controls[0].options.find((o) => o.value == quantity)
                  )
                );
                <UntypedFormGroup>(
                  this.dynamicForm.controls[type]["controls"][i][
                    "controls"
                  ].Configuration.setValue(
                    d.controls[1].options.find((o) => o.id == componentId)
                  )
                );
              } else {
                this.addProcesoorFormGroup(type, i);
                <UntypedFormGroup>(
                  this.dynamicForm.controls[type]["controls"][i][
                    "controls"
                  ].Quantity.setValue(
                    d.controls[0].options.find((o) => o.value == quantity)
                  )
                );
                <UntypedFormGroup>(
                  this.dynamicForm.controls[type]["controls"][i][
                    "controls"
                  ].Configuration.setValue(
                    d.controls[1].options.find((o) => o.id == componentId)
                  )
                );
              }
            }
            this.checkFormValidity(
              d.controls[1].options.find((o) => o.id == componentId),
              d.controls[0].options.find((o) => o.value == quantity),
              type
            );
          }
        }
        idx++;
        if (idx == 5) return;
      }
    });

    this.checkForChange();
  }
  bindAdditionalDropdowns() {
    this.dataObject.others.forEach((d) => {
      let type = d.group;
      if (
        ![
          "memory",
          "io",
          "storage",
          "expansions",
          "dedicatedStorage",
          "powerSupply",
          "processor",
          "maxPorts",
          "iomModules",
          "noOfPowerSupply",
          "portsConfigured",
        ].includes(type)
      ) {
        const length = this.dynamicForm.controls[type]["controls"].length;
        <UntypedFormGroup>(
          this.dynamicForm.controls[type]["controls"][length - 1][
            "controls"
          ].Quantity.setValue(d.controls[0].options[0])
        );
        <UntypedFormGroup>(
          this.dynamicForm.controls[type]["controls"][length - 1][
            "controls"
          ].Configuration.setValue(d.controls[1].options[0])
        );
        this.checkFormValidity(
          d.controls[1].options[0],
          d.controls[0].options[0],
          type
        );
      }
    });
  }

  bindQuantityOnlyDD(inputobject: any, group: string) {
    if (
      inputobject.filter((v) => {
        return v.group == group;
      }).length > 0
    ) {
      let k = inputobject.filter((v) => {
        return v.group == group;
      })[0].controls[0].options;
      let bindQuantity = k.filter(
        (v) => v.value == this.server.config.activePorts
      );
      // set to defaults -- non mandatory
      if (bindQuantity.length == 0) {
        bindQuantity = k;
      }
      <UntypedFormGroup>(
        this.dynamicForm.controls[group]["controls"][0][
          "controls"
        ].Quantity.setValue(bindQuantity[0])
      );
      this.checkFormValidity("", bindQuantity[0], group);
    }
  }
  bindIndependentDropdowns(group: string) {
    if (
      this.dataObject?.others.filter((v) => {
        return v.group == group;
      }).length > 0
    ) {
      let bindQuantity = this.dataObject?.others
        .filter((v) => {
          return v.group == group;
        })[0]
        .controls[0].options.filter(
          (v) =>
            Number(v.value) ==
            (Number(this.server.config.mezzanineControllerCount)
              ? Number(this.server.config.mezzanineControllerCount)
              : 0)
        );
      let bindConfiguration = this.dataObject?.others
        .filter((v) => {
          return v.group == group;
        })[0]
        .controls[1].options.filter(
          (v) =>
            v.value ==
            (this.server.config.mezzanineControllerTypeName
              ? this.server.config.mezzanineControllerTypeName
              : "")
        );
      /**
       * For independent dropdowns, its not required to set error
       * since they are not mandatory and can display the
       * correct calculations.
       */
      if (bindConfiguration.length == 0 || bindQuantity.length == 0) {
        bindQuantity = [
          this.dataObject?.others.filter((v) => {
            return v.group == group;
          })[0].controls[0].options[0],
        ];
        bindConfiguration = [
          this.dataObject?.others.filter((v) => {
            return v.group == group;
          })[0].controls[1].options[0],
        ];
      }
      <UntypedFormGroup>(
        this.dynamicForm.controls[group]["controls"][0][
          "controls"
        ].Quantity.setValue(bindQuantity[0])
      );
      <UntypedFormGroup>(
        this.dynamicForm.controls[group]["controls"][0][
          "controls"
        ].Configuration.setValue(bindConfiguration[0])
      );
      this.checkFormValidity(bindConfiguration[0], bindQuantity[0], group);
    }
  }
  bindExpandableDropdowns(
    group: string,
    name: string,
    quantity: string,
    controlName: string,
    configPropName: string
  ) {
    /**
     * In case expandable options have been removed
     * and the controls are not present, return.
     */
    let matchingControl = this.dataObject.others.filter((v) => {
      return v.group == controlName;
    });
    if (matchingControl.length == 0) {
      return;
    }
    let bindQuantity = this.server.config[configPropName].map((p) => {
      let k = this.dataObject.others.filter((v) => {
        return v.group == controlName;
      })[0].controls[0].options;
      let xx = k.filter((xx) => p[quantity] == xx.value);
      if (xx && xx.length > 0) return xx[0];
      if (xx && xx.length == 0) return k[0];
      return undefined;
    });
    let bindConfiguration = this.server.config[configPropName].map((p) => {
      let k = this.dataObject.others.filter((v) => {
        return v.group == controlName;
      })[0].controls[1].options;
      let xx = k.filter((xx) => p[name] == xx.value);
      if (xx && xx.length > 0) return xx[0];
      if (xx && xx.length == 0) return k[0];
      return undefined;
    });
    for (let i = 0; i < bindConfiguration.length; i++) {
      if (i > 0) {
        this.addProcesoorFormGroup(group, 0);
      }
      <UntypedFormGroup>(
        this.dynamicForm.controls[controlName]["controls"][i][
          "controls"
        ].Quantity.setValue(bindQuantity[i])
      );
      <UntypedFormGroup>(
        this.dynamicForm.controls[controlName]["controls"][i][
          "controls"
        ].Configuration.setValue(bindConfiguration[i])
      );
      this.checkFormValidity(bindConfiguration[i], bindQuantity[i], group);
    }
  }

  public async openSaveTemplateModal(type, attributes, formData) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: SaveTemplateComponent,
          componentData: {
            key: "",
            type: type,
            attributes: attributes[0].seriesValues[0],
            config: this.server.config,
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();
    if (result !== "cloased") {
      this.saveTemplate(result);
    }
  }
  saveTemplate(result: any) {
    let template = result[2];
    template.originalName = result[2].name;
    template.name = result[0];
    template.config = result[3];
    template.discont = result[2].discont;
    template.available = true;
    template.templateId = uuidv4();
    if (template && result[1]) {
      this.setTemplateValue(result[1], template, result[0]);
    }
    this.projectService.setReqPayload(null);
  }
  setTemplateValue(type: string, template: any, templateName: string) {
    let temp;
    let localStorageKey = AppConstant.TEMPLATE + type;
    const errorMessage =
      this.translate.instant(errorCodes.SUCCESSFULLY_CREATED) +
      " " +
      templateName;
    if (localStorage[localStorageKey]) {
      temp = JSON.parse(localStorage.getItem(localStorageKey));
      temp.templates.push(template);
      localStorage.setItem(localStorageKey, JSON.stringify(temp));
    } else {
      temp = { templates: [] };
      temp.templates.push(template);
      localStorage.setItem(localStorageKey, JSON.stringify(temp));
    }
    this.toaster.show("success", errorMessage, "");
    window.scroll(0, 0);
  }
  getPowerUrl(type: string) {
    switch (type) {
      case "RACK":
        return UrlConstant.RACKPOWER;
      case "CHASSIS":
        return UrlConstant.CHASSISPOWER;
      case "MSERIES":
        return UrlConstant.MODULARPOWER;
      case "NEXUS":
        return UrlConstant.FABRIC_EXTENDER;
      case "FI":
        return UrlConstant.FABRIC_INTERCONNECT;
      case "CARTRIDGE":
        return UrlConstant.CARTRIDGE;
      case "BLADE":
        return UrlConstant.BLADE;
      default:
        return UrlConstant.RACKPOWER;
    }
  }
  getPowerPayload(
    formValue: any,
    apidata: any,
    type: any,
    systemWorkLoad: any
  ) {
    this.projectData = JSON.parse(
      localStorage.getItem(`${this.projectType}-${this.projectId}`)
    );
    let io = [];
    let storage = [];
    let memory = [];
    let filteredStorage = [];
    let filteredIO = [];
    let filteredMemory = [];
    let dedicatedStorage = [], filteredDedicatedStorage = [];
    if (formValue.memory) {
      memory = formValue.memory.map(({ Configuration, Quantity }) => {
        return {
          memoryTypeName: Configuration.value,
          memoryCount: Quantity.value,
          memoryId: Configuration.id,
        };
      });
      filteredMemory = this.filterData(memory);
    }
    if (formValue.storage) {
      storage = formValue.storage.map(({ Configuration, Quantity }) => {
        return {
          storageTypeName: Configuration.value,
          storageCount: Quantity.value ? Quantity.value : 0,
          storageId: Configuration.id,
        };
      });
      filteredStorage = this.filterData(storage);
    }
    if (formValue.dedicatedStorage) {
      dedicatedStorage = formValue.dedicatedStorage.map(({ Configuration, Quantity }) => {
        return {
          mezzanineControllerTypeName: Configuration.value,
          mezzanineControllerCount: Quantity.value ? Quantity.value : 0,
          mezzanineControllerId: Configuration.id,
        };
      });
      filteredDedicatedStorage = this.filterData(dedicatedStorage);
    }
    if (
      formValue.io &&
      formValue.io[0].Quantity &&
      formValue.io[0].Quantity.value > 0 &&
      formValue.io[0].Configuration.value !== ""
    ) {
      io = formValue.io.map(({ Configuration, Quantity }) => {
        return {
          adaptorTypeName: Configuration.value,
          adaptorCount: Quantity.value ? Quantity.value : 0,
          adaptorId: Configuration.id,
        };
      });
      filteredIO = this.filterData(io);
    }
    const fanPolicyDto = {
      fanPolicyDto: {
        opTemprature: formValue?.operatingTemp,
        fanFailure: formValue?.fanFailure,
        fanPolicy:
          formValue?.fanFailure == "Yes"
            ? "Fan-Failure"
            : formValue?.fanPolicy?.value,
      },
    };
    /** add payload for additional components */
    let additionalPayload = {};
    let additionalCompStrings = ["One", "Two", "Three", "Four", "Five"];
    let idx = 0;
    this.dataObject.others.forEach((d) => {
      let type = d.group;
      if (
        ![
          "memory",
          "io",
          "storage",
          "expansions",
          "dedicatedStorage",
          "powerSupply",
          "processor",
          "maxPorts",
          "iomModules",
          "noOfPowerSupply",
        ].includes(type)
      ) {
        additionalPayload[`additionalComponent${additionalCompStrings[idx]}`] =
          formValue[type].map((f) => {
            return {
              componentId: f?.Configuration?.id,
              quantity: f?.Quantity?.value,
            };
          });
        idx++;
        if (idx == 5) return;
      }
    });
    switch (type) {
      case "MSERIES": {
        let payload = {
          id: apidata?.id,
          index: 1,
          quantity: 1,
          projectName: this.projectData?.projectName,
          hasValidConfig: this.hasValidConfig,
          systemWorkloadPercent: systemWorkLoad,
          redundancyMode: formValue?.redundancyMode?.value,
          inputVoltageId: formValue?.voltage?.id,
          powerSupplyId: formValue?.powerSupply[0]?.Configuration?.id,
          powerSupplyTypeName: formValue?.powerSupply[0]?.Configuration?.label,
          powerSupplyCount: formValue?.powerSupply[0]?.Quantity?.value,
          mezzanineControllerCount:
            formValue?.dedicatedStorage &&
              formValue?.dedicatedStorage[0]?.Quantity?.value &&
              formValue?.dedicatedStorage[0]?.Configuration?.value
              ? formValue?.dedicatedStorage[0]?.Quantity?.value
              : 0,
          mezzanineControllerId:
            formValue?.dedicatedStorage &&
              formValue?.dedicatedStorage[0]?.Quantity?.value &&
              formValue?.dedicatedStorage[0]?.Configuration?.value
              ? formValue?.dedicatedStorage[0]?.Configuration?.id
              : 0,
          mezzanineControllerTypeName:
            formValue?.dedicatedStorage &&
              formValue?.dedicatedStorage[0]?.Quantity?.value &&
              formValue?.dedicatedStorage[0]?.Configuration?.value
              ? formValue?.dedicatedStorage[0]?.Configuration?.value
              : "",
          dedicatedStorage: filteredDedicatedStorage,
          storage: filteredStorage,
          adaptor: filteredIO,
          cartridges:
            this.server != null || this.server?.nodes?.length > 0
              ? this.server?.nodes.map(
                ({
                  id,
                  index,
                  chassisId,
                  config: {
                    quantity,
                    systemWorkloadPercent,
                    hasValidConfig,
                    processorId,
                    processorTypeName,
                    processorCount,
                    mezzanineControllerId,
                    mezzanineControllerTypeName,
                    mezzanineControllerCount,
                    storage,
                    adaptors,
                    memory,
                    dedicatedStorage,
                  },
                }) => {
                  let filteredStorage = storage.filter((v) => {
                    let vals = Object.values(v);
                    for (let k of vals) {
                      if (!k) return false;
                    }
                    return true;
                  });
                  let filteredAdaptor = adaptors.filter((v) => {
                    let vals = Object.values(v);
                    for (let k of vals) {
                      if (!k) return false;
                    }
                    return true;
                  });
                  let filteredDedicatedStorage = dedicatedStorage.filter((v) => {
                    let vals = Object.values(v);
                    for (let k of vals) {
                      if (!k) return false;
                    }
                    return true;
                  });
                  return {
                    id,
                    chassisId,
                    quantity,
                    index,
                    hasValidConfig,
                    systemWorkloadPercent,
                    processorId,
                    processorTypeName,
                    processorCount,
                    mezzanineControllerId,
                    mezzanineControllerTypeName,
                    mezzanineControllerCount,
                    storage: filteredStorage,
                    adaptor: filteredAdaptor,
                    dedicatedStorage: filteredDedicatedStorage,
                    memory,
                  };
                }
              )
              : [],
        };
        payload = { ...payload, ...additionalPayload };
        if (this.dataObject.legacyPlatform == false)
          return { ...payload, ...fanPolicyDto };
        else return payload;
      }
      case "RACK": {
        let payload = {
          id: apidata?.id,
          index: 1,
          quantity: 1,
          projectName: this.projectData?.projectName,
          hasValidConfig: this.hasValidConfig,
          systemWorkloadPercent: systemWorkLoad,
          redundancyMode: formValue?.redundancyMode?.value,
          inputVoltageId: formValue?.voltage?.id,
          powerSupplyId: formValue?.powerSupply[0]?.Configuration?.id,
          powerSupplyTypeName: formValue?.powerSupply[0]?.Configuration?.label,
          powerSupplyCount: formValue?.powerSupply[0]?.Quantity?.value,
          processorId: formValue?.processor[0]?.Configuration?.id,
          processorTypeName: formValue?.processor[0]?.Configuration?.label,
          processorCount: formValue?.processor[0]?.Quantity?.label,
          mezzanineControllerCount:
            formValue?.dedicatedStorage &&
              formValue?.dedicatedStorage[0]?.Quantity?.value &&
              formValue?.dedicatedStorage[0]?.Configuration?.value
              ? formValue?.dedicatedStorage[0]?.Quantity?.value
              : 0,
          mezzanineControllerId:
            formValue?.dedicatedStorage &&
              formValue?.dedicatedStorage[0]?.Quantity?.value &&
              formValue?.dedicatedStorage[0]?.Configuration?.value
              ? formValue?.dedicatedStorage[0]?.Configuration?.id
              : 0,
          mezzanineControllerTypeName:
            formValue?.dedicatedStorage &&
              formValue?.dedicatedStorage[0]?.Quantity?.value &&
              formValue?.dedicatedStorage[0]?.Configuration?.value
              ? formValue?.dedicatedStorage[0]?.Configuration?.value
              : "",
          memory: filteredMemory,
          storage: filteredStorage,
          adaptor: filteredIO,
          dedicatedStorage: filteredDedicatedStorage,
        };
        payload = { ...payload, ...additionalPayload };
        if (this.dataObject.legacyPlatform == false)
          return { ...payload, ...fanPolicyDto };
        else return payload;
      }
      case "CARTRIDGE":
      case "BLADE": {
        const parentServer = JSON.parse(
          sessionStorage.getItem("currentServerData")
        );
        let payload = {
          id: apidata?.id,
          chassisId: this.parentChassisId
            ? this.parentChassisId
            : parentServer?.currentSequenceId,
          chassisPower:
            this.server && this.server?.chassisPower
              ? this.server.chassisPower
              : {
                powerSupplyId: parentServer.powerSupplyId,
                powerSupplyCount: parentServer.powerSupplyCount,
                inputVoltageId: parentServer.voltageId,
              },
          quantity: 1,
          projectName: this.projectData?.projectName,
          hasValidConfig: this.hasValidConfig,
          systemWorkloadPercent: systemWorkLoad,
          redundancyMode: formValue?.redundancyMode?.value,
          inputVoltageId: formValue?.voltage?.id,
          powerSupplyId: formValue?.powerSupply[0]?.Configuration?.id,
          powerSupplyTypeName: formValue?.powerSupply[0]?.Configuration?.label,
          powerSupplyCount: formValue?.powerSupply[0]?.Quantity?.value,
          processorId: formValue.processor
            ? formValue?.processor[0]?.Configuration?.id
            : null,
          processorTypeName: formValue.processor
            ? formValue?.processor[0]?.Configuration?.label
            : null,
          processorCount: formValue.processor
            ? formValue?.processor[0]?.Quantity?.label
            : null,
          memory: filteredMemory,
          storage: filteredStorage,
          adaptor: filteredIO,
          dedicatedStorage: filteredDedicatedStorage,
          mezzanineControllerCount:
            formValue?.dedicatedStorage &&
              formValue?.dedicatedStorage[0]?.Quantity?.value &&
              formValue?.dedicatedStorage[0]?.Configuration?.value
              ? formValue?.dedicatedStorage[0]?.Quantity?.value
              : 0,
          mezzanineControllerId:
            formValue?.dedicatedStorage &&
              formValue?.dedicatedStorage[0]?.Quantity?.value &&
              formValue?.dedicatedStorage[0]?.Configuration?.value
              ? formValue?.dedicatedStorage[0]?.Configuration?.id
              : 0,
          mezzanineControllerTypeName:
            formValue?.dedicatedStorage &&
              formValue?.dedicatedStorage[0]?.Quantity?.value &&
              formValue?.dedicatedStorage[0]?.Configuration?.value
              ? formValue?.dedicatedStorage[0]?.Configuration?.value
              : "",
        };
        payload = { ...payload, ...additionalPayload };
        if (this.dataObject.legacyPlatform == false)
          return { ...payload, ...fanPolicyDto };
        else return payload;
      }
      case "FI":
      case "NEXUS": {
        let expansion = [];
        if (formValue.expansions) {
          expansion = this.filterData(
            formValue.expansions.map(({ Configuration, Quantity }) => {
              return {
                extenderTypeName: Configuration.value,
                quantity: Quantity.value,
                id: Configuration.id,
              };
            })
          );
        }

        let payload = {
          id: apidata?.id,
          index: 1,
          quantity: 1,
          projectName: this.projectData?.projectName,
          hasValidConfig: this.hasValidConfig,
          systemWorkloadPercent: systemWorkLoad,
          redundancyMode: formValue?.redundancyMode?.value,
          inputVoltageId: formValue?.voltage?.id,
          powerSupplyId: formValue?.powerSupply[0]?.Configuration?.id,
          powerSupplyTypeName: formValue?.powerSupply[0]?.Configuration?.value,
          powerSupplyCount: formValue?.powerSupply[0]?.Quantity?.value,
          expansionCards: expansion,
          activePorts: formValue.maxPorts[0].Quantity
            ? formValue.maxPorts[0].Quantity?.value
            : 0,
        };
        payload = { ...payload, ...additionalPayload };
        if (this.dataObject.legacyPlatform == false)
          return { ...payload, ...fanPolicyDto };
        else return payload;
      }
      case "CHASSIS": {
        let payload = {
          id: apidata?.id,
          index: 1,
          quantity: 1,
          projectName: this.projectData?.projectName,
          hasValidConfig: this.hasValidConfig,
          systemWorkloadPercent: systemWorkLoad,
          redundancyMode: formValue?.redundancyMode?.value,
          inputVoltageId: formValue?.voltage?.id,
          powerSupplyId: formValue?.powerSupply[0]?.Configuration?.id,
          powerSupplyTypeName: formValue?.powerSupply[0]?.Configuration?.label,
          powerSupplyCount: formValue?.noOfPowerSupply[0]?.Configuration?.value,
          iomCount: 2,
          blades:
            this.server != null || this.server?.blades?.length > 0
              ? this.server?.blades.map(
                ({
                  id,
                  index,
                  chassisId,
                  config: {
                    quantity,
                    systemWorkloadPercent,
                    hasValidConfig,
                    processorId,
                    processorTypeName,
                    processorCount,
                    storage,
                    adaptors,
                    memory,
                    dedicatedStorage,
                  },
                }) => {
                  let filteredStorage = storage.filter((v) => {
                    let vals = Object.values(v);
                    for (let k of vals) {
                      if (!k) return false;
                    }
                    return true;
                  });
                  let filteredAdaptor = adaptors.filter((v) => {
                    let vals = Object.values(v);
                    for (let k of vals) {
                      if (!k) return false;
                    }
                    return true;
                  });
                  let filteredDedicatedStorage = dedicatedStorage.filter((v) => {
                    let vals = Object.values(v);
                    for (let k of vals) {
                      if (!k) return false;
                    }
                    return true;
                  });
                  return {
                    id,
                    chassisId,
                    quantity,
                    index,
                    hasValidConfig,
                    systemWorkloadPercent,
                    processorId,
                    processorTypeName,
                    processorCount,
                    storage: filteredStorage,
                    adaptor: filteredAdaptor,
                    dedicatedStorage: filteredDedicatedStorage,
                    memory,
                  };
                }
              )
              : [],
        };
        if (
          formValue?.iomModules !== undefined &&
          formValue?.iomModules !== null
        ) {
          payload["iom"] = formValue?.iomModules[0]?.Configuration?.id;
          payload["ioModuleTypeName"] =
            formValue?.iomModules[0]?.Configuration?.value;
        }
        payload = { ...payload, ...additionalPayload };
        if (this.dataObject.legacyPlatform == false)
          return { ...payload, ...fanPolicyDto };
        else return payload;
      }
      default: {
        let payload = {
          id: apidata?.id,
          index: 1,
          quantity: 1,
          projectName: this.projectData?.projectName,
          hasValidConfig: this.hasValidConfig,
          systemWorkloadPercent: systemWorkLoad,
          redundancyMode: formValue?.redundancyMode?.value,
          inputVoltageId: formValue?.voltage?.id,
          powerSupplyId: formValue?.powerSupply[0]?.Configuration?.id,
          powerSupplyTypeName: formValue?.powerSupply[0]?.Configuration?.label,
          powerSupplyCount: formValue?.powerSupply[0]?.Quantity?.value,
          processorId: formValue?.processor[0]?.Configuration?.id,
          processorTypeName: formValue?.processor[0]?.Configuration?.label,
          processorCount: formValue?.processor[0]?.Quantity?.label,
          mezzanineControllerCount:
            formValue?.dedicatedStorage &&
              formValue?.dedicatedStorage[0]?.Quantity?.value &&
              formValue?.dedicatedStorage[0]?.Configuration?.value
              ? formValue?.dedicatedStorage[0]?.Quantity?.value
              : 0,
          mezzanineControllerId:
            formValue?.dedicatedStorage &&
              formValue?.dedicatedStorage[0]?.Quantity?.value &&
              formValue?.dedicatedStorage[0]?.Configuration?.value
              ? formValue?.dedicatedStorage[0]?.Configuration?.id
              : 0,
          mezzanineControllerTypeName:
            formValue?.dedicatedStorage &&
              formValue?.dedicatedStorage[0]?.Quantity?.value &&
              formValue?.dedicatedStorage[0]?.Configuration?.value
              ? formValue?.dedicatedStorage[0]?.Configuration?.value
              : "",
          memory: filteredMemory,
          storage: filteredStorage,
          adaptor: filteredIO,
          dedicatedStorage: filteredDedicatedStorage,
        };
        payload = { ...payload, ...additionalPayload };
        if (this.dataObject.legacyPlatform == false)
          return { ...payload, ...fanPolicyDto };
        else return payload;
      }
    }
  }
  filterData(data: any[]): any[] {
    return data.filter((v) => {
      if (!v) {
        return;
      }
      let vals = Object.values(v);
      for (let k of vals) {
        if (!k) return false;
      }
      return true;
    });
  }
  isValidJSON(str) {
    try {
      return (JSON.parse(str) && !!str);
    } catch (e) {
      return false;
    }
  }
  calculatePlatformPower(systemWorkLoad: any) {
    this.checkProjectExists();
    this.isFormIncomplete = false;
    this.checkMaxSlots(this.dynamicForm.value, this.dataObject);
    if (this.hasPowerCapWarning || this.hasPowerSupplyStatusWarning) {
      this.dynamicForm.controls["redundancyMode"].setErrors(null);
      this.dynamicForm.controls["powerSupply"]["controls"][0][
        "controls"
      ].Configuration.setErrors(null);
      this.dynamicForm.controls["powerSupply"]["controls"][0][
        "controls"
      ].Quantity.setErrors(null);
    }
    if (this.dynamicForm.valid) {
      this.systemWorkLoad = systemWorkLoad;
      const url = this.getPowerUrl(this.dataObject?.type);
      const apidata = this.dataObject;
      const formValue = this.dynamicForm.value;
      const reqData = this.getPowerPayload(
        formValue,
        apidata,
        this.dataObject?.type,
        this.systemWorkLoad
      );
      this.dynamicForm.setErrors(null);
      this.projectService.setReqPayload(reqData);
      this.blockUI.start("Loading...");
      this.subscription.add(
        this.apiService.postPowerMethod(url, reqData).subscribe(
          (result) => {
            this.toaster.remove(errorCodes.INSUFFICIENT_REDUNDANCY);
            this.toaster.remove(errorCodes.NON_REDUNDANT);
            this.toaster.remove(errorCodes.ERROR_TITLE);
            this.toaster.remove(errorCodes.WARNING_TITLE);
            this.isFormValid = true;
            this.getPowerRedundancyWarnings(
              result?.redundancyStatus,
              this.dataObject?.type
            );
            this.createServerObject(result, apidata, formValue);
            this.blockUI.stop();
          },
          (error) => {
            console.error(error);
            this.isFormValid = false;
            if (error?.error?.error.includes("messageKey")) {
              const messages = JSON.parse(error?.error?.error);
              const primaryMessage = this.translate.instant(
                messages?.messages[0]?.messageKey
              );
              let secondaryMessage = this.translate.instant(
                messages?.messages[1]?.messageKey
              );
              if (secondaryMessage.includes("Http failure response"))
                secondaryMessage = "";
              if (primaryMessage) {
                this.errorMsg = error?.error ? primaryMessage : "";
              } else {
                this.errorMsg = error?.error
                  ? error.error?.error
                  : errorCodes.GENERIC_POWER_CALC_ERROR_MESSAGE;
              }

              this.toaster.show(
                "error",
                this.errorMsg,
                secondaryMessage
                  ? secondaryMessage
                  : error
                    ? error?.message
                    : ""
              );
            } else if (error?.error?.message !== undefined) {
              let errorMsg = error?.error?.message;
              if (this.isValidJSON(errorMsg)) {
                let messages = JSON.parse(errorMsg);
                if (messages && messages.messageKey) {
                  this.toaster.show("error", messages.messageKey, "");
                }
              } else if (typeof errorMsg == "string" && errorMsg.length > 0) {
                let message = error?.error?.message;
                this.toaster.show("error", errorMsg, "");
              } else {
                this.toaster.show("error", errorCodes.GENERIC_POWER_CALC_ERROR_MESSAGE, "");
              }
            } else {
              this.errorMsg = error?.error
                ? error.error?.error
                : errorCodes.GENERIC_POWER_CALC_ERROR_MESSAGE;
              this.toaster.show(
                "error",
                this.errorMsg,
                error ? error?.message : ""
              );
            }
            this.dynamicForm.setErrors({ invalid: true });
            window.scroll(0, 0);
            this.blockUI.stop();
          }
        )
      );
    }
  }
  getPowerRedundancyWarnings(redundancyStatus: any, series: any) {
    let loadLevels = ["idleStatus", "loadStatus", "maxStatus"],
      redundancyAtLevel = null;
    for (var i = 0; redundancyStatus && i < 3; i++) {
      redundancyAtLevel = redundancyStatus[loadLevels[i]];
      if (
        !this.hasPowerSupplyStatusWarning && redundancyAtLevel &&
        redundancyAtLevel.powerSupplyStatus != "good"
      ) {
        this.hasPowerSupplyStatusWarning = true;
        if (redundancyAtLevel.powerSupplyStatus == "nonRedundant" || redundancyAtLevel.powerSupplyStatus == "loadExtendedPowerRequired"
          || redundancyAtLevel.powerSupplyStatus == "loadNonRedundant" || redundancyAtLevel.powerSupplyStatus == "maxExtendedPowerRequired"
          || redundancyAtLevel.powerSupplyStatus == "maxNonRedundant"
        ) {
          this.toaster.remove(errorCodes.NON_REDUNDANT);
          this.toaster.remove(errorCodes.ERROR_TITLE);
          this.toaster.remove(errorCodes.WARNING_TITLE);
          this.dynamicForm.controls["redundancyMode"].setErrors({
            invalid: true,
          });
          if (series == "MSERIES" || "RACK" || "NEXUS" || "FI") {
            this.dynamicForm.controls["powerSupply"]["controls"][0][
              "controls"
            ].Configuration.setErrors({ invalid: true });
            this.dynamicForm.controls["powerSupply"]["controls"][0][
              "controls"
            ].Quantity.setErrors({ invalid: true });
          }
          if (series == "CHASSIS") {
            this.dynamicForm.controls["noOfPowerSupply"]["controls"][0][
              "controls"
            ].Configuration.setErrors({ invalid: true });
            this.dynamicForm.controls["powerSupply"]["controls"][0][
              "controls"
            ].Configuration.setErrors({ invalid: true });
          }
          if (redundancyAtLevel.powerSupplyStatus == "nonRedundant") {
            this.toaster.show(
              "error",
              errorCodes.NON_REDUNDANT,
              errorCodes.NON_REDUNDANT_ERROR,
              604800
            );
          }
          else if (redundancyAtLevel.powerSupplyStatus == "loadExtendedPowerRequired" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedMaxPsusForExtended") {
            this.toaster.remove(errorCodes.WARNING_TITLE);
            this.toaster.show(
              "warning",
              errorCodes.WARNING_TITLE,
              errorCodes.LOAD_EXTENDED_POWER_REQUIRED_ERROR+"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_MESSAGE,
              86400000
            );
          }else if (redundancyAtLevel.powerSupplyStatus == "loadExtendedPowerRequired" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedLessThanMaxPsus") {
            this.toaster.remove(errorCodes.WARNING_TITLE);
            this.toaster.show(
              "warning",
              errorCodes.WARNING_TITLE,
              errorCodes.LOAD_EXTENDED_POWER_REQUIRED_ERROR+"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_EXTENDED_MESSAGE,
              86400000
            );
          }else if(redundancyAtLevel.powerSupplyStatus == "loadNonRedundant" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedMaxPsusForMaxError") {
            this.toaster.remove(errorCodes.ERROR_TITLE);
            this.toaster.show(
              "error",
              errorCodes.ERROR_TITLE,
              errorCodes.LOAD_NON_REDUNDANT_ERROR+"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_REDUNDANT_MAX_MESSAGE,
              86400000
            );
          }else if(redundancyAtLevel.powerSupplyStatus == "loadNonRedundant" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedLessThanMaxPsus") {
            this.toaster.remove(errorCodes.ERROR_TITLE);
            this.toaster.show(
              "error",
              errorCodes.ERROR_TITLE,
              errorCodes.LOAD_NON_REDUNDANT_ERROR +"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_EXTENDED_MESSAGE,
              86400000
            );
          }else if (redundancyAtLevel.powerSupplyStatus == "maxExtendedPowerRequired" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedMaxPsusForExtended") {
            this.toaster.remove(errorCodes.WARNING_TITLE);
            this.toaster.show(
              "warning",
              errorCodes.WARNING_TITLE,
              errorCodes.MAX_EXTENDED_POWER_REQUIRED_ERROR+"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_MESSAGE,
              86400000
            );
          }else if (redundancyAtLevel.powerSupplyStatus == "maxExtendedPowerRequired" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedLessThanMaxPsus") {
            this.toaster.remove(errorCodes.WARNING_TITLE);
            this.toaster.show(
              "warning",
              errorCodes.WARNING_TITLE,
              errorCodes.MAX_EXTENDED_POWER_REQUIRED_ERROR+"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_EXTENDED_MESSAGE,
              86400000
            );
          }else if(redundancyAtLevel.powerSupplyStatus == "maxNonRedundant" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedMaxPsusForMaxError") {
            this.toaster.remove(errorCodes.ERROR_TITLE);
            this.toaster.show(
              "error",
              errorCodes.ERROR_TITLE,
              errorCodes.MAX_NON_REDUNDANT_ERROR+"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_REDUNDANT_MAX_MESSAGE,
              86400000
            );
          }else if(redundancyAtLevel.powerSupplyStatus == "maxNonRedundant" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedLessThanMaxPsus") {
            this.toaster.remove(errorCodes.ERROR_TITLE);
            this.toaster.show(
              "error",
              errorCodes.ERROR_TITLE,
              errorCodes.MAX_NON_REDUNDANT_ERROR +"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_EXTENDED_MESSAGE,
              86400000
            );
          }
          window.scroll(0, 0);
          return;
        }
        if (redundancyAtLevel && redundancyAtLevel.powerSupplyStatus == "insufficientCapacity") {
          this.toaster.remove(errorCodes.INSUFFICIENT_REDUNDANCY);
          this.dynamicForm.controls["redundancyMode"].setErrors({
            invalid: true,
          });
          if (series == ("MSERIES" || "RACK" || "NEXUS" || "FI")) {
            this.dynamicForm.controls["powerSupply"]["controls"][0][
              "controls"
            ].Configuration.setErrors({ invalid: true });
            this.dynamicForm.controls["powerSupply"]["controls"][0][
              "controls"
            ].Quantity.setErrors({ invalid: true });
          }
          if (series == "CHASSIS") {
            this.dynamicForm.controls["noOfPowerSupply"]["controls"][0][
              "controls"
            ].Configuration.setErrors({ invalid: true });
            this.dynamicForm.controls["powerSupply"]["controls"][0][
              "controls"
            ].Configuration.setErrors({ invalid: true });
          }
          this.toaster.show(
            "warning",
            errorCodes.INSUFFICIENT_REDUNDANCY,
            errorCodes.INSUFFICIENT_REDUNDANCY_ERROR,
            604800
          );
          window.scroll(0, 0);
          return;
        }
      } else {
        this.hasPowerSupplyStatusWarning = false;
        this.toaster.remove(errorCodes.INSUFFICIENT_REDUNDANCY);
        this.toaster.remove(errorCodes.NON_REDUNDANT);
        this.toaster.remove(errorCodes.ERROR_TITLE);
        this.toaster.remove(errorCodes.WARNING_TITLE);
        this.dynamicForm.controls["redundancyMode"].setErrors(null);
        this.dynamicForm.controls["powerSupply"]["controls"][0][
          "controls"
        ].Configuration.setErrors(null);
      }
      if (redundancyAtLevel && redundancyAtLevel.powerSupplyStatus == "powerCapped") {
        this.hasPowerCapWarning = true;
        this.toaster.remove(errorCodes.POWER_CAPPED);
        if (series == "MSERIES" || "RACK" || "NEXUS" || "FI") {
          this.dynamicForm.controls["powerSupply"]["controls"][0][
            "controls"
          ].Configuration.setErrors({ invalid: true });
          this.dynamicForm.controls["powerSupply"]["controls"][0][
            "controls"
          ].Quantity.setErrors({ invalid: true });
        }
        if (series == "CHASSIS") {
          this.dynamicForm.controls["noOfPowerSupply"]["controls"][0][
            "controls"
          ].Configuration.setErrors({ invalid: true });
          this.dynamicForm.controls["powerSupply"]["controls"][0][
            "controls"
          ].Configuration.setErrors({ invalid: true });
        }
        this.toaster.show("warning", errorCodes.POWER_CAPPED, "", 604800);
        window.scroll(0, 0);
      } else {
        this.hasPowerCapWarning = false;
        this.toaster.remove(errorCodes.POWER_CAPPED);
        this.dynamicForm.controls["redundancyMode"].setErrors(null);
      }
    }
  }
 

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  isNaN(value) {
    return !Number(value);
  }
  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }
    return value + "%";
  }
  formatOTLabel(value: number | null) {
    if (!value) {
      return 0;
    }
    return value + "`C";
  }
  getImage(image) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpeg;base64, ${image}`);
  }
}
