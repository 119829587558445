import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/shared/components/toast/toast.service';
import { errorCodes } from 'src/constants/errorCodes';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  curRoute: string = '';
  constructor(
    private readonly _location: Location,
    private router: Router,
    public toaster: ToasterService,
  ) {
    this.curRoute = this.router.url;
    // remove any existing redundancy warnings 
    this.toaster.remove(errorCodes.NON_REDUNDANT);
    this.toaster.remove(errorCodes.INSUFFICIENT_REDUNDANCY);
    this.toaster.remove(errorCodes.POWER_CAPPED);
    this.toaster.remove(errorCodes.ERROR_TITLE);
    this.toaster.remove(errorCodes.WARNING_TITLE);
  }

  ngOnInit(): void {
  }
  onPrevious($event) {

    this._location.back();
  }
  // changeRoute(newRoute: string) {
  //   this.router.navigate([ this.curRoute + '/' + newRoute]);
  // }

}
