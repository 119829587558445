import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import * as CryptoJS from "crypto-js";
import { Subscription } from "rxjs";
import { ProjectService } from "src/app/services/project.service";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { AppConstant } from "src/constants/app.constants";
import { errorCodes } from "src/constants/errorCodes";
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ApiService } from "src/app/services/api.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";

interface Total {
  totalSytemWorkLoad: number;
  totalMaxPower: number;
  totalIdlePower: number;
  totalMaxCurrent: number;
  totalCoolingImperial: number;
  totalCoolingMetric: number;
}
@Component({
  selector: "app-final-results",
  templateUrl: "./final-results.component.html",
  styleUrls: ["./final-results.component.css"],
})
export class FinalResultsComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  projectId: string;
  projectData: any;
  powerCost: number;
  emissionFactor: number;
  country: string = 'Select Region';
  flag = false;
  fileUrl: any;
  fileName: string;
  projectUnit: string;
  serverObj: { bseries: any[]; fabric: any[]; cseries: any[]; mseries: any[] };
  resultsForm: UntypedFormGroup;
  annualCostMaxPower = 0;
  annualCostIdlePower = 0;
  seriesNamesSet = [];
  seriesDisplayData: any = [];
  partitionTotals: any = [];
  finalPartitions: any = [];
  cseriesTotal: Total;
  fabricTotal: Total;
  bseriesTotal: Total;
  mseriesTotal: Total;
  additionalTotal: Total;
  cseriesEmissionTotal: number;
  fabricEmissionTotal: number;
  bseriesEmissionTotal: number;
  mseriesEmissionTotal: number;
  addionalEmissionTotal: number;
  annualCostSytemWorload: number;
  totalSystemWorkLoad: number;
  totalMaxPower: number;
  totalIdlePower: number;
  totalMaxCurrent: number;
  totalImperialCooling: number;
  totalMetricCooling: number;
  selectedfabricId = -1;
  selectedBseriesId = -1;
  selectedCseriesId = -1;
  selectedMseriesId = -1;
  istoasterActive: boolean = false;
  // fabrics: any;
  emissionInfo: any;
  countries: string[];
  userData: any;
  isUserLoggedIn: boolean = false;
  annualCostEmissionSystemWorkload: number;
  annualCostEmissionMaxPower: number;
  annualCostEmissionIdlePower: number;
  imperialEmissionConstt: number = 0.001102292769;
  metricEmissionConstt: number = 0.001;
  @BlockUI() blockUI: NgBlockUI;
  projectType: string = 'UCS.UserProjects';
  constructor(
    private router: Router,
    private toaster: ToasterService,
    private formBuilder: UntypedFormBuilder,
    private projectService: ProjectService,
    private apiService: ApiService,
  ) {
    if (!localStorage.getItem("latestProjectId")) {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/ucs-resizer/projectList"]);
      } else this.router.navigate(["/projectList"]);
    } else {
      let latestProjectId = localStorage.getItem("latestProjectId");
      if (!sessionStorage.getItem("myProjectId")) {
        sessionStorage.setItem("myProjectId", latestProjectId);
      } else {
        this.projectType = this.router.url.includes('ucs-resizer') ? 'UCS.SizerProjects' : 'UCS.UserProjects';
        if (!JSON.parse(localStorage.getItem(`${this.projectType}-${sessionStorage.getItem("myProjectId")}`))) {
          if (this.projectType.includes('Sizer')) {
            this.router.navigate(["/ucs-resizer/projectList"]);
          } else this.router.navigate(["/projectList"]);
        }
      }
    }
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.isUserLoggedIn = this.userData ? true : false;
    if (this.isUserLoggedIn) {
      this.blockUI.start("Loading...");
      this.apiService.getMethod('admin/emission').subscribe(response => {
        this.blockUI.stop();
        this.emissionInfo = response;
        this.countries = Object.keys(this.emissionInfo);
      }, (error) => {
        console.error(error);
        this.blockUI.stop();
        let errorMsg = error?.error
          ? error.error?.error
          : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show(
          "error",
          errorMsg,
          error ? error?.message : ""
        );
      })
    }
    this.subscription.add(
      this.projectService.currentUnit.subscribe((unit: any) => {
        this.projectUnit = unit ? unit : localStorage.getItem("UCS.GlobalMeasurmentUnit");
      })
    );

    this.projectId = sessionStorage.getItem("myProjectId");
    this.projectType = this.router.url.includes('ucs-resizer') ? 'UCS.SizerProjects' : 'UCS.UserProjects';
    this.projectData = JSON.parse(localStorage.getItem(`${this.projectType}-${this.projectId}`));
    this.powerCost = this.projectData?.powerUnitCost;
    this.country = this.projectData?.country ? this.projectData?.country : localStorage.getItem('UCS.GlobalRegion');
    if (this.country && this.country !== 'Select Region' && this.isUserLoggedIn) {
      this.changeCountry(this.country, true);
    }
    this.emissionFactor = this.projectData.emissionFactor;
    this.resultsForm = this.formBuilder.group({
      country: [this.country, [Validators.required]],
      emissionFactor: [this.emissionFactor, [Validators.required],],
      powerCost: [this.powerCost, [Validators.required, Validators.min(0.0000001)],],
      projectUnit: [this.projectUnit, ""],
    });
    this.subscription.add(
      this.projectService.getPowerCost().subscribe((cost: any) => {
        this.projectData.powerUnitCost = cost ? cost.cost : this.projectData?.powerUnitCost;
        this.powerCost = this.projectData.powerUnitCost;
        this.resultsForm.controls['powerCost'].setValue(this.powerCost);
        this.calculateResults();
      })
    );
    this.subscription.add(
      this.projectService.getEmissionFactor().subscribe((emissionFactor: any) => {
        if (emissionFactor && emissionFactor.factor.length > 0) {
          this.projectData.emissionFactor = emissionFactor ? emissionFactor.factor : this.projectData?.emissionFactor;
          this.emissionFactor = this.projectData?.emissionFactor;
          this.resultsForm.controls['emissionFactor'].setValue(this.emissionFactor);
        }
      })
    );
    this.subscription.add(
      this.projectService.getCountry().subscribe((country: any) => {
        if (country && country.length > 0) {
          this.projectData.country = country ? country : this.projectData?.country;
          this.country = this.projectData?.country ? this.projectData?.country : localStorage.getItem('UCS.GlobalRegion');;
          this.resultsForm.controls['country'].setValue(this.country);
        }
      })
    );
    this.calculateResults();

    //set GlobalMeasurmentUnit in localstorage to support private tab
    if (this.projectUnit) {
      localStorage.setItem("UCS.GlobalMeasurmentUnit", this.projectUnit);
    }
    this.getSeriesData(this.projectData);
  }
  doesServerHaveRedundancyError(server, getWarnings?: boolean) {
    let loadLevels = ["idleStatus", "loadStatus", "maxStatus"], redundancyAtLevel = null;
    let redundancyStatus = server?.power?.redundancyStatus;
    if (redundancyStatus) {
      for (let i = 0; redundancyStatus && i < 3; i++) {
        redundancyAtLevel = redundancyStatus[loadLevels[i]];
        if (redundancyAtLevel && redundancyAtLevel.powerSupplyStatus !== "good") {
          if (getWarnings) {
            let redundancyStatusAndMessage =  this.getRedundancyStatusAndMessage(redundancyAtLevel);
            return [true, redundancyStatusAndMessage];
          }
          return true;
        }
      }
    }
    if (getWarnings) return [false, "good"];
    return false;
  }

  getRedundancyStatusAndMessage(redundancyAtLevel){
    let redundancyStatusAndMessage = {};
    if (redundancyAtLevel.powerSupplyStatus == "loadExtendedPowerRequired" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedMaxPsusForExtended") {
      redundancyStatusAndMessage["title"] = errorCodes.WARNING;
      redundancyStatusAndMessage["message"] = errorCodes.LOAD_EXTENDED_POWER_REQUIRED_ERROR;
      redundancyStatusAndMessage["recommendation_title"] = errorCodes.RECOMMENDATION;
      redundancyStatusAndMessage["recommendation"] = errorCodes.PSU_LESS_THAN_MAX_MESSAGE;

    }else if (redundancyAtLevel.powerSupplyStatus == "loadExtendedPowerRequired" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedLessThanMaxPsus") {
      redundancyStatusAndMessage["title"] = errorCodes.WARNING;
      redundancyStatusAndMessage["message"] = errorCodes.LOAD_EXTENDED_POWER_REQUIRED_ERROR;
      redundancyStatusAndMessage["recommendation_title"] = errorCodes.RECOMMENDATION;
      redundancyStatusAndMessage["recommendation"] = errorCodes.PSU_LESS_THAN_MAX_EXTENDED_MESSAGE;

    }else if(redundancyAtLevel.powerSupplyStatus == "loadNonRedundant" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedMaxPsusForMaxError") {
      redundancyStatusAndMessage["title"] = errorCodes.ERROR;
      redundancyStatusAndMessage["message"] = errorCodes.LOAD_NON_REDUNDANT_ERROR;
      redundancyStatusAndMessage["recommendation_title"] = errorCodes.RECOMMENDATION;
      redundancyStatusAndMessage["recommendation"] = errorCodes.PSU_LESS_THAN_MAX_REDUNDANT_MAX_MESSAGE;

    }else if(redundancyAtLevel.powerSupplyStatus == "loadNonRedundant" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedLessThanMaxPsus") {
      redundancyStatusAndMessage["title"] = errorCodes.ERROR;
      redundancyStatusAndMessage["message"] = errorCodes.LOAD_NON_REDUNDANT_ERROR;
      redundancyStatusAndMessage["recommendation_title"] = errorCodes.RECOMMENDATION;
      redundancyStatusAndMessage["recommendation"] = errorCodes.PSU_LESS_THAN_MAX_EXTENDED_MESSAGE;

    }else if (redundancyAtLevel.powerSupplyStatus == "maxExtendedPowerRequired" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedMaxPsusForExtended") {
      redundancyStatusAndMessage["title"] = errorCodes.WARNING;
      redundancyStatusAndMessage["message"] = errorCodes.MAX_EXTENDED_POWER_REQUIRED_ERROR;
      redundancyStatusAndMessage["recommendation_title"] = errorCodes.RECOMMENDATION;
      redundancyStatusAndMessage["recommendation"] = errorCodes.PSU_LESS_THAN_MAX_MESSAGE;

    }else if (redundancyAtLevel.powerSupplyStatus == "maxExtendedPowerRequired" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedLessThanMaxPsus") {
      redundancyStatusAndMessage["title"] = errorCodes.WARNING;
      redundancyStatusAndMessage["message"] = errorCodes.MAX_EXTENDED_POWER_REQUIRED_ERROR;
      redundancyStatusAndMessage["recommendation_title"] = errorCodes.RECOMMENDATION;
      redundancyStatusAndMessage["recommendation"] = errorCodes.PSU_LESS_THAN_MAX_EXTENDED_MESSAGE;

    }else if(redundancyAtLevel.powerSupplyStatus == "maxNonRedundant" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedMaxPsusForMaxError") {
      redundancyStatusAndMessage["title"] = errorCodes.ERROR;
      redundancyStatusAndMessage["message"] = errorCodes.MAX_NON_REDUNDANT_ERROR;
      redundancyStatusAndMessage["recommendation_title"] = errorCodes.RECOMMENDATION;
      redundancyStatusAndMessage["recommendation"] = errorCodes.PSU_LESS_THAN_MAX_REDUNDANT_MAX_MESSAGE;

    }else if(redundancyAtLevel.powerSupplyStatus == "maxNonRedundant" && redundancyAtLevel.powerSupplyCountStatusEnum == "selectedLessThanMaxPsus") {
      redundancyStatusAndMessage["title"] = errorCodes.ERROR;
      redundancyStatusAndMessage["message"] = errorCodes.MAX_NON_REDUNDANT_ERROR;
      redundancyStatusAndMessage["recommendation_title"] = errorCodes.RECOMMENDATION;
      redundancyStatusAndMessage["recommendation"] = errorCodes.PSU_LESS_THAN_MAX_EXTENDED_MESSAGE;

    }else if(redundancyAtLevel.powerSupplyStatus == "nonRedundant"){
      redundancyStatusAndMessage["title"] = errorCodes.WARNING;
      redundancyStatusAndMessage["message"] =  errorCodes.NON_REDUNDANT +"\n" +errorCodes.NON_REDUNDANT_ERROR;

    }else if(redundancyAtLevel.powerSupplyStatus == "insufficientCapacity"){
      redundancyStatusAndMessage["title"] = errorCodes.WARNING;
      redundancyStatusAndMessage["message"] = errorCodes.INSUFFICIENT_REDUNDANCY+"\n" +errorCodes.INSUFFICIENT_REDUNDANCY_ERROR;

    }else if(redundancyAtLevel.powerSupplyStatus == "powerCapped"){
      redundancyStatusAndMessage["title"] = errorCodes.WARNING;
      redundancyStatusAndMessage["message"] = errorCodes.POWER_CAPPED;
      
    }
    return redundancyStatusAndMessage;
  }
  getSeriesData(data) {
    for (let i = 0; i < data.chassisList.length; i++) { this.seriesNamesSet.push(data.chassisList[i].seriesDisplayName); this.seriesDisplayData.push(data.chassisList[i]); }
    for (let i = 0; i < data.rackServers.length; i++) { this.seriesNamesSet.push(data.rackServers[i].seriesDisplayName); this.seriesDisplayData.push(data.rackServers[i]); }
    for (let i = 0; i < data.mseriesList.length; i++) { this.seriesNamesSet.push(data.mseriesList[i].seriesDisplayName); this.seriesDisplayData.push(data.mseriesList[i]); }
    for (let i = 0; i < data.fabrics.length; i++) { this.seriesNamesSet.push(data.fabrics[i].seriesDisplayName); this.seriesDisplayData.push(data.fabrics[i]); }
    for (let i = 1; i <= 5; i++)
      if (data[`AdditionalPlatform${i}`]) {
        for (let ap of data[`AdditionalPlatform${i}`]) {
          this.seriesNamesSet.push(ap.seriesDisplayName);
          this.seriesDisplayData.push(ap);
        }
      }
    this.seriesNamesSet = [...new Set(this.seriesNamesSet)];

    let finalPartitions = [];

    let visited = new Array(this.seriesDisplayData.length).fill(false);
    if (this.seriesDisplayData.length == 1) {
      finalPartitions.push(this.seriesDisplayData);
    } else {
      for (let i = 0; i < this.seriesDisplayData.length; i++) {
        if (!visited[i]) {
          let merged = [];
          merged.push(this.seriesDisplayData[i]);
          visited[i] = true;
          for (let j = i + 1; j < this.seriesDisplayData.length; j++) {
            if (!visited[j]) {
              if (this.seriesDisplayData[j].seriesDisplayName == this.seriesDisplayData[i].seriesDisplayName && this.seriesDisplayData[j].type == this.seriesDisplayData[i].type) {
                visited[j] = true;
                merged.push(this.seriesDisplayData[j]);
              } else if ((this.seriesDisplayData[j].type == 'FI' && this.seriesDisplayData[i].type == 'NEXUS') || (this.seriesDisplayData[i].type == 'FI' && this.seriesDisplayData[j].type == 'NEXUS')) {
                visited[j] = true;
                merged.push(this.seriesDisplayData[j]);
              }
            }
          }
          finalPartitions.push(merged);
        }
      }
    }
    for (let i = 0; i < finalPartitions.length; i++) {
      let partitionResult = this.calculateTotalValues(finalPartitions[i], 'x');
      let seriesName = "";
      if (finalPartitions[i][0].seriesDisplayName.trim() == 'Fabric Extender' || finalPartitions[i][0].seriesDisplayName.trim() == 'Fabric Interconnect') seriesName = "Fabrics";
      else seriesName = finalPartitions[i][0].seriesDisplayName;
      this.partitionTotals.push({
        "seriesName": seriesName,
        "result": partitionResult
      });
    }
    this.finalPartitions = finalPartitions;
  }
  calculateAdditionalTotal() {
    this.additionalTotal = {
      totalSytemWorkLoad: 0,
      totalMaxPower: 0,
      totalIdlePower: 0,
      totalMaxCurrent: 0,
      totalCoolingImperial: 0,
      totalCoolingMetric: 0,
    }
    let properties = ['totalSytemWorkLoad', 'totalMaxPower', 'totalIdlePower', 'totalMaxCurrent', 'totalCoolingImperial', 'totalCoolingMetric'];
    for (let i = 1; i <= 5; i++) {
      if (this.projectData[`AdditionalPlatform${i}`]) {
        let result = this.calculateTotalValues(this.projectData[`AdditionalPlatform${i}`], '');
        for (let property of properties) {
          this.additionalTotal[property] += result[property];
        }
      }
    }
  }
  calculateResults() {
    this.serverObj = { bseries: [], fabric: [], cseries: [], mseries: [] };

    // this.fabrics = this.projectData.fabricInterConnect.concat(this.projectData.fabricExtender);
    this.cseriesTotal = this.calculateTotalValues(this.projectData.rackServers, "cseries");
    this.fabricTotal = this.calculateTotalValues(this.projectData.fabrics, "fabric");
    this.bseriesTotal = this.calculateTotalValues(this.projectData.chassisList, "bseries");
    this.mseriesTotal = this.calculateTotalValues(this.projectData.mseriesList, "mseries");
    this.calculateAdditionalTotal();
    this.calculateAnnualCost();
  }
  calculateAnnualCost() {
    this.totalSystemWorkLoad = this.cseriesTotal.totalSytemWorkLoad + this.mseriesTotal.totalSytemWorkLoad +
      this.bseriesTotal.totalSytemWorkLoad + this.fabricTotal.totalSytemWorkLoad + this.additionalTotal.totalSytemWorkLoad;
    this.totalMaxPower = this.cseriesTotal.totalMaxPower +
      this.mseriesTotal.totalMaxPower + this.bseriesTotal.totalMaxPower + this.fabricTotal.totalMaxPower + this.additionalTotal.totalMaxPower;
    this.totalIdlePower = this.cseriesTotal.totalIdlePower + this.mseriesTotal.totalIdlePower +
      this.bseriesTotal.totalIdlePower + this.fabricTotal.totalIdlePower + this.additionalTotal.totalIdlePower;
    this.totalMaxCurrent = this.cseriesTotal.totalMaxCurrent + this.mseriesTotal.totalMaxCurrent +
      this.bseriesTotal.totalMaxCurrent + this.fabricTotal.totalMaxCurrent + this.additionalTotal.totalMaxCurrent;
    this.totalImperialCooling = this.cseriesTotal.totalCoolingImperial + this.mseriesTotal.totalCoolingImperial +
      this.bseriesTotal.totalCoolingImperial + this.fabricTotal.totalCoolingImperial + this.additionalTotal.totalCoolingImperial;
    this.totalMetricCooling = this.cseriesTotal.totalCoolingMetric + this.mseriesTotal.totalCoolingMetric +
      this.bseriesTotal.totalCoolingMetric + this.fabricTotal.totalCoolingMetric + this.additionalTotal.totalCoolingMetric;
    this.annualCostSytemWorload = (this.totalSystemWorkLoad / 1000) * (365 * 24) * this.powerCost;
    this.annualCostMaxPower = (this.totalMaxPower / 1000) * (365 * 24) * this.powerCost;
    this.annualCostIdlePower = (this.totalIdlePower / 1000) * (365 * 24) * this.powerCost;

    this.cseriesEmissionTotal = (this.cseriesTotal.totalSytemWorkLoad / 1000) * (365 * 24) * this.emissionFactor;
    this.fabricEmissionTotal = (this.fabricTotal.totalSytemWorkLoad / 1000) * (365 * 24) * this.emissionFactor;
    this.bseriesEmissionTotal = (this.bseriesTotal.totalSytemWorkLoad / 1000) * (365 * 24) * this.emissionFactor;
    this.mseriesEmissionTotal = (this.mseriesTotal.totalSytemWorkLoad / 1000) * (365 * 24) * this.emissionFactor;
    this.addionalEmissionTotal = (this.additionalTotal.totalSytemWorkLoad / 1000) * (365 * 24) * this.emissionFactor;

    this.annualCostEmissionSystemWorkload = (this.totalSystemWorkLoad / 1000) * (365 * 24) * this.emissionFactor * 0.001;
    this.annualCostEmissionMaxPower = (this.totalMaxPower / 1000) * (365 * 24) * this.emissionFactor * 0.001;
    this.annualCostEmissionIdlePower = (this.totalIdlePower / 1000) * (365 * 24) * this.emissionFactor * 0.001;
  }

  calculateTotalValues(serverObj: any, type: string) {
    let totalSytemWorkLoad = 0;
    let totalMaxPower = 0;
    let totalIdlePower = 0;
    let totalMaxCurrent = 0;
    let totalCoolingImperial = 0;
    let totalCoolingMetric = 0;
    let totalEmissions = 0;
    if (serverObj.length > 0) {
      totalSytemWorkLoad = serverObj
        .map((item) => item.power.inputPower)
        .reduce((prev, next) => prev + next);
      totalMaxPower = serverObj
        .map((item) => item.power.maxInputPower)
        .reduce((prev, next) => prev + next);
      totalIdlePower = serverObj
        .map((item) => item.power.idleInputPower)
        .reduce((prev, next) => prev + next);
      totalMaxCurrent = serverObj
        .map((item) => item.power.inputCurrentMax)
        .reduce((prev, next) => prev + next);
      totalCoolingImperial = serverObj
        .map((item) => item.power?.inputCooling?.imperialCooling)
        .reduce((prev, next) => prev + next);
      totalCoolingMetric = serverObj
        .map((item) => item.power?.inputCooling?.metricCoolingWatts)
        .reduce((prev, next) => prev + next);
      totalEmissions = serverObj
        .map((item) => item.power?.inputPower)
        .reduce((prev, next) => prev + next) * this.emissionFactor * ((365 * 24) / 1000) * 0.001;
    }
    return {
      totalSytemWorkLoad: totalSytemWorkLoad,
      totalMaxPower: totalMaxPower,
      totalIdlePower: totalIdlePower,
      totalMaxCurrent: totalMaxCurrent,
      totalCoolingImperial: totalCoolingImperial,
      totalCoolingMetric: totalCoolingMetric,
      totalEmissions: totalEmissions,
    };
  }

  changeGlobalUnit(event: any) {
    if (event == "Metric") {
      this.projectData.measurementUnit = "Metric";
      localStorage.setItem(`${this.projectType}-${this.projectId}`, JSON.stringify(this.projectData));
      localStorage.setItem("UCS.GlobalMeasurmentUnit", "Metric");
    } else {
      this.projectData.measurementUnit = "Imperial";
      localStorage.setItem(`${this.projectType}-${this.projectId}`, JSON.stringify(this.projectData));
      localStorage.setItem("UCS.GlobalMeasurmentUnit", "Imperial");
    }
    this.subscription.add(this.projectService.changeUnit(event));
    this.calculateResults();
  }

  downloadCSV(legacy?: boolean) {
    try {
      let result = [];
      result.push(this.projectData?.projectName);
      result.push("\n");
      if (legacy) result.push(`"Results","=HYPERLINK(""https://ucslayout.cisco.com/layout-designer"",""Load into https://ucslayout.cisco.com/layout-designer using Power tool"")"`);
      else result.push("Results");
      result.push("\n");
      var allTableElements = document.querySelectorAll('table');
      for (let tableElement of allTableElements) {
        //Get all the rows of the table
        let allRowsOfTable = tableElement.querySelectorAll("tr, thead");

        for (let row of allRowsOfTable) {
          //Get All the columns in the row
          let allColumns = row.querySelectorAll<HTMLElement>("td, th");
          let data = [];

          for (let col of allColumns) {
            if (allColumns[1]?.innerText.replace(/\s+/g, "").normalize() == 'System Workload Factor (W)'.replace(/\s+/g, "").normalize()) {
              data = [" ", "System Workload Factor (W)", "Max Power (W)", "Max Current Draw (A)", "Idle Power (W)", "Cooling System Workflow (BTU/hr)"];
              break;
            }
            let seriesFound = false;
            for (let i = 0; i < this.seriesNamesSet.length; i++) {
              if (allColumns[0]?.innerText == this.seriesNamesSet[i] && allColumns[1]?.innerText == 'System Workload Factor') {
                result.push("\n");
                result.push(this.seriesNamesSet[i]);
                result.push("\n");
                data = [this.seriesNamesSet[i], "System Workload Factor Power", "System Workload Factor Current", "System Workload Factor Cooling (BTU/hr)", "Max Power (W)", "Max Current (A)", "Idle Power (W)", "Idle Current (A)", "Air flow(cfm)", "Acoustics(dBA)", "Weight (lbs)", "Redundancy Mode"];
                seriesFound = true;
                break;
              }
              if (allColumns[0]?.innerText == this.seriesNamesSet[i] && allColumns[1]?.innerText == '') { seriesFound = true; break; }
            }
            if (seriesFound) break;

            if (allColumns[0].innerText == 'Fabrics' && allColumns[1].innerText == 'System Workload Factor') {
              result.push("\n");
              result.push("Fabrics");
              result.push("\n");
              data = ["Fabrics", "System Workload Factor Power", "System Workload Factor Current", "System Workload Factor Cooling (BTU/hr)", "Max Power (W)", "Max Current (A)", "Idle Power (W)", "Idle Current (A)", "Air flow(cfm)", "Acoustics(dBA)", "Weight (lbs)", "Redundancy Mode"];
              break;
            }
            if (allColumns[0].innerText == 'Power' && allColumns[1].innerText == 'Current') break;

            else {
              let text = col.innerText ? (col.innerText.includes(',') ? `"${col.innerText}"` : col.innerText) : '';
              text = col.innerText.includes('\n') ? col.innerText.replace("\nView More", "") : text;
              text = text.includes('\n') ? text.replace(/\n/g, ' ') : text;
              data.push(text);
            }
          }
          if (data.length > 0) {
            if (legacy) {
              data[0] = this.getLegacyServerName(data[0]).trim();
            }
            result.push(data.join(","));
          }
        }
        result.push("");
      }
      // Download CSV
      let projectName = legacy ? this.projectData?.projectName + "_UCSLayout" : this.projectData?.projectName;
      this.download_csv(result.join("\n"), projectName + ".csv");
    }
    catch {
      this.toaster.show("error", errorCodes.EXPORT_CSV_ERROR, '');
      window.scroll(0, 0);
    }
  }
  getLegacyServerName(serverName: string) {
    let cmp = 'Cisco Systems, Inc';
    let cmp0 = cmp.split(' ')[0];
    if (serverName.toLocaleLowerCase().includes(cmp.toLocaleLowerCase())) {
      return serverName.replace(cmp, cmp + ' UCS');
    } else if (serverName.toLocaleLowerCase().includes(cmp0.toLocaleLowerCase())) {
      return serverName.replace(cmp0, cmp0 + ' UCS');
    } else {
      return serverName;
    }
  }
  download_csv(csv, filename) {
    var csvFile;
    var downloadLink;

    // CSV FILE
    csvFile = new Blob([csv], {
      type: "text/csv",
    });

    downloadLink = document.createElement("a"); // Download link
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile); //  create a link to the file
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink); // Add the link to your DOM
    downloadLink.click();
  }
  onViewMore(chasis: any, type: string) {
    chasis.opened = !chasis.opened;
  }

  onEditProject() {
    this.navigateToProjectDetails();
  }
  navigateToProjectDetails(fragment?: string) {
    if (this.router.url.includes('admin/')) {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/admin/ucs-resizer/projectDetails"], { fragment: fragment });
      } else this.router.navigate(["/admin/projectDetails"], { fragment: fragment });
    } else {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/ucs-resizer/projectDetails"], { fragment: fragment });
      } else this.router.navigate(["/projectDetails"], { fragment: fragment });
    }
  }
  downloadPRJ() {
    const key_sec = AppConstant.KEY_SEC;
    const data = JSON.parse(
      localStorage.getItem(`${this.projectType}-${this.projectId}`)
    );
    const jsonData = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(jsonData, key_sec).toString();
    const blob = new Blob([encryptedData], {
      type: "application/octet-stream",
    });

    var downloadLink = document.createElement("a");
    downloadLink.download = `${this.projectData?.projectName}.prj`;
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
  changeEmissionFactor($event: any) {
    if ($event.target.value > 0) {
      this.resultsForm.get('emissionFactor').patchValue($event.target.value);
      this.emissionFactor = $event.target.value;
      this.projectService.setEmissionFactor(this.emissionFactor);
      this.projectData.emissionFactor = $event.target.value;
      localStorage.setItem(`UCS.UserProjects-${this.projectId}`, JSON.stringify(this.projectData));
    }
    if ($event.target.value <= 0) {
      if (!this.istoasterActive) {
        this.toaster.show("error", errorCodes.NONZERO_POSITIVE_VALUE_EM, '', 10000, false);
        this.istoasterActive = true;
      }
    } else {
      this.istoasterActive = false;
      this.toaster.remove(errorCodes.NONZERO_POSITIVE_VALUE_EM);
    }
  }
  changeCountry($event: any, init?: boolean) {
    this.country = $event;
    this.projectService.setCountry(this.country);
    this.projectData.country = $event;
    localStorage.setItem(`UCS.UserProjects-${this.projectId}`, JSON.stringify(this.projectData));
    localStorage.setItem('UCS.GlobalRegion', this.projectData.country);
    if (!init) {
      let countryInfo: any[] = Object.entries(this.emissionInfo).find(c => c[0] == $event);
      let factors: any[] = countryInfo[1];
      let costFactor = factors["Cost Factor"].factorvalue;
      let emissionFactor = factors["Emission Factor"].factorvalue;

      this.changePowerCost({ target: { value: costFactor } });
      this.changeEmissionFactor({ target: { value: emissionFactor } });
    }

  }
  changePowerCost(event: any) {
    if (event.target.value > 0) {
      this.projectData.powerUnitCost = event.target.value;
      this.powerCost = this.projectData.powerUnitCost;
      this.subscription.add(this.projectService.setPowerCost(event.target.value));
      localStorage.setItem(
        `${this.projectType}-${this.projectId}`,
        JSON.stringify(this.projectData)
      );
    }
    if (event.target.value <= 0) {
      if (!this.istoasterActive) {
        this.toaster.show("error", errorCodes.NONZERO_POSITIVE_VALUE, '', 10000, false);
        this.istoasterActive = true;
      }
    } else {
      this.istoasterActive = false;
      this.toaster.remove(errorCodes.NONZERO_POSITIVE_VALUE);
    }
  }
  onSubmit() { }
  getServerMemoryConfig(server) {
    let memoryConfig = "";
    if (server.generation && server.generation.includes("M7")) {
      let processorQty = Number(server.config.processorCount);
      let memoryQty = server.config.memory.map(m => m.memoryCount).reduce((a, b) => a + b, 0);
      if (processorQty > 0 && memoryQty > 0) {
        if (memoryQty / processorQty <= 8) {
          memoryConfig = "(1DPC)";
        } else {
          memoryConfig = "(2DPC)";
        }
      }
    }
    return memoryConfig;
  }
  getAdditionalComponents(server) {
    let additionalCompStrings = ["One", "Two", "Three", "Four", "Five"];
    let additionalComponents = [];
    for (let compString of additionalCompStrings) {
      let additionalComponent = server[`additionalComponent${compString}`];
      if (additionalComponent) {
        let components = [];
        if (additionalComponent.length) components = additionalComponent;
        else components.push(additionalComponent);
        components = components.filter(c => c.componentId);
        additionalComponents.push(components);
      }
    }
    return additionalComponents;
  }
  downloadPDF() {
    var data = document.getElementById('final-results-container');
    html2canvas(data).then(canvas => {
      // switch imgWidth, pageHeight for portrait/landscape
      let imgWidth = 295;
      let pageHeight = 210;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('l', 'mm', 'a4', true); // A4 size page of PDF  
      let position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST')

      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
        heightLeft -= pageHeight;
      }
      pdf.save(`${this.projectData?.projectName}.pdf`); // Generated PDF   
    });
  }
  displayFormattedRMode(rMode: string): string {
    if (!rMode) return "";
    rMode = rMode.trim();
    if (rMode == "N1") return "N+1";
    else if (rMode == "NN") return "N+N";
    else if (rMode == "N2") return "N+2";
    else return "N";
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
