export class errorCodes {

     public static GENERIC_ERROR_MESSAGE = "Oops something went wrong!!";
     public static PROJECTNAME_EMPTY = "Project Name cannot be empty";
     public static PROJECTNAME_MESSAGE = "Please enter Project Name!!";
     public static WRONG_FILE_FORMAT = 'Unexpected file format';
     public static FILE_FORMAT_MESSAGE = 'Only .prj file format supported!!';
     public static ADD_BLADE_MESSAGE = "Follow the link \"Add blade to chassis\" underneath chassis name in right side of this view to configure blades to chassis";
     public static ADD_NODE_MESSAGE = "Follow the link \"Add node to chassis\" underneath chassis name in right side of this view to configure nodes to chassis";
     public static SUCCESSFULLY_CREATED = 'Successfully created';
     public static PROCESSOR_MEMORY_COUNT_ERROR = "Please select a different processor memory count combination";
     public static NO_CONFIGURATION_MSG = "No Configuration found for this combination";
     public static MEASUREMENT_NOTFOUND_ERROR = 'Cannot find measurement for chosen Processor memory combination';
     public static ERRROR_CALCULATION = 'Error Found in Calculation';
     public static NONZERO_POSITIVE_VALUE = 'Power Cost field should be valid non-zero positive number.';
     public static NONZERO_POSITIVE_VALUE_EM = 'Emission Cost field should be valid non-zero positive number.';
     public static EXPORT_CSV_ERROR = "Failed to export project result details in csv format, Please contact system administrator";
     public static DOCUMENT_DELETE_MESSAGE = "Document deleted successfully!";
     public static NEW_RECORD = 'New Record has been created!';
     public static DUPLICATE_RECORD = "Duplicate data found. Please check the record added";
     public static UPDATE_RECORD = 'Details updated successfully!';
     public static DOCUMENT_UPLOAD_MESSAGE = "Document uploaded successfully";
     public static DETAILS_ADDED = "Details have been added successfully";
     public static PLATFORM_COMPONENTS_UPDATED = 'Platform with components are updated successfully';
     public static PLATFORM_DELETE_MESSAGE = "Record deleted successfully!";
     public static PLATFORM_CONFIG_UPDATED = 'Configuration details are saved successfully';
     public static PLATFORM_CONFIG_FAILED = 'Configuration details Save Failed.';
     public static QUANTITY_POSITIVE_NUMBER = 'Quantity of server item should be valid positive number.';
     public static QUANTITY_LIMIT_EXCEEDED = 'Requested Quantity of server item is not within allowed Server Quantity range. The allowed range is 1-999'
     public static MAXSLOTS_ERROR = 'The power estimations at project level could not be calculated due to following errors.';
     public static CPU_DIMS_NOT_ADDED = "Mandatory Components are not assigned : ";
     public static OP_FAILED = "Operation Failed!";
     public static MAND_COMP_NOT_ADDED = "Mandatory Components are not assigned.";
     public static MEASUREMENTS_NOT_ADDED = "Meaurements are not available.";

     public static PSUPPLY_DISKS_NOT_ADDED = "Disks / Power Supplies are not assigned.";
     public static DISKS_NOT_ADDED = "Storages are not assigned.";
     public static VOLTAGES_NOT_ADDED = "Voltages are not available.";


     public static MAX_EXPANSION_ERR_MSG = "Expansion Slots not available, Expansion Slots is set to 0.";
     public static MAX_IOES_ERR_MSG = "PCI Slots not available, PCI Slots is set to 0.";
     public static MAX_STORAGE_ERR_MSG = "Hard Disks/Hard Drives not available, Hard Disks/Hard Drives is set to 0.";
     public static ADMIN_FAN_WAR = "Fan Policy Warning";
     public static ADMIN_FAN_WAR_RM_MSG = "Fan Policy will be removed if Legacy Platform Flag is changed to True";


     public static NODE_SET_0 = "Compute node is set to false";
     public static HEADER_UPDATED_MSG = "Header Updated Successfully!";
     public static HEADER_DELETED_MSG = "Header Deleted Successfully!";
     public static COMPONENT_NAME_UPDATED = "Component Name updated Successfully!";

     public static LICENSE_UPDATED_MSG = "License Updated Successfully!";
     public static DELETE_RECORD = 'Record deleted successfully!';
     public static NON_REDUNDANT = 'Redundancy mode check failed.';
     public static NON_REDUNDANT_ERROR = 'Maximum power consumption exceeds redundant available power. Change redundancy mode, power supply quantity and/or input voltage to increase available redundant power. \nThe system may implement power capping, which can impact performance and limit hardware discovery.';
     public static POWER_CAPPED = 'The system may implement power capping, which can impact performance and limit hardware discovery';
     public static INSUFFICIENT_REDUNDANCY = 'Total available power check failed';
     public static INSUFFICIENT_REDUNDANCY_ERROR = 'Maximum power consumption exceeds total available output power. Change redundancy mode, power supply quantity and/or input voltage to increase total available power. \nThe system may implement power capping, which can impact performance and limit hardware discovery.';

     public static PLATFORM_NAME_UPDATED = "Platform Name updated Successfully!";
     public static PLATFORM_DISPLAYNAME_EMPTY = "Platform Name cannot be empty!";
     public static NEW_PLATFORM = 'New Platform has been created!';

     public static COMPONENT_DISPLAYNAME_EMPTY = "Component Name cannot be empty!";
     public static COMPONENT_DISPLAYNAME_TOOLONG_DEV = "Component Name too long. Reverting to previous!";
     public static COMPONENT_PUTDB_ERROR = "Component Name could not be updated due to Internal errors! Reverting to previous";

     public static ERROR_MSG_UPDATED = "Error Message Updated Successfully!";
     public static ERROR_MSG_EMPTY = "Error Message cannot be empty! Please enter Error Message";
     public static SERIESNAME_UPDATED = "Series Name Updated Successfully!";
     public static SERIESNAME_EMPTY = "Series Name cannot be empty! Please enter Series Name";

     /** form validation */
     public static ERROR_FORM_INVALIDATED = "Cannot Perform Calculations. Form Invalidated";
     public static ERROR_FORM_INVALIDATED_INFO = "The Configuration for the given server/chassis has been changed. Following mandatory field(s) need to be filled: ";

     /** warning if configuration not added */
     public static CONFIG_NOT_ADDED = "CPU DIMMS config not added";
     public static CONFIG_NOT_ADDED_INFO = "The DIMM configs for CPUs are not added. All configs are set to Invalid State. Please add the same to perform calculations."

     /** generic server error message */
     public static GENERIC_POWER_CALC_ERROR_MESSAGE = "Couldn't calculate power because of server-configuration problems. Contact site administrator.";
     public static GENERIC_CONFIG_ADD_ERROR_MESSAGE = "Couldn't fetch platform details because of server-configuration problems. Contact site administrator.";

     /** region / power cost changed messages */
     public static REGION_CHANGED_INFO = "Region has been updated. The power cost will be recalculated based on the region based power cost.";
     public static POWER_COST_CHANGED_INFO = "Power cost was changed manually. The power cost will be recalculated based on the new value.";

     /** extended power required */
     public static WARNING_TITLE = "WARNING :";
     public static ERROR_TITLE = "ERROR :";
     public static LOAD_EXTENDED_POWER_REQUIRED_ERROR = " Workload factor power exceeds available redundant power capacity. Servers may throttle based on actual demand.";
     public static LOAD_NON_REDUNDANT_ERROR ="Workload factor power exceeds available extended power capacity. Servers are likely to throttle based on actual demand. "
     public static MAX_EXTENDED_POWER_REQUIRED_ERROR =" If actual power demand exceeds configured workload factor power, servers may throttle."
     public static MAX_NON_REDUNDANT_ERROR ="If actual power demand exceeds available extended power capacity, servers are likely to throttle."
     public static RECOMMENDATION_TITLE = "<b>RECOMMENDATION :</b>"

     public static PSU_LESS_THAN_MAX_EXTENDED_MESSAGE = "Add additional PSUs to the chassis as required."
     public static PSU_LESS_THAN_MAX_MESSAGE = "Enable extended power mode or change PSU redundancy. If PSU redundancy is lost, extended power mode is disabled. Power capping may be required and can impact performance."
     public static PSU_LESS_THAN_MAX_REDUNDANT_MAX_MESSAGE = "Change PSU redundancy mode."
     public static WARNING = "WARNING";
     public static ERROR= "ERROR";
     public static RECOMMENDATION = "RECOMMENDATION"
}
