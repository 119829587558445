import {
  Component,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  Renderer2,
  ViewChild,
  TemplateRef,
  HostListener,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CngContentConfigType,
  CngModalService,
  CngModalSize,
} from "@cisco/cui-ng";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { ProjectService } from "src/app/services/project.service";
import { ConfirmationBoxComponent } from "src/app/shared/components/confirmation-box/confirmation-box.component";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { SharedService } from "src/app/shared/shared.service";
import { errorCodes } from "src/constants/errorCodes";
import { UrlConstant } from "src/constants/url.constants";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import * as _ from "lodash";
@Component({
  selector: "app-project-results",
  templateUrl: "./project-results.component.html",
  styleUrls: ["./project-results.component.css"],
})
export class ProjectResultsComponent implements OnInit, OnDestroy {
  @BlockUI() blockUI: NgBlockUI;
  subscription: Subscription = new Subscription();
  selectedSeriesId = -1;
  selectedSeriesNameIdx = -1;
  projectData: any;
  resultArray: Object = {};
  selectedBladeId = "-1";
  selectedNodeId = "-1";
  Object = Object;
  projectId: string;
  serverQuantity = 1;
  globalUnit = "";
  message = "";
  errorMsg: any;
  objectKeys(obj) {
    return Object.keys(obj);
  }
  seriesNamesSet: any = [];
  flag = false;
  editServerElementName = "";
  editChildServerName = "";
  editChildNodeName = "";
  istoasterActive: boolean = false;
  lodash = _;
  selectedClassId = -1;
  selectedNetworkId = -1;
  isBladeQtyLimitReached: boolean = false;
  isNodeQtyLimitReached: boolean = false;
  info: string = "";
  isServerEdited: boolean = false;
  currentServer: any;
  clickCount: number = 0;
  @ViewChild("editTheServer") editServerRef: TemplateRef<any>;
  @ViewChild("editChildServer") editChildServerRef: TemplateRef<any>;
  projectType: string = 'UCS.UserProjects';
  constructor(
    private translate: TranslateService,
    private projectService: ProjectService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: CngModalService,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    public toaster: ToasterService,
    private cdr: ChangeDetectorRef,
    private rdr: Renderer2
  ) { }

  ngOnInit(): void {
    this.projectType = this.router.url.includes('ucs-resizer') ? 'UCS.SizerProjects' : 'UCS.UserProjects';
    this.subscription.add(
      this.projectService.currentUnit.subscribe((unit: any) => {
        this.globalUnit = unit
          ? unit
          : localStorage.getItem("UCS.GlobalMeasurmentUnit");
      })
    );
    this.subscription.add(
      this.sharedService.currentMessage.subscribe((message: any) => {
        this.message = message.key;
        if (this.message == "deleteChassis") {
          this.deleteChassis(message.id, message.type, message.serverId);
          this.subscription.add(this.sharedService.sendMessage(""));
        }
        if (this.message == "deleteServer") {
          this.deleteServer(message.serverId, message.type, message.chassisId);
          /** re-check for additional items that can be added */
          let currentServer = message.server;
          this.route.fragment.subscribe((f) => {
            if (["BLADE", "CARTRIDGE"].includes(message.type)) {
              if (message.type == "BLADE") {
                this.addChassis(
                  currentServer.pid,
                  currentServer.config.powerSupplyId,
                  currentServer.config.powerSupplyCount,
                  currentServer.id,
                  currentServer?.config?.inputVoltageId,
                  "blade",
                  currentServer.serverId,
                  currentServer.maxBladeSlots -
                  this.getTotalSlotsConsumed(currentServer.blades),
                  f == "blade" ? true : false
                );
              }
              if (message.type == "CARTRIDGE") {
                this.addChassis(
                  currentServer.pid,
                  currentServer.config.powerSupplyId,
                  currentServer.config.powerSupplyCount,
                  currentServer.id,
                  currentServer?.config?.inputVoltageId,
                  "node",
                  currentServer.serverId,
                  currentServer.maxCartridgeSlots -
                  this.getTotalSlotsConsumed(currentServer.nodes),
                  f == "node" ? true : false
                );
              }
              this.subscription.add(this.sharedService.sendMessage(""));
              message = null;
            }
          });
        }
      })
    );

    this.subscription.add(
      this.projectService.getProjectData().subscribe((data) => {
        if (data) {
          this.projectId = sessionStorage.getItem("myProjectId");
          this.projectData = data;
          this.checkProjectExists();
          this.projectData = this.projectData
            ? this.projectData
            : JSON.parse(
              localStorage.getItem(`${this.projectType}-${this.projectId}`)
            );
          this.getUniqueSeries(this.projectData);
          this.calculateProjectPower("From getProjectData subscribe");
        }
      })
    );
    this.subscription.add(
      this.projectService.getServerEditStatus().subscribe((editStatus) => {
        this.isServerEdited = editStatus;
      })
    );
    this.subscription.add(
      this.projectService.getCurrentServer().subscribe((currentServer) => {
        this.currentServer = currentServer;
      })
    );
    this.subscription.add(
      this.projectService.getCurrentProjectName().subscribe((data) => {
        if (data) {
          this.projectData.projectName = data;
        }
      })
    );
    this.subscription.add(
      this.projectService.getIsAddedOrEdited().subscribe((isAddedOrEdited) => {
        if (isAddedOrEdited === true) {
          // trigger selection function
          this.selectPreviouslyAddedOrEditedServer();
          this.projectService.setIsAddedOrEdited(false);
        }
      })
    );
    if (!this.projectData) this.getProjectData();
    this.info = this.translate.instant("INFO");
  }
  selectPreviouslyAddedOrEditedServer() {
    if (!this.projectData) this.getProjectData();
    let currentSequenceId = sessionStorage.getItem("currentSequenceId");
    let currentServerType = sessionStorage.getItem("currentServerType");
    let currentServerId = sessionStorage.getItem("currentServerId");
    let parentSequenceId = sessionStorage.getItem("parentSequenceId");
    let mp = {
      CHASSIS: "bseries",
      MSERIES: "mseries",
      RACK: "cseries",
      BLADE: "blade",
      CARTRIDGE: "node",
      FI: "FI",
      NEXUS: "NEXUS",
    };
    let currentServer = null,
      currentIdx = 0,
      isAdditionalPlatform = false;
    if (!currentServerType.includes("AdditionalPlatform")) {
      currentServer = this.getCurentServer(
        currentSequenceId,
        mp[currentServerType],
        currentServerId,
        parentSequenceId
      );
    } else {
      isAdditionalPlatform = true;
      if (this.additionalPlatformsAdded()) {
        for (let i = 1; i <= 5; i++) {
          if (this.doesAdditionalPlatformExist(i)) {
            let additionalPlatform = this.getAdditionalPlatform(i);
            for (let server of additionalPlatform) {
              if (server.id == currentSequenceId && server.serverId == currentServerId) {
                currentServer = server;
                currentIdx = i;
                break;
              }
            }
          }
        }
      }
    }
    // class select
    let selectedClass = currentServer.primaryClass == "Compute" ? 1 : 3;
    this.onClassSelect(selectedClass, true);
    let seriesIdx = this.seriesNamesSet.findIndex(
      (s) => s === currentServer.seriesDisplayName
    );
    let selectedServerTypeMap;
    if (!isAdditionalPlatform) {
      let selectedSeriesId;
      let selectedValues = this.getSeriesIdAndServerTypeMap(currentServer);
      selectedSeriesId = selectedValues[0];
      selectedServerTypeMap = selectedValues[1];
      this.onSeriesSelect(selectedSeriesId, seriesIdx, true);
    } else {
      selectedServerTypeMap = "AP";
      this.onSeriesSelect((currentIdx - 1) * 10, seriesIdx, true);
    }
    if (!["BLADE", "CARTRIDGE"].includes(currentServerType)) {
      if (["FI", "NEXUS"].includes(currentServerType)) {
        // perform relative ordering
        let fiIdx = 0, nexusIdx = 0;
        for (let fabric of this.projectData.fabrics) {
          if (fabric.type == "FI") {
            fabric.index = fiIdx + 1;
            fiIdx += 1;
          } else if (fabric.type == "NEXUS") {
            fabric.index = nexusIdx + 1;
            nexusIdx += 1;
          }
          if (currentServer.serverId == fabric.serverId) {
            currentServer.index = fabric.index;
          }
        }
      }
      this.onServerSelect(
        selectedServerTypeMap,
        currentServer.index - 1,
        seriesIdx,
        currentServer
      );
    } else {
      // blade/node select
      let currentServerInfo = this.getCurentServer(
        currentSequenceId,
        mp[currentServerType],
        currentServerId,
        parentSequenceId,
        true
      );
      currentServer = currentServerInfo[0];
      let parentServer = currentServerInfo[1];
      let parentSelectIdAndMap = this.getSeriesIdAndServerTypeMap(parentServer);
      this.onSeriesSelect(parentSelectIdAndMap[0], seriesIdx, true);
      if (currentServerType == "BLADE") {
        this.onBladeSelect(
          (currentServer.index).toString(),
          (parentServer.index - 1).toString()
        );
      } else {
        this.onNodeSelect(
          (currentServer.index).toString(),
          (parentServer.index - 1).toString()
        );
      }
    }
  }
  getSeriesIdAndServerTypeMap(currentServer) {
    let selectedSeriesId,
      selectedServerTypeMap = "AP";
    switch (currentServer.type) {
      case "CHASSIS":
        selectedSeriesId = 0;
        selectedServerTypeMap = "B";
        break;
      case "FI":
      case "NEXUS":
        selectedSeriesId = 1;
        selectedServerTypeMap = "J";
        break;
      case "RACK":
        selectedSeriesId = 2;
        selectedServerTypeMap = "C";
        break;
      case "MSERIES":
        selectedSeriesId = 3;
        selectedServerTypeMap = "M";
        break;
      default:
        selectedSeriesId = -1;
    }
    return [selectedSeriesId, selectedServerTypeMap];
  }
  setCurrentServerInfo(currentServer) {
    localStorage.setItem("currentServerType", currentServer.type);
    localStorage.setItem("currentServerId", currentServer.serverId);
    sessionStorage.setItem("currentServerType", currentServer.type);
    sessionStorage.setItem("currentServerId", currentServer.serverId);
  }
  doesServerHaveRedundancyError(server, getWarnings?: boolean) {
    let loadLevels = ["idleStatus", "loadStatus", "maxStatus"], redundancyAtLevel = null;
    let redundancyStatus = server?.power?.redundancyStatus;
    let redundancyStatusValue ={};
    if (redundancyStatus) {
      for (let i = 0; redundancyStatus && i < 3; i++) {
        redundancyAtLevel = redundancyStatus[loadLevels[i]];
        if (redundancyAtLevel && redundancyAtLevel.powerSupplyStatus !== "good") {
          if (getWarnings) {
            redundancyStatusValue["warning"] = true;
            redundancyStatusValue["powerSupplyStatus"] = redundancyAtLevel.powerSupplyStatus;
            redundancyStatusValue["powerSupplyCountStatusEnum"] = redundancyAtLevel.powerSupplyCountStatusEnum;
            return [redundancyStatusValue];
          }
          return true;
        }
      }
    }
    if (getWarnings) return [false, "good"];
    return false;
  }
  getProjectData() {
    this.projectId = sessionStorage.getItem("myProjectId");
    this.checkProjectExists();
    this.projectData = this.projectData
      ? this.projectData
      : JSON.parse(localStorage.getItem(`${this.projectType}-${this.projectId}`));
    this.getUniqueSeries(this.projectData);
    this.calculateProjectPower();
  }
  checkProjectExists() {
    if (!JSON.parse(localStorage.getItem(`${this.projectType}-${this.projectId}`))) {
      this.router.navigate(["/projectList"]);
    }
  }
  getUniqueSeries(data) {
    for (let i = 0; i < data.chassisList.length; i++)
      this.seriesNamesSet.push(data.chassisList[i].seriesDisplayName);
    for (let i = 0; i < data.rackServers.length; i++)
      this.seriesNamesSet.push(data.rackServers[i].seriesDisplayName);
    for (let i = 0; i < data.mseriesList.length; i++)
      this.seriesNamesSet.push(data.mseriesList[i].seriesDisplayName);
    for (let i = 0; i < data.fabrics.length; i++)
      this.seriesNamesSet.push(data.fabrics[i].seriesDisplayName);
    for (let i = 1; i <= 5; i++)
      if (data[`AdditionalPlatform${i}`]) {
        for (let ap of data[`AdditionalPlatform${i}`]) {
          this.seriesNamesSet.push(ap.seriesDisplayName);
        }
      }
    this.seriesNamesSet = [...new Set(this.seriesNamesSet)];
  }
  filterList(serverList, seriesName) {
    return serverList.filter(
      (server) => server.seriesDisplayName == seriesName
    );
  }
  getAdditionalComponents(server) {
    let additionalCompStrings = ["One", "Two", "Three", "Four", "Five"];
    let additionalComponents = [];
    for (let compString of additionalCompStrings) {
      let additionalComponent = server[`additionalComponent${compString}`];
      if (additionalComponent) {
        let components = [];
        if (additionalComponent.length) components = additionalComponent;
        else components.push(additionalComponent);
        components = components.filter((c) => c.componentId);
        additionalComponents.push(components);
      }
    }
    return additionalComponents;
  }
  getTotalQuantity(additionalComponent) {
    let total = 0;
    for (let comp of additionalComponent) {
      total += comp.quantity;
    }
    return total;
  }
  onResultClick() { }
  onSeriesSelect(index: number, seriesIdx: number, isInternal?: boolean) {
    if (this.selectedSeriesId == index) {
      if (this.selectedSeriesNameIdx == seriesIdx) {
        this.selectedSeriesId = -1;
        this.selectedSeriesNameIdx = -1;
      } else {
        this.selectedSeriesNameIdx = seriesIdx;
      }
    } else {
      if (this.selectedSeriesNameIdx !== seriesIdx) {
        this.selectedSeriesId = index;
        this.selectedSeriesNameIdx = seriesIdx;
      } else {
        this.selectedSeriesNameIdx = -1;
        this.selectedSeriesId = -1;
      }
    }
    if (isInternal) {
      this.selectedSeriesId = index;
      this.selectedSeriesNameIdx = seriesIdx;
    }
    this.selectedServerId = [];
    this.selectedBladeId = "-1";
    this.selectedNodeId = "-1";
    this.cdr.detectChanges();
  }
  onClassSelect(index: number, isInternal?: boolean) {
    if (this.selectedClassId == index) this.selectedClassId = -1;
    else this.selectedClassId = index;

    if (isInternal) this.selectedClassId = index;

    this.selectedSeriesId = -1;
    this.cdr.detectChanges();
  }
  selectedServerId: any = [];
  onServerSelect(
    seriesId: any,
    serverId: number,
    seriesNameIdx: number,
    currentServer: any
  ) {
    if (this.selectedServerId.includes(seriesId + serverId + seriesNameIdx)) {
      var index = this.selectedServerId.indexOf(
        seriesId + serverId + seriesNameIdx
      );
      if (index !== -1) {
        this.selectedServerId.splice(index, 1);
      }
    } else {
      this.selectedServerId.push(seriesId + serverId + seriesNameIdx);
    }
    const bladeQuantity = currentServer.blades
      .map((item) => item.config?.quantity * item?.slotsConsumed)
      .reduce((prev, next) => parseInt(prev) + parseInt(next), 0);
    const nodeQuantity = currentServer.nodes
      .map((item) => item.config?.quantity * item?.slotsConsumed)
      .reduce((prev, next) => parseInt(prev) + parseInt(next), 0);
    if (bladeQuantity >= currentServer.maxBladeSlots)
      this.isBladeQtyLimitReached = true;
    else this.isBladeQtyLimitReached = false;
    if (nodeQuantity >= currentServer.maxCartridgeSlots)
      this.isNodeQtyLimitReached = true;
    else this.isNodeQtyLimitReached = false;
  }

  onBladeSelect(bladeIdx: string, chassisIdx: string) {
    let val = `${bladeIdx},${chassisIdx}`;
    if (this.selectedBladeId == val) this.selectedBladeId = "-1";
    else this.selectedBladeId = val;
  }
  onNodeSelect(nodeIdx: string, chassisIdx: string) {
    let val = `${nodeIdx},${chassisIdx}`;
    if (this.selectedNodeId == val) this.selectedNodeId = "-1";
    else this.selectedNodeId = val;
  }
  checkProjectError(): boolean {
    /** checking project error for default platforms */
    this.checkProjectExists();
    let isProjectError = false;
    let platforms = ['chassisList', 'rackServers', 'mseriesList', 'fabrics'];
    for (let platform of platforms) {
      this.projectData[`${platform}`].forEach(s => {
        if (s.config.flag == 1) { isProjectError = true; return; }
        if (['chassisList', 'mseriesList'].includes(platform)) {
          if (platform == 'chassisList') {
            s.blades.forEach(blade => {
              if (blade.config.flag == 1) { isProjectError = true; return; }
            })
            if (isProjectError) return;
          } else if (platform == 'mseriesList') {
            s.nodes.forEach(node => {
              if (node.config.flag == 1) { isProjectError = true; return; }
            })
            if (isProjectError) return;
          }
        }
      })
    }
    if (!isProjectError) this.projectService.setProjectError(false);
    return isProjectError;
  }
  changeServerQuantity(event, id: string, type: string, serverId: string) {
    const currentServer = this.getCurentServer(id, type, serverId);
    if (event.target.value < 1) {
      if (!this.istoasterActive) {
        this.toaster.show(
          "error",
          errorCodes.QUANTITY_POSITIVE_NUMBER,
          "",
          5000,
          false
        );
      }
      this.istoasterActive = true;
      currentServer.config.flag = 1;
      this.projectService.setProjectError(true);
      return;
    } else if (event.target.value > 999) {
      if (!this.istoasterActive) {
        this.toaster.show(
          "error",
          errorCodes.QUANTITY_LIMIT_EXCEEDED,
          "",
          5000,
          false
        );
      }
      this.istoasterActive = true;
      currentServer.config.flag = 1;
      this.projectService.setProjectError(true);
      return;
    } else {
      this.istoasterActive = false;
      this.toaster.remove(errorCodes.QUANTITY_POSITIVE_NUMBER);
      currentServer.config.flag = null;
      currentServer.config.quantity = event.target.value;
      if (currentServer?.blades) {
        for (let blade of currentServer?.blades) {
          if (blade?.config?.quantity <= 0) {
            if (!this.istoasterActive) {
              this.toaster.show(
                "error",
                errorCodes.QUANTITY_POSITIVE_NUMBER,
                "",
                5000,
                false
              );
            }
            this.istoasterActive = true;
            currentServer.config.flag = 1;
            this.projectService.setProjectError(true);
            return;
          } else {
            blade.config.flag = null;
          }
        }
        const bladeQuantity = currentServer.blades
          .map((item) => item.config?.quantity * item?.slotsConsumed)
          .reduce((prev, next) => parseInt(prev) + parseInt(next), 0);
        if (bladeQuantity > currentServer.maxBladeSlots) {
          this.projectService.setProjectError(true);
          this.toaster.remove(errorCodes.MAXSLOTS_ERROR);
          this.toaster.show(
            "error",
            errorCodes.MAXSLOTS_ERROR,
            `${bladeQuantity} ` +
            this.translate.instant(
              `selected blade slots exceeds available `
            ) +
            `${currentServer.maxBladeSlots} ` +
            this.translate.instant(`slots`) +
            ` ${currentServer.displayName}`,
            5000
          );
          window.scroll(0, 0);
          return;
        }
      }
      if (currentServer?.nodes) {
        for (let node of currentServer?.nodes) {
          if (node?.config?.quantity <= 0) {
            if (!this.istoasterActive) {
              this.toaster.show(
                "error",
                errorCodes.QUANTITY_POSITIVE_NUMBER,
                "",
                5000,
                false
              );
            }
            this.istoasterActive = true;
            currentServer.config.flag = 1;
            this.projectService.setProjectError(true);
            return;
          } else {
            node.config.flag = null;
          }
        }
        const nodeQuantity = currentServer.nodes
          .map((item) => item.config?.quantity * item?.slotsConsumed)
          .reduce((prev, next) => parseInt(prev) + parseInt(next), 0);
        if (nodeQuantity > currentServer.maxCartridgeSlots) {
          this.projectService.setProjectError(true);
          this.toaster.remove(errorCodes.MAXSLOTS_ERROR);
          this.toaster.show(
            "error",
            errorCodes.MAXSLOTS_ERROR,
            `${nodeQuantity} ` +
            this.translate.instant(`selected node slots exceeds available `) +
            `${currentServer.maxCartridgeSlots} ` +
            this.translate.instant(`slots`) +
            ` ${currentServer.displayName}`,
            5000
          );
          window.scroll(0, 0);
          return;
        }
      }
      this.projectData.updatedDate = new Date().toISOString();
      let isProjectError = this.checkProjectError();
      // this.projectService.setProjectError(false);
      if (isProjectError) return;
      localStorage.setItem(
        `${this.projectType}-${this.projectId}`,
        JSON.stringify(this.projectData)
      );
      localStorage.setItem(`setCurrentServer`, JSON.stringify(currentServer));
      sessionStorage.setItem(`setCurrentServer`, JSON.stringify(currentServer));
      this.getProjectData();
    }
  }
  isCurrentServer(server) {
    if (server.serverId == this.currentServer.serverId) return true;
    return false;
  }
  addChassis(
    pid: string,
    powerSupplyId: string,
    powerSupplyCount: string,
    chassisId: string,
    voltageId: number,
    type: string,
    serverId: string,
    slotsRemaining: number,
    redirect?: boolean
  ) {
    localStorage.setItem("editServerId", serverId); //UIId
    localStorage.setItem("parentSequenceId", chassisId); //sequenceid
    sessionStorage.setItem("editServerId", serverId); //UIId
    sessionStorage.setItem("parentSequenceId", chassisId);
    this.subscription.add(
      this.projectService.setCurrentChassis([
        pid,
        powerSupplyId,
        powerSupplyCount,
        chassisId,
        voltageId,
        type,
        slotsRemaining,
        redirect === false ? redirect : true,
      ])
    );
    const currentServerDetails = {
      currentProductId: pid,
      currentSequenceId: chassisId,
      powerSupplyId: powerSupplyId,
      powerSupplyCount: powerSupplyCount,
      voltageId: voltageId,
    };
    localStorage.setItem(
      "currentServerData",
      JSON.stringify(currentServerDetails)
    );
    sessionStorage.setItem(
      "currentServerData",
      JSON.stringify(currentServerDetails)
    );
    this.navigateToProjectDetails(type);
  }
  navigateToProjectDetails(fragment?: string) {
    if (this.router.url.includes('admin/')) {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/admin/ucs-resizer/projectDetails"], { fragment: fragment });
      } else this.router.navigate(["/admin/projectDetails"], { fragment: fragment });
    } else {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/ucs-resizer/projectDetails"], { fragment: fragment });
      } else this.router.navigate(["/projectDetails"], { fragment: fragment });
    }
  }
  editServer(sequenceId: string, type: string, serverId: string) {
    this.editServerElementName = serverId;
    const currentServer = this.getCurentServer(sequenceId, type, serverId);
    this.projectData.updatedDate = new Date().toISOString();
    localStorage.setItem(
      `${this.projectType}-${this.projectId}`,
      JSON.stringify(this.projectData)
    );
    localStorage.setItem("currentProductId", currentServer.pid);
    localStorage.setItem("currentSequenceId", currentServer.id);
    sessionStorage.setItem("currentProductId", currentServer.pid);
    sessionStorage.setItem("currentSequenceId", currentServer.id);
    this.setCurrentServerInfo(currentServer);
    this.projectService.setReqPayload(null);
    this.activatedRoute.url.subscribe((activeUrl) => {
      const url = window.location.pathname;

      if (url.includes("serverDetails")) {
        this.subscription.add(
          this.projectService.setCurrentServer(currentServer)
        );
        this.projectService.setServerFlag(true);
        localStorage.setItem("setCurrentServer", JSON.stringify(currentServer));
        sessionStorage.setItem("setCurrentServer", JSON.stringify(currentServer));
      } else {
        this.router.navigate(["serverDetails"], {
          relativeTo: this.activatedRoute,
        });
        this.subscription.add(
          this.projectService.setCurrentServer(currentServer)
        );
        this.projectService.setServerFlag(true);
        localStorage.setItem("setCurrentServer", JSON.stringify(currentServer));
        sessionStorage.setItem("setCurrentServer", JSON.stringify(currentServer));
      }
    });
    this.projectService.setServerEditStatus(true);
    /**
     * persist server edit state for update
     * on refreshing data / hard reload
     */
    localStorage.setItem("setServerEditStatus", "true");
    sessionStorage.setItem("setServerEditStatus", "true");
    if (!this.doesServerHaveRedundancyError(currentServer)) {
      this.removeRedundancyWarnings();
    }
  }

  removeRedundancyWarnings() {
    // remove any existing redundancy warnings 
    this.toaster.remove(errorCodes.NON_REDUNDANT);
    this.toaster.remove(errorCodes.INSUFFICIENT_REDUNDANCY);
    this.toaster.remove(errorCodes.POWER_CAPPED);
    this.toaster.remove(errorCodes.ERROR_TITLE);
    this.toaster.remove(errorCodes.WARNING_TITLE);
  }

  getCurentServer(
    id: string,
    type: string,
    serverId: string,
    parentId?: string,
    returnParent?: boolean
  ) {
    this.checkProjectExists();
    let currentServer;
    switch (type) {
      case "FI":
      case "NEXUS":
        currentServer = this.projectData.fabrics.filter((v) => {
          return v.id == id && v.serverId == serverId;
        });
        return currentServer[0];
      case "mseries":
        currentServer = this.projectData.mseriesList.filter((v) => {
          return v.id == id && v.serverId == serverId;
        });
        return currentServer[0];
      case "bseries":
        currentServer = this.projectData.chassisList.filter((v) => {
          return v.id == id && v.serverId == serverId;
        });
        return currentServer[0];
      case "cseries":
        currentServer = this.projectData.rackServers.filter((v) => {
          return v.id == id && v.serverId == serverId;
        });
        return currentServer[0];
      case "blade":
        currentServer = this.projectData.chassisList.filter((v) => {
          return v.id == parentId;
        });
        let ind = -1;
        for (let s of currentServer) {
          ind = s.blades.findIndex((v) => v.id == id && v.serverId == serverId);
          if (s.blades[ind]) {
            if (returnParent)
              return [s.blades[ind], s];
            return s.blades[ind];
          }
        }
      case "node":
        currentServer = this.projectData.mseriesList.filter((v) => {
          return v.id == parentId;
        });
        let idx = -1;
        for (let s of currentServer) {
          idx = s.nodes.findIndex((v) => v.id == id && v.serverId == serverId);
          if (s.nodes[idx]) {
            if (returnParent)
              return [s.nodes[idx], s];
            return s.nodes[idx];
          }
        }
      default: {
        let serverFound = false;
        for (let i = 1; i <= 5; i++) {
          if (this.doesAdditionalPlatformExist(i)) {
            let platform = this.getAdditionalPlatform(i);
            for (let server of platform) {
              if (server.serverType == type && server.serverId == serverId) {
                currentServer = server;
                serverFound = true;
                break;
              }
            }
          }
          if (serverFound) break;
        }
        return currentServer;
      }
    }
  }

  blurServer(
    event: any,
    id: string,
    type: string,
    serverId: string,
    parentId: string
  ) {
    this.editServerElementName = "";
    this.editChildNodeName = "";
    this.editChildServerName = "";
    const currentServer = this.getCurentServer(id, type, serverId, parentId);
    this.projectData.updatedDate = new Date().toISOString();
    localStorage.setItem(
      `${this.projectType}-${this.projectId}`,
      JSON.stringify(this.projectData)
    );
    localStorage.setItem("currentProductId", currentServer.pid);
    localStorage.setItem("currentSequenceId", currentServer.id);
    sessionStorage.setItem("currentProductId", currentServer.pid);
    sessionStorage.setItem("currentSequenceId", currentServer.id);
    this.setCurrentServerInfo(currentServer);
  }
  updateIndices(servers: any[], isChild?: boolean) {
    servers.forEach((server, idx) => {
      server.index = isChild ? idx : idx + 1;
    })
  }
  deleteChassis(id: string, type: string, serverId: string) {
    this.checkProjectExists();
    let removeIndex = -1;
    switch (type) {
      case "FI":
      case "NEXUS":
        removeIndex = this.projectData.fabrics.findIndex((item) => {
          return item.id == id && item.serverId == serverId;
        });
        this.projectData.fabrics.splice(removeIndex, 1);
        this.updateIndices(this.projectData.fabrics);
        break;
      case "mseries":
        removeIndex = this.projectData.mseriesList.findIndex((item) => {
          return item.id == id && item.serverId == serverId;
        });
        this.projectData.mseriesList.splice(removeIndex, 1);
        this.updateIndices(this.projectData.mseriesList);
        this.subscription.add(this.projectService.setCurrentChassis([null])); //to remove node server list under the chassis
        break;
      case "bseries":
        removeIndex = this.projectData.chassisList.findIndex((item) => {
          return item.id == id && item.serverId == serverId;
        });
        this.projectData.chassisList.splice(removeIndex, 1);
        this.updateIndices(this.projectData.chassisList);
        this.subscription.add(this.projectService.setCurrentChassis([null])); //to remove blade server list under the chassis
        break;
      case "cseries":
        removeIndex = this.projectData.rackServers.findIndex((item) => {
          return item.id == id && item.serverId == serverId;
        });
        this.projectData.rackServers.splice(removeIndex, 1);
        this.updateIndices(this.projectData.rackServers);
        break;
      default: {
        let serverFound = false;
        for (let i = 1; i <= 5; i++) {
          if (this.doesAdditionalPlatformExist(i)) {
            let platform = this.getAdditionalPlatform(i);
            for (let j = 0; j < platform.length; j++) {
              let server = platform[j];
              if (server.serverType == type) {
                this.projectData[`AdditionalPlatform${i}`].splice(j, 1);
                this.updateIndices(this.projectData[`AdditionalPlatform${i}`]);
                serverFound = true;
                break;
              }
            }
          }
          if (serverFound) break;
        }
      }
    }
    this.subscription.add(this.projectService.setServerEditStatus(false));
    localStorage.setItem("setServerEditStatus", "false");
    sessionStorage.setItem("setServerEditStatus", "false");
    this.projectData.updatedDate = new Date().toISOString();
    localStorage.setItem(
      `${this.projectType}-${this.projectId}`,
      JSON.stringify(this.projectData)
    );
    this.getProjectData();
    this.navigateToProjectDetails("Compute");
  }

  public async deleteChasssisOpenModal(id, type: string, serverId: string) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant("ARE_YOU_SURE_TO_DELETE_SERVER"),
            key: "deleteChassis",
            id: id,
            type: type,
            serverId: serverId,
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();
  }

  changeServerName(event, id: string, type: string, serverId: string) {
    const currentServer = this.getCurentServer(id, type, serverId);
    currentServer.userProvidedName = event.target.value;
    let curServer = JSON.parse(sessionStorage.getItem("setCurrentServer"));
    curServer.userProvidedName = event.target.value;
    localStorage.setItem(`setCurrentServer`, JSON.stringify(curServer));
    sessionStorage.setItem(`setCurrentServer`, JSON.stringify(curServer));
    this.projectData.updatedDate = new Date().toISOString();
    localStorage.setItem(
      `${this.projectType}-${this.projectId}`,
      JSON.stringify(this.projectData)
    );
  }
  isQtyUnderLimit(data): boolean {
    for (let elem of data?.chassis)
      if (elem.quantity < 0 || elem.quantity > 999) return false;
    for (let elem of data?.invictaServers)
      if (elem.quantity < 0 || elem.quantity > 999) return false;
    for (let elem of data?.mseries)
      if (elem.quantity < 0 || elem.quantity > 999) return false;
    for (let elem of data?.rackServers)
      if (elem.quantity < 0 || elem.quantity > 999) return false;
    for (let elem of data?.switches)
      if (elem.quantity < 0 || elem.quantity > 999) return false;
    return true;
  }
  filterAdditionalComp(additionalComp) {
    return additionalComp ? additionalComp : null;
  }
  additionalPlatformPayloadMap(additionalPlatform) {
    return additionalPlatform
      ? additionalPlatform.map(
        ({
          id,
          index,
          config: {
            quantity,
            systemWorkloadPercent,
            hasValidConfig,
            redundancyMode,
            inputVoltageId,
            powerSupplyId,
            powerSupplyTypeName,
            powerSupplyCount,
            mezzanineControllerId,
            mezzanineControllerTypeName,
            mezzanineControllerCount,
            dedicatedStorage,
            storage,
            adaptors,
            memory,
            processorId,
            processorTypeName,
            processorCount,
            fanPolicyDto,
          },
          additionalComponentOne,
          additionalComponentTwo,
          additionalComponentThree,
          additionalComponentFour,
          additionalComponentFive,
        }) => {
          let filteredStorage = storage.filter((v) => {
            let vals = Object.values(v);
            for (let k of vals) {
              if (!k) return false;
            }
            return true;
          });
          let filteredAdaptor = adaptors.filter((v) => {
            let vals = Object.values(v);
            for (let k of vals) {
              if (!k) return false;
            }
            return true;
          });
          let filteredDedicatedStorage = dedicatedStorage.filter((v) => {
            let vals = Object.values(v);
            for (let k of vals) {
              if (!k) return false;
            }
            return true;
          });
          return {
            id,
            quantity,
            index,
            hasValidConfig,
            systemWorkloadPercent,
            redundancyMode,
            inputVoltageId,
            powerSupplyId,
            powerSupplyTypeName,
            powerSupplyCount,
            mezzanineControllerId,
            mezzanineControllerTypeName,
            mezzanineControllerCount,
            storage: filteredStorage,
            adaptor: filteredAdaptor,
            dedicatedStorage: filteredDedicatedStorage,
            memory,
            processorId,
            processorTypeName,
            processorCount,
            fanPolicyDto,
            additionalComponentOne: this.filterAdditionalComp(
              additionalComponentOne
            ),
            additionalComponentTwo: this.filterAdditionalComp(
              additionalComponentTwo
            ),
            additionalComponentThree: this.filterAdditionalComp(
              additionalComponentThree
            ),
            additionalComponentFour: this.filterAdditionalComp(
              additionalComponentFour
            ),
            additionalComponentFive: this.filterAdditionalComp(
              additionalComponentFive
            ),
            projectName: this.projectData?.projectName,
          };
        }
      )
      : [];
  }
  calculateProjectPower(from?) {
    const reqData = {
      projectName: this.projectData?.projectName,
      rackServers: this.projectData.rackServers.map(
        ({
          id,
          index,
          config: {
            quantity,
            systemWorkloadPercent,
            hasValidConfig,
            redundancyMode,
            inputVoltageId,
            powerSupplyId,
            powerSupplyTypeName,
            powerSupplyCount,
            mezzanineControllerId,
            mezzanineControllerTypeName,
            mezzanineControllerCount,
            dedicatedStorage,
            storage,
            adaptors,
            memory,
            processorId,
            processorTypeName,
            processorCount,
            fanPolicyDto,
          },
          additionalComponentOne,
          additionalComponentTwo,
          additionalComponentThree,
          additionalComponentFour,
          additionalComponentFive,
        }) => {
          let filteredStorage = storage.filter((v) => {
            let vals = Object.values(v);
            for (let k of vals) {
              if (!k) return false;
            }
            return true;
          });
          let filteredAdaptor = adaptors.filter((v) => {
            let vals = Object.values(v);
            for (let k of vals) {
              if (!k) return false;
            }
            return true;
          });
          let filteredDedicatedStorage = dedicatedStorage.filter((v) => {
            let vals = Object.values(v);
            for (let k of vals) {
              if (!k) return false;
            }
            return true;
          });
          return {
            id,
            quantity,
            index,
            hasValidConfig,
            systemWorkloadPercent,
            redundancyMode,
            inputVoltageId,
            powerSupplyId,
            powerSupplyTypeName,
            powerSupplyCount,
            mezzanineControllerId,
            mezzanineControllerTypeName,
            mezzanineControllerCount,
            dedicatedStorage: filteredDedicatedStorage,
            storage: filteredStorage,
            adaptor: filteredAdaptor,
            memory,
            processorId,
            processorTypeName,
            processorCount,
            fanPolicyDto,
            additionalComponentOne: this.filterAdditionalComp(
              additionalComponentOne
            ),
            additionalComponentTwo: this.filterAdditionalComp(
              additionalComponentTwo
            ),
            additionalComponentThree: this.filterAdditionalComp(
              additionalComponentThree
            ),
            additionalComponentFour: this.filterAdditionalComp(
              additionalComponentFour
            ),
            additionalComponentFive: this.filterAdditionalComp(
              additionalComponentFive
            ),
            projectName: this.projectData?.projectName,
          };
        }
      ),
      chassis: this.projectData.chassisList.map(
        ({
          id,
          index,
          blades,
          config: {
            quantity,
            systemWorkloadPercent,
            hasValidConfig,
            redundancyMode,
            inputVoltageId,
            powerSupplyId,
            powerSupplyTypeName,
            powerSupplyCount,
            iom,
            ioModuleTypeName,
            iomCount,
            fanPolicyDto,
          },
          additionalComponentOne,
          additionalComponentTwo,
          additionalComponentThree,
          additionalComponentFour,
          additionalComponentFive,
        }) => {
          return {
            id,
            quantity,
            index,
            hasValidConfig,
            systemWorkloadPercent,
            redundancyMode,
            inputVoltageId,
            powerSupplyId,
            powerSupplyTypeName,
            powerSupplyCount,
            iom,
            ioModuleTypeName,
            iomCount,
            fanPolicyDto,
            blades: blades.map(
              ({
                id,
                index,
                chassisId,
                config: {
                  quantity,
                  systemWorkloadPercent,
                  hasValidConfig,
                  processorId,
                  processorTypeName,
                  processorCount,
                  dedicatedStorage,
                  storage,
                  adaptors,
                  memory,
                  fanPolicyDto,
                },
                additionalComponentOne,
                additionalComponentTwo,
                additionalComponentThree,
                additionalComponentFour,
                additionalComponentFive,
              }) => {
                let filteredStorage = storage.filter((v) => {
                  let vals = Object.values(v);
                  for (let k of vals) {
                    if (!k) return false;
                  }
                  return true;
                });
                let filteredAdaptor = adaptors.filter((v) => {
                  let vals = Object.values(v);
                  for (let k of vals) {
                    if (!k) return false;
                  }
                  return true;
                });
                let filteredDedicatedStorage = dedicatedStorage.filter((v) => {
                  let vals = Object.values(v);
                  for (let k of vals) {
                    if (!k) return false;
                  }
                  return true;
                });
                return {
                  id,
                  chassisId,
                  quantity,
                  index,
                  hasValidConfig,
                  systemWorkloadPercent,
                  processorId,
                  processorTypeName,
                  processorCount,
                  dedicatedStorage: filteredDedicatedStorage,
                  storage: filteredStorage,
                  adaptor: filteredAdaptor,
                  memory,
                  fanPolicyDto,
                  additionalComponentOne: this.filterAdditionalComp(
                    additionalComponentOne
                  ),
                  additionalComponentTwo: this.filterAdditionalComp(
                    additionalComponentTwo
                  ),
                  additionalComponentThree: this.filterAdditionalComp(
                    additionalComponentThree
                  ),
                  additionalComponentFour: this.filterAdditionalComp(
                    additionalComponentFour
                  ),
                  additionalComponentFive: this.filterAdditionalComp(
                    additionalComponentFive
                  ),
                  projectName: this.projectData?.projectName,
                };
              }
            ),
            additionalComponentOne: this.filterAdditionalComp(
              additionalComponentOne
            ),
            additionalComponentTwo: this.filterAdditionalComp(
              additionalComponentTwo
            ),
            additionalComponentThree: this.filterAdditionalComp(
              additionalComponentThree
            ),
            additionalComponentFour: this.filterAdditionalComp(
              additionalComponentFour
            ),
            additionalComponentFive: this.filterAdditionalComp(
              additionalComponentFive
            ),
            projectName: this.projectData?.projectName,
          };
        }
      ),
      invictaServers: [],
      switches: this.projectData.fabrics.map(
        ({
          id,
          index,
          config: {
            quantity,
            systemWorkloadPercent,
            hasValidConfig,
            redundancyMode,
            inputVoltageId,
            powerSupplyId,
            powerSupplyTypeName,
            powerSupplyCount,
            expansionCards,
            activePorts,
            fanPolicyDto,
          },
        }) => {
          return {
            id,
            quantity,
            index,
            hasValidConfig,
            systemWorkloadPercent,
            redundancyMode,
            inputVoltageId,
            powerSupplyId,
            powerSupplyTypeName,
            powerSupplyCount,
            expansionCards,
            activePorts,
            fanPolicyDto,
            projectName: this.projectData?.projectName,
          };
        }
      ),
      mseries: this.projectData.mseriesList.map(
        ({
          id,
          index,
          config: {
            quantity,
            systemWorkloadPercent,
            hasValidConfig,
            redundancyMode,
            inputVoltageId,
            powerSupplyId,
            powerSupplyTypeName,
            powerSupplyCount,
            mezzanineControllerId,
            mezzanineControllerTypeName,
            mezzanineControllerCount,
            dedicatedStorage,
            storage,
            adaptors,
            fanPolicyDto,
          },
          nodes,
          additionalComponentOne,
          additionalComponentTwo,
          additionalComponentThree,
          additionalComponentFour,
          additionalComponentFive,
        }) => {
          return {
            id,
            quantity,
            index,
            hasValidConfig,
            systemWorkloadPercent,
            redundancyMode,
            inputVoltageId,
            powerSupplyId,
            powerSupplyTypeName,
            powerSupplyCount,
            mezzanineControllerId,
            mezzanineControllerTypeName,
            mezzanineControllerCount,
            dedicatedStorage,
            storage,
            fanPolicyDto,
            adaptor: adaptors,
            cartridges: nodes.map(
              ({
                id,
                index,
                chassisId,
                config: {
                  quantity,
                  systemWorkloadPercent,
                  hasValidConfig,
                  processorId,
                  processorTypeName,
                  processorCount,
                  mezzanineControllerId,
                  mezzanineControllerTypeName,
                  mezzanineControllerCount,
                  dedicatedStorage,
                  storage,
                  adaptors,
                  memory,
                  fanPolicyDto,
                },
                additionalComponentOne,
                additionalComponentTwo,
                additionalComponentThree,
                additionalComponentFour,
                additionalComponentFive,
              }) => {
                let filteredStorage = storage.filter((v) => {
                  let vals = Object.values(v);
                  for (let k of vals) {
                    if (!k) return false;
                  }
                  return true;
                });
                let filteredAdaptor = adaptors.filter((v) => {
                  let vals = Object.values(v);
                  for (let k of vals) {
                    if (!k) return false;
                  }
                  return true;
                });
                let filteredDedicatedStorage = dedicatedStorage.filter((v) => {
                  let vals = Object.values(v);
                  for (let k of vals) {
                    if (!k) return false;
                  }
                  return true;
                });
                return {
                  id,
                  chassisId,
                  quantity,
                  index,
                  hasValidConfig,
                  systemWorkloadPercent,
                  processorId,
                  processorTypeName,
                  processorCount,
                  mezzanineControllerId,
                  mezzanineControllerTypeName,
                  mezzanineControllerCount,
                  dedicatedStorage: filteredDedicatedStorage,
                  storage: filteredStorage,
                  adaptor: filteredAdaptor,
                  memory,
                  fanPolicyDto,
                  additionalComponentOne: this.filterAdditionalComp(
                    additionalComponentOne
                  ),
                  additionalComponentTwo: this.filterAdditionalComp(
                    additionalComponentTwo
                  ),
                  additionalComponentThree: this.filterAdditionalComp(
                    additionalComponentThree
                  ),
                  additionalComponentFour: this.filterAdditionalComp(
                    additionalComponentFour
                  ),
                  additionalComponentFive: this.filterAdditionalComp(
                    additionalComponentFive
                  ),
                  projectName: this.projectData?.projectName,
                };
              }
            ),
            additionalComponentOne: this.filterAdditionalComp(
              additionalComponentOne
            ),
            additionalComponentTwo: this.filterAdditionalComp(
              additionalComponentTwo
            ),
            additionalComponentThree: this.filterAdditionalComp(
              additionalComponentThree
            ),
            additionalComponentFour: this.filterAdditionalComp(
              additionalComponentFour
            ),
            additionalComponentFive: this.filterAdditionalComp(
              additionalComponentFive
            ),
            projectName: this.projectData?.projectName,
          };
        }
      ),
      additionalPlatform: [
        ...this.additionalPlatformPayloadMap(
          this.projectData.AdditionalPlatform1
        ),
        ...this.additionalPlatformPayloadMap(
          this.projectData.AdditionalPlatform2
        ),
        ...this.additionalPlatformPayloadMap(
          this.projectData.AdditionalPlatform3
        ),
        ...this.additionalPlatformPayloadMap(
          this.projectData.AdditionalPlatform4
        ),
        ...this.additionalPlatformPayloadMap(
          this.projectData.AdditionalPlatform5
        ),
      ],
    };
    if (!this.isQtyUnderLimit(reqData)) {
      if (!this.istoasterActive) {
        this.toaster.show(
          "error",
          errorCodes.QUANTITY_LIMIT_EXCEEDED,
          "",
          5000,
          false
        );
      }
      this.istoasterActive = true;
      this.projectService.setProjectError(true);
      return;
    }
    let isProjectError = this.checkProjectError();
    if (isProjectError) return;
    // this.projectService.setProjectError(false);
    const url = UrlConstant.PROJECT_POWER;
    this.blockUI.start("Loading...");
    this.subscription.add(
      this.apiService.postPowerMethod(url, reqData).subscribe(
        (result) => {
          this.mapProjectResult(result);
          this.blockUI.stop();
          localStorage.setItem(
            `${this.projectType}-${this.projectId}`,
            JSON.stringify(this.projectData)
          );
          this.removeRedundancyWarnings();
          this.checkForRedundancyError(this.projectData);
          this.subscription.add(
            this.projectService.setCalculatedPower([
              this.projectData.power.inputPower,
              this.projectData.power.inputCurrent,
            ])
          );
        },
        (error) => {
          console.error(error);
          this.projectService.setProjectError(true);
          if (error?.error?.error.includes("messageKey")) {
            const messages = JSON.parse(error?.error?.error);
            const primaryMessage = this.translate.instant(
              messages?.messages[0]?.messageKey
            );
            let secondaryMessage = this.translate.instant(
              messages?.messages[1]?.messageKey
            );
            if (secondaryMessage.includes("Http failure response"))
              secondaryMessage = "";
            if (primaryMessage) {
              this.errorMsg = error?.error ? primaryMessage : "";
            } else {
              this.errorMsg = error?.error
                ? error.error?.error
                : errorCodes.GENERIC_ERROR_MESSAGE;
            }

            this.toaster.show(
              "error",
              this.errorMsg,
              secondaryMessage ? secondaryMessage : error ? error?.message : ""
            );
          } else {
            this.errorMsg = error?.error
              ? error.error?.error
              : errorCodes.GENERIC_ERROR_MESSAGE;
            this.toaster.show(
              "error",
              this.errorMsg,
              error ? error?.message : ""
            );
          }
          window.scroll(0, 0);
          this.blockUI.stop();
        }
      )
    );
  }
  checkForRedundancyError(projectData) {
    let platforms = ['chassisList', 'rackServers', 'mseriesList', 'fabrics'];
    let doesProjectHaveServerWithRedundancyError = false, redundancyWarnings = [];
    if (projectData) {
      for (let platform of platforms) {
        for (let server of projectData[`${platform}`]) {
          if (server.power && server.power.redundancyStatus) {
            let serverRedundancyStatus = this.doesServerHaveRedundancyError(server, true);
            if (serverRedundancyStatus[0].warning === true) {
              doesProjectHaveServerWithRedundancyError = true;
              redundancyWarnings.push(serverRedundancyStatus);
              
            }
          }
        }
      }
    }
    if (doesProjectHaveServerWithRedundancyError) {
      for (let redundancyWarning of redundancyWarnings) {
        if (redundancyWarning[0].powerSupplyStatus == "nonRedundant") {
          this.toaster.remove(errorCodes.NON_REDUNDANT);
          this.toaster.show(
            "warning",
            errorCodes.NON_REDUNDANT,
            errorCodes.NON_REDUNDANT_ERROR,
            604800
          );
        } else if (redundancyWarning[0].powerSupplyStatus == "insufficientCapacity") {
          this.toaster.remove(errorCodes.INSUFFICIENT_REDUNDANCY);
          this.toaster.show(
            "warning",
            errorCodes.INSUFFICIENT_REDUNDANCY,
            errorCodes.INSUFFICIENT_REDUNDANCY_ERROR,
            604800
          );
        } else if (redundancyWarning[0].powerSupplyStatus == "powerCapped") {
          this.toaster.remove(errorCodes.POWER_CAPPED);
          this.toaster.show(
            "warning",
            errorCodes.POWER_CAPPED,
            "",
            604800
          );
        }else if (redundancyWarning[0].powerSupplyStatus == "loadExtendedPowerRequired" && redundancyWarning[0].powerSupplyCountStatusEnum == "selectedMaxPsusForExtended") {
          this.toaster.remove(errorCodes.WARNING_TITLE);
          this.toaster.show(
            "warning",
            errorCodes.WARNING_TITLE,
            errorCodes.LOAD_EXTENDED_POWER_REQUIRED_ERROR+"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_MESSAGE,
            86400000
          );
        }else if (redundancyWarning[0].powerSupplyStatus == "loadExtendedPowerRequired" && redundancyWarning[0].powerSupplyCountStatusEnum == "selectedLessThanMaxPsus") {
          this.toaster.remove(errorCodes.WARNING_TITLE);
          this.toaster.show(
            "warning",
            errorCodes.WARNING_TITLE,
            errorCodes.LOAD_EXTENDED_POWER_REQUIRED_ERROR+"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_EXTENDED_MESSAGE,
            86400000
          );
        }else if(redundancyWarning[0].powerSupplyStatus == "loadNonRedundant" && redundancyWarning[0].powerSupplyCountStatusEnum == "selectedMaxPsusForMaxError") {
          this.toaster.remove(errorCodes.ERROR_TITLE);
          this.toaster.show(
            "error",
            errorCodes.ERROR_TITLE,
            errorCodes.LOAD_NON_REDUNDANT_ERROR+"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_REDUNDANT_MAX_MESSAGE,
            86400000
          );
        }else if(redundancyWarning[0].powerSupplyStatus == "loadNonRedundant" && redundancyWarning[0].powerSupplyCountStatusEnum == "selectedLessThanMaxPsus") {
          this.toaster.remove(errorCodes.ERROR_TITLE);
          this.toaster.show(
            "error",
            errorCodes.ERROR_TITLE,
            errorCodes.LOAD_NON_REDUNDANT_ERROR +"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_EXTENDED_MESSAGE,
            86400000
          );
        }else if (redundancyWarning[0].powerSupplyStatus == "maxExtendedPowerRequired" && redundancyWarning[0].powerSupplyCountStatusEnum == "selectedMaxPsusForExtended") {
          this.toaster.remove(errorCodes.WARNING_TITLE);
          this.toaster.show(
            "warning",
            errorCodes.WARNING_TITLE,
            errorCodes.MAX_EXTENDED_POWER_REQUIRED_ERROR+"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_MESSAGE,
            86400000
          );
        }else if (redundancyWarning[0].powerSupplyStatus == "maxExtendedPowerRequired" && redundancyWarning[0].powerSupplyCountStatusEnum == "selectedLessThanMaxPsus") {
          this.toaster.remove(errorCodes.WARNING_TITLE);
          this.toaster.show(
            "warning",
            errorCodes.WARNING_TITLE,
            errorCodes.MAX_EXTENDED_POWER_REQUIRED_ERROR+"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_EXTENDED_MESSAGE,
            86400000
          );
        }else if(redundancyWarning[0].powerSupplyStatus == "maxNonRedundant" && redundancyWarning[0].powerSupplyCountStatusEnum == "selectedMaxPsusForMaxError") {
          this.toaster.remove(errorCodes.ERROR_TITLE);
          this.toaster.show(
            "error",
            errorCodes.ERROR_TITLE,
            errorCodes.MAX_NON_REDUNDANT_ERROR+"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_REDUNDANT_MAX_MESSAGE,
            86400000
          );
        }else if(redundancyWarning[0].powerSupplyStatus == "maxNonRedundant" && redundancyWarning[0].powerSupplyCountStatusEnum == "selectedLessThanMaxPsus") {
          this.toaster.remove(errorCodes.ERROR_TITLE);
          this.toaster.show(
            "error",
            errorCodes.ERROR_TITLE,
            errorCodes.MAX_NON_REDUNDANT_ERROR +"\n"+ errorCodes.RECOMMENDATION_TITLE +"\n" + errorCodes.PSU_LESS_THAN_MAX_EXTENDED_MESSAGE,
            86400000
          );
        }
      }
      window.scroll(0, 0);
      redundancyWarnings = [];
    } else {
      this.removeRedundancyWarnings();
    }
  }
  mapProjectResult(result: any) {
    if (result) {
      this.projectData.power.inputPower = result?.inputPower;
      this.projectData.power.inputCurrent = result?.inputCurrent;
      this.projectData.power.inputCooling = result?.inputCooling;
      this.projectData.power.inputAirflow = result?.inputAirflow;
      this.projectData.power.maxPower = result?.maxPower;
      this.projectData.power.maxCurrent = result?.maxCurrent;
      this.projectData.power.maxCooling = result?.maxCooling;
      this.projectData.power.maxAirflow = result?.maxAirflow;
      this.projectData.power.idlePower = result?.idlePower;
      this.projectData.power.idleAirflow = result?.idleAirflow;
      this.projectData.power.metric = result?.metric;
      this.projectData.power.imperial = result?.imperial;
      for (let i = 0; i < result?.chassis.serverResults.length; i++) {
        if (
          result?.chassis.serverResults.length > 0 &&
          this.projectData.chassisList[i].index ==
          result?.chassis.serverResults[i].index
        ) {
          this.projectData.chassisList[i].power =
            result?.chassis.serverResults[i];
          // calculating system workload percent
          this.projectData.chassisList[i].power.systemWorkloadPercent = this.projectService.calculateSystemWorkloadForChassis(this.projectData.chassisList[i]);
        }
      }
      for (let i = 0; i < result?.mseries.serverResults.length; i++) {
        if (
          result?.mseries.serverResults.length > 0 &&
          this.projectData.mseriesList[i].index ==
          result?.mseries.serverResults[i].index
        ) {
          this.projectData.mseriesList[i].power =
            result?.mseries.serverResults[i];
          // calculating system workload percent
          this.projectData.mseriesList[i].power.systemWorkloadPercent = this.projectService.calculateSystemWorkloadForChassis(this.projectData.mseriesList[i]);
        }
      }
      for (let i = 0; i < result?.rackServers.serverResults.length; i++) {
        if (
          result?.rackServers.serverResults.length > 0 &&
          this.projectData.rackServers[i].index ==
          result?.rackServers.serverResults[i].index
        ) {
          this.projectData.rackServers[i].power =
            result?.rackServers.serverResults[i];
        }
      }
      for (let i = 0; i < result?.switches.serverResults.length; i++) {
        if (
          result?.switches.serverResults.length > 0 &&
          this.projectData.fabrics[i].index ==
          result?.switches.serverResults[i].index
        ) {
          this.projectData.fabrics[i].power = result?.switches.serverResults[i];
        }
      }
    }
  }

  deleteServer(serverId: string, type: string, chassisId: string) {
    this.checkProjectExists();
    let parentServer = {};
    if (type == "BLADE") {
      parentServer = this.projectData.chassisList.filter((v) => {
        return v.id == chassisId;
      });
      let [parentIdx, removeIndex]: number[] = this.getServerIdxAndParentIdx(
        parentServer,
        type,
        serverId
      );
      parentServer[parentIdx].blades.splice(removeIndex, 1);
      this.updateIndices(parentServer[parentIdx].blades, true);
      const bladeQuantity = parentServer[0].blades
        .map((item) => item.config?.quantity * item?.slotsConsumed)
        .reduce((prev, next) => parseInt(prev) + parseInt(next), 0);
      if (bladeQuantity > parentServer[0].maxBladeSlots) {
        this.projectService.setProjectError(true);
        this.toaster.remove(errorCodes.MAXSLOTS_ERROR);
        this.toaster.show(
          "error",
          errorCodes.MAXSLOTS_ERROR,
          `${bladeQuantity} ` +
          this.translate.instant(`selected blade slots exceeds available `) +
          `${parentServer[0].maxBladeSlots} ` +
          this.translate.instant(`slots`) +
          ` ${parentServer[0].displayName}`,
          5000
        );
        window.scroll(0, 0);
        return;
      }
    }
    if (type == "CARTRIDGE") {
      parentServer = this.projectData.mseriesList.filter((v) => {
        return v.id == chassisId;
      });
      let [parentIdx, removeIndex]: number[] = this.getServerIdxAndParentIdx(
        parentServer,
        type,
        serverId
      );
      parentServer[parentIdx].nodes.splice(removeIndex, 1);
      this.updateIndices(parentServer[parentIdx].nodes, true);
      const nodeQuantity = parentServer[0].nodes
        .map((item) => item.config?.quantity * item?.slotsConsumed)
        .reduce((prev, next) => parseInt(prev) + parseInt(next), 0);
      if (nodeQuantity > parentServer[0].maxCartridgeSlots) {
        this.projectService.setProjectError(true);
        this.toaster.remove(errorCodes.MAXSLOTS_ERROR);
        this.toaster.show(
          "error",
          errorCodes.MAXSLOTS_ERROR,
          `${nodeQuantity} ` +
          this.translate.instant(`selected node slots exceeds available `) +
          `${parentServer[0].maxCartridgeSlots} ` +
          this.translate.instant(`slots`) +
          ` ${parentServer[0].displayName}`,
          5000
        );
        window.scroll(0, 0);
        return;
      }
    }
    this.subscription.add(this.projectService.setServerEditStatus(false));
    localStorage.setItem("setServerEditStatus", "false");
    sessionStorage.setItem("setServerEditStatus", "false");
    this.projectData.updatedDate = new Date().toISOString();
    localStorage.setItem(
      `${this.projectType}-${this.projectId}`,
      JSON.stringify(this.projectData)
    );
    this.getProjectData();
    this.navigateToProjectDetails("Compute");
  }
  validateDeleteServerConfig() { }
  getServerIdxAndParentIdx(parentServer, type: string, serverId: string) {
    let parentIdx = 0;
    let serverIdx = -1;
    while (serverIdx == -1) {
      if (!parentServer[parentIdx]) break;
      if (type == "BLADE") {
        serverIdx = parentServer[parentIdx].blades
          .map((item) => {
            return item.serverId;
          })
          .indexOf(serverId);
      } else if (type == "CARTRIDGE") {
        serverIdx = parentServer[parentIdx].nodes
          .map((item) => {
            return item.serverId;
          })
          .indexOf(serverId);
      }
      if (serverIdx !== -1) break;
      parentIdx++;
    }
    return [parentIdx, serverIdx];
  }
  editBladeServer(
    bladeServerId: string,
    bladeId: string,
    type: string,
    chassisId: string,
    voltageId: string,
    powerSupplyId: string,
    powerSupplyCount: string
  ) {
    this.checkProjectExists();
    let bladeServer = {};
    if (type == "BLADE") {
      this.editChildServerName = bladeServerId;
      bladeServer = this.projectData.chassisList.filter((v) => {
        return v.id == chassisId;
      });
      let [parentIdx, serverIdx]: number[] = this.getServerIdxAndParentIdx(
        bladeServer,
        type,
        bladeServerId
      );

      let blade = bladeServer[parentIdx].blades.filter((v) => {
        return v.serverId == bladeServerId;
      });
      blade.updatedDate = new Date().toISOString();
      this.subscription.add(
        this.projectService.setCurrentChassis([
          blade.pid,
          powerSupplyId,
          powerSupplyCount,
          chassisId,
          voltageId,
          type,
        ])
      );
      localStorage.setItem(
        `${this.projectType}-${this.projectId}`,
        JSON.stringify(this.projectData)
      );
      localStorage.setItem("currentProductId", blade[0].pid);
      localStorage.setItem("voltage", voltageId);
      localStorage.setItem("currentSequenceId", blade[0].id);
      localStorage.setItem("parentSequenceId", chassisId); //sequenceid

      sessionStorage.setItem("currentProductId", blade[0].pid);
      sessionStorage.setItem("voltage", voltageId);
      sessionStorage.setItem("currentSequenceId", blade[0].id);
      sessionStorage.setItem("parentSequenceId", chassisId); //sequenceid

      this.setCurrentServerInfo(blade[0]);

      //set Reqpayload null
      this.projectService.setReqPayload(null);

      const chassisPower = {
        powerSupplyId: powerSupplyId,
        powerSupplyCount: powerSupplyCount,
        inputVoltageId: voltageId,
      };
      blade[0].chassisPower = chassisPower;
      this.activatedRoute.url.subscribe((activeUrl) => {
        const url = window.location.pathname;
        if (url.includes("serverDetails")) {
          this.subscription.add(this.projectService.setCurrentServer(blade[0]));
          this.projectService.setServerFlag(true);
          localStorage.setItem("setCurrentServer", JSON.stringify(blade[0]));
          sessionStorage.setItem("setCurrentServer", JSON.stringify(blade[0]));
        } else {
          this.router.navigate(["serverDetails"], {
            relativeTo: this.activatedRoute,
          });
          this.subscription.add(this.projectService.setCurrentServer(blade[0]));
          this.projectService.setServerFlag(true);
          localStorage.setItem("setCurrentServer", JSON.stringify(blade[0]));
          sessionStorage.setItem("setCurrentServer", JSON.stringify(blade[0]));
        }
      });
      this.projectService.setServerEditStatus(true);
      localStorage.setItem("setServerEditStatus", "true");
      sessionStorage.setItem("setServerEditStatus", "true");
      this.removeRedundancyWarnings();
    }
  }

  editNodeServer(
    nodeServerId: string,
    nodeId: string,
    type: string,
    chassisId: string,
    voltageId: string,
    powerSupplyId: string,
    powerSupplyCount: string
  ) {
    this.checkProjectExists();
    let nodeServer = {};
    if (type == "CARTRIDGE") {
      this.editChildNodeName = nodeServerId;
      nodeServer = this.projectData.mseriesList.filter((v) => {
        return v.id == chassisId;
      });
      let [parentIdx, serverIdx]: number[] = this.getServerIdxAndParentIdx(
        nodeServer,
        type,
        nodeServerId
      );

      let node = nodeServer[parentIdx].nodes.filter((v) => {
        return v.serverId == nodeServerId;
      });
      node.updatedDate = new Date().toISOString();
      this.subscription.add(
        this.projectService.setCurrentChassis([
          node.pid,
          powerSupplyId,
          powerSupplyCount,
          chassisId,
          voltageId,
          type,
        ])
      );
      localStorage.setItem(
        `${this.projectType}-${this.projectId}`,
        JSON.stringify(this.projectData)
      );
      localStorage.setItem("currentProductId", node[0].pid);
      localStorage.setItem("voltage", voltageId);
      localStorage.setItem("currentSequenceId", node[0].id);
      localStorage.setItem("parentSequenceId", chassisId); //sequenceid

      sessionStorage.setItem("currentProductId", node[0].pid);
      sessionStorage.setItem("voltage", voltageId);
      sessionStorage.setItem("currentSequenceId", node[0].id);
      sessionStorage.setItem("parentSequenceId", chassisId); //sequenceid
      this.setCurrentServerInfo(node[0]);

      //set Reqpayload null
      this.projectService.setReqPayload(null);

      const chassisPower = {
        powerSupplyId: powerSupplyId,
        powerSupplyCount: powerSupplyCount,
        inputVoltageId: voltageId,
      };
      node[0].chassisPower = chassisPower;
      this.activatedRoute.url.subscribe((activeUrl) => {
        const url = window.location.pathname;
        if (url.includes("serverDetails")) {
          this.subscription.add(this.projectService.setCurrentServer(node[0]));
          this.projectService.setServerFlag(true);
          localStorage.setItem("setCurrentServer", JSON.stringify(node[0]));
          sessionStorage.setItem("setCurrentServer", JSON.stringify(node[0]));
        } else {
          this.router.navigate(["serverDetails"], {
            relativeTo: this.activatedRoute,
          });
          this.subscription.add(this.projectService.setCurrentServer(node[0]));
          this.projectService.setServerFlag(true);
          localStorage.setItem("setCurrentServer", JSON.stringify(node[0]));
          sessionStorage.setItem("setCurrentServer", JSON.stringify(node[0]));
        }
      });
      this.projectService.setServerEditStatus(true);
      localStorage.setItem("setServerEditStatus", "true");
      sessionStorage.setItem("setServerEditStatus", "true");
      this.removeRedundancyWarnings();
    }
  }
  changeBladeQuantity(
    event: any,
    serverId: string,
    chassisId: string,
    type: string
  ) {
    this.checkProjectExists();
    let parentServer = {};
    if (type == "BLADE") {
      parentServer = this.projectData.chassisList.filter((v) => {
        return v.id == chassisId;
      });
      let [parentIdx, serverIdx]: number[] = this.getServerIdxAndParentIdx(
        parentServer,
        type,
        serverId
      );
      let blade = parentServer[parentIdx].blades.filter((v) => {
        return v.serverId == serverId;
      });
      if (!parentServer[parentIdx]?.config?.quantity) {
        if (!this.istoasterActive) {
          this.toaster.show(
            "error",
            errorCodes.QUANTITY_POSITIVE_NUMBER,
            "",
            5000,
            false
          );
          this.istoasterActive = true;
        }
        blade[0].config.flag = 1;
        blade[0].config.quantity = parseInt(event.target.value);
        localStorage.setItem(`setCurrentServer`, JSON.stringify(blade[0]));
        sessionStorage.setItem(`setCurrentServer`, JSON.stringify(blade[0]));
        this.projectService.setProjectError(true);
        return;
      }
      parentServer[parentIdx].config.flag = null;
      if (parseInt(event.target.value) <= 0) {
        if (!this.istoasterActive) {
          this.toaster.show(
            "error",
            errorCodes.QUANTITY_POSITIVE_NUMBER,
            "",
            5000,
            false
          );
          this.istoasterActive = true;
        }
        blade[0].config.flag = 1;
        blade[0].config.quantity = parseInt(event.target.value);
        localStorage.setItem(`setCurrentServer`, JSON.stringify(blade[0]));
        sessionStorage.setItem(`setCurrentServer`, JSON.stringify(blade[0]));
        this.projectService.setProjectError(true);
        return;
      } else {
        this.istoasterActive = false;
        blade[0].config.flag = null;
        this.toaster.remove(errorCodes.QUANTITY_POSITIVE_NUMBER);
      }
      blade[0].config.quantity = parseInt(event.target.value);
      localStorage.setItem(`setCurrentServer`, JSON.stringify(blade[0]));
      sessionStorage.setItem(`setCurrentServer`, JSON.stringify(blade[0]));
      const bladeQuantity = parentServer[parentIdx].blades
        .map((item) => item.config?.quantity * item?.slotsConsumed)
        .reduce((prev, next) => parseInt(prev) + parseInt(next));

      if (bladeQuantity <= parentServer[parentIdx].maxBladeSlots) {
        if (bladeQuantity < parentServer[parentIdx].maxBladeSlots)
          this.isBladeQtyLimitReached = false;
        else this.isBladeQtyLimitReached = true;
        this.toaster.remove(errorCodes.MAXSLOTS_ERROR);
        let isProjectError = this.checkProjectError();
        // this.projectService.setProjectError(false);
        if (isProjectError) return;
        this.projectData.updatedDate = new Date().toISOString();
        localStorage.setItem(
          `${this.projectType}-${this.projectId}`,
          JSON.stringify(this.projectData)
        );
        this.getProjectData();
      } else {
        this.isBladeQtyLimitReached = true;
        blade[0].config.quantity = blade[0].config.quantity; //set prevoius value
        this.projectService.setProjectError(true);
        this.toaster.remove(errorCodes.MAXSLOTS_ERROR);
        this.toaster.show(
          "error",
          errorCodes.MAXSLOTS_ERROR,
          `${bladeQuantity}  ` +
          this.translate.instant(`selected blade slots exceeds available `) +
          ` ${parentServer[0].maxBladeSlots} ` +
          this.translate.instant(`slots`) +
          ` ${parentServer[0].displayName}`,
          5000
        );
        window.scroll(0, 0);
      }
    }
  }

  changeNodeQuantity(
    event: any,
    serverId: string,
    chassisId: string,
    type: string
  ) {
    this.checkProjectExists();
    let parentServer = {};
    if (type == "CARTRIDGE") {
      parentServer = this.projectData.mseriesList.filter((v) => {
        return v.id == chassisId;
      });
      let [parentIdx, serverIdx]: number[] = this.getServerIdxAndParentIdx(
        parentServer,
        type,
        serverId
      );
      let node = parentServer[parentIdx].nodes.filter((v) => {
        return v.serverId == serverId;
      });
      if (!parentServer[parentIdx]?.config?.quantity) {
        if (!this.istoasterActive) {
          this.toaster.show(
            "error",
            errorCodes.QUANTITY_POSITIVE_NUMBER,
            "",
            5000,
            false
          );
          this.istoasterActive = true;
        }
        node[0].config.flag = 1;
        node[0].config.quantity = parseInt(event.target.value);
        localStorage.setItem(`setCurrentServer`, JSON.stringify(node[0]));
        sessionStorage.setItem(`setCurrentServer`, JSON.stringify(node[0]));
        this.projectService.setProjectError(true);
        return;
      }
      parentServer[parentIdx].config.flag = null;
      if (parseInt(event.target.value) <= 0) {
        if (!this.istoasterActive) {
          this.toaster.show(
            "error",
            errorCodes.QUANTITY_POSITIVE_NUMBER,
            "",
            5000,
            false
          );
          this.istoasterActive = true;
        }
        node[0].config.flag = 1;
        node[0].config.quantity = parseInt(event.target.value);
        localStorage.setItem(`setCurrentServer`, JSON.stringify(node[0]));
        sessionStorage.setItem(`setCurrentServer`, JSON.stringify(node[0]));
        this.projectService.setProjectError(true);
        return;
      } else {
        this.istoasterActive = false;
        node[0].config.flag = null;
        this.toaster.remove(errorCodes.QUANTITY_POSITIVE_NUMBER);
      }
      node[0].config.quantity = parseInt(event.target.value);
      localStorage.setItem(`setCurrentServer`, JSON.stringify(node[0]));
      sessionStorage.setItem(`setCurrentServer`, JSON.stringify(node[0]));
      const nodeQuantity = parentServer[parentIdx].nodes
        .map((item) => item.config?.quantity * item?.slotsConsumed)
        .reduce((prev, next) => parseInt(prev) + parseInt(next));
      if (nodeQuantity <= parentServer[parentIdx].maxCartridgeSlots) {
        if (nodeQuantity < parentServer[parentIdx].maxCartridgeSlots)
          this.isNodeQtyLimitReached = false;
        else this.isNodeQtyLimitReached = true;
        this.toaster.remove(errorCodes.MAXSLOTS_ERROR);
        let isProjectError = this.checkProjectError();
        // this.projectService.setProjectError(false);
        if (isProjectError) return;
        this.projectData.updatedDate = new Date().toISOString();
        localStorage.setItem(
          `${this.projectType}-${this.projectId}`,
          JSON.stringify(this.projectData)
        );
        this.getProjectData();
      } else {
        this.isNodeQtyLimitReached = true;
        node[0].config.quantity = node[0].config.quantity; //set prevoius value
        this.projectService.setProjectError(true);
        this.toaster.remove(errorCodes.MAXSLOTS_ERROR);
        this.toaster.show(
          "error",
          errorCodes.MAXSLOTS_ERROR,
          `${nodeQuantity} ` +
          this.translate.instant(`selected node slots exceeds available `) +
          `${parentServer[0].maxCartridgeSlots} ` +
          this.translate.instant(`slots`) +
          ` ${parentServer[0].displayName}`,
          5000
        );
        window.scroll(0, 0);
      }
    }
  }

  changeBladeName(
    event: any,
    bladeServerId: any,
    bladeId: any,
    chassisId: any
  ) {
    this.checkProjectExists();
    let server = this.projectData.chassisList.filter((v) => {
      return v.id == chassisId;
    });
    let blade = server[0].blades.filter((v) => {
      return v.serverId == bladeServerId;
    });
    blade[0].userProvidedName = event.target.value;
    this.projectData.updatedDate = new Date().toISOString();
    localStorage.setItem(
      `${this.projectType}-${this.projectId}`,
      JSON.stringify(this.projectData)
    );
    let curServer = JSON.parse(sessionStorage.getItem("setCurrentServer"));
    curServer.userProvidedName = event.target.value;
    localStorage.setItem(`setCurrentServer`, JSON.stringify(curServer));
    sessionStorage.setItem(`setCurrentServer`, JSON.stringify(curServer));
  }
  enumerate(length: number): Array<any> {
    if (length >= 0) {
      return new Array(length);
    }
  }
  doesAdditionalPlatformExist(i: number) {
    return (
      this.projectData[`AdditionalPlatform${i}`] &&
      this.projectData[`AdditionalPlatform${i}`].length > 0
    );
  }
  getAdditionalPlatformPrimaryClass(i: number): string {
    return this.doesAdditionalPlatformExist(i)
      ? this.projectData[`AdditionalPlatform${i}`][0].primaryClass
      : "";
  }
  getAdditionalPlatform(i: number) {
    return this.projectData[`AdditionalPlatform${i}`];
  }
  additionalPlatformsAdded(): boolean {
    let res = false;
    for (let i = 1; i <= 5; i++) {
      if (this.doesAdditionalPlatformExist(i)) {
        res = true;
        break;
      }
    }
    return res;
  }
  additionalPlatformsAddedByClass(primaryClass: string): boolean {
    let res = false;
    for (let i = 1; i <= 5; i++) {
      if (
        this.doesAdditionalPlatformExist(i) &&
        this.getAdditionalPlatformPrimaryClass(i) == primaryClass
      ) {
        res = true;
        break;
      }
    }
    return res;
  }
  changeNodeName(event: any, nodeServerId: any, nodeId: any, chassisId: any) {
    this.checkProjectExists();
    let server = this.projectData.mseriesList.filter((v) => {
      return v.id == chassisId;
    });
    let node = server[0].nodes.filter((v) => {
      return v.serverId == nodeServerId;
    });
    node[0].userProvidedName = event.target.value;
    this.projectData.updatedDate = new Date().toISOString();
    localStorage.setItem(
      `${this.projectType}-${this.projectId}`,
      JSON.stringify(this.projectData)
    );
    let curServer = JSON.parse(sessionStorage.getItem("setCurrentServer"));
    curServer.userProvidedName = event.target.value;
    localStorage.setItem(`setCurrentServer`, JSON.stringify(curServer));
    sessionStorage.setItem(`setCurrentServer`, JSON.stringify(curServer));
  }
  getTotalSlotsConsumed(arr: any[]): number {
    return arr
      .map((b) => b.slotsConsumed * b.config?.quantity)
      .reduce((a, b) => a + b, 0);
  }
  public async deleteServerOpenModal(
    serverId: string,
    type: string,
    chassisId: string,
    server: any
  ) {
    const result = await this.modalService
      .open({
        content: {
          type: CngContentConfigType.COMPONENT,
          content: ConfirmationBoxComponent,
          componentData: {
            someText: this.translate.instant("ARE_YOU_SURE_TO_DELETE_SERVER"),
            key: "deleteServer",
            chassisId: chassisId,
            type: type,
            serverId: serverId,
            server: server,
          },
        },
        size: CngModalSize.SMALL,
      })
      .onDismiss.toPromise();
  }
  // firstToggle: boolean = false;
  // @HostListener('document:click', ['$event'])
  // toggle(event) {
  //   let serverRef = this.editServerRef ? this.editServerRef : (this.editChildServerRef ? this.editChildServerRef : null);
  //   if (serverRef) {
  //     if (this.clickCount == 0) {
  //       this.clickCount++; this.firstToggle = true;
  //     } else {
  //       this.firstToggle = false;
  //     }
  //   }
  //   if (!this.firstToggle && serverRef && this.clickCount >= 1 && (event.target.value !== serverRef.elementRef.nativeElement.parentElement.children[1].children[0].value)) {
  //     this.editServerElementName = '';
  //     this.clickCount = 0;
  //     this.firstToggle = false;
  //   }
  // }
  displayFormattedRMode(rMode: string): string {
    if (!rMode) return "";
    rMode = rMode.trim();
    if (rMode == "N1") return "N+1";
    else if (rMode == "NN") return "N+N";
    else if (rMode == "N2") return "N+2";
    else return "N";
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
