<div class="final-results-container" id="final-results-container">
    <div class="row menu-row">
        <div class="col-md-9"></div>
        <div class="col-md-3 menu-container" style="display: flex; justify-content: flex-end;">
            <button class="btn btn--primary" (click)="onEditProject()">{{'EDIT' | translate}}</button>
            <button class="btn btn--primary" [matMenuTriggerFor]="menu">{{'EXPORT' | translate}} <span
                    class="icon-dropdown icon-size-16"></span></button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="downloadCSV()">{{'AS_CSV' | translate}}</button>
                <button mat-menu-item (click)="downloadCSV(true)">As UCS Layout.csv</button>
                <button mat-menu-item (click)="downloadPRJ()">{{'AS_PRJ' | translate }}</button>
                <button mat-menu-item (click)="downloadPDF()">{{'DOWNLOAD_PDF' | translate }}</button>
            </mat-menu>
        </div>
    </div>
    <div class="row div-server-name" id="projectName">
        <h5>{{projectData?.projectName}} {{'POWER_SUMMARY'| translate}}</h5>
    </div>
    <div class="row div-project-details">
        <form [formGroup]="resultsForm" (ngSubmit)="onSubmit()">
            <div class="row row-project">
                <div class="col-md-2 no-display"> <label for="select-type-basic"
                        class="select-label results-label">{{'IMPERIAL_OR_METRIC'
                        |translate}}</label></div>
                <div class="col-md-6 no-display result-project-details">
                    <div class="form-group dropdown grp-voltage">
                        <div class="form-group__text select div-mode">
                            <select id="select-type" class="select-small mode"
                                (ngModelChange)="changeGlobalUnit($event)" formControlName="projectUnit">
                                <option value="Imperial">{{ 'IMPERIAL' | translate }} </option>
                                <option value="Metric">{{ 'METRIC' | translate }} </option>
                            </select>
                            <!-- <label for="select-type-basic" class="select-label results-label">{{'IMPERIAL_OR_METRIC'
                            |translate}}</label> -->
                        </div>
                    </div>
                </div>

            </div>
            <div class="row row-project no-display" *ngIf="isUserLoggedIn">

                <div class="col-md-2"><label>Region </label></div>
                <div class="col-md-6">
                    <div class="form-group div-emission-cost">
                        <div class="form-group__text div-power">
                            <ng-select appendTo="body" appearance="outline" [searchable]="true" class="select-small " formControlName="country"
                            (change)="changeCountry($event)"
                            placeholder="Select Region"
                            [ngClass]="(resultsForm.get('country').invalid) ? 'select-danger': 'select-normal'"
                            [items]="countries">
                            <ng-option
                              *ngFor="let element of countries">
                                {{element | translate }}
                            </ng-option>
                          </ng-select>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row row-project no-display">

                <div class="col-md-2"><label>{{'POWER_COST_PER_KWHR' | translate}}</label></div>
                <div class="col-md-6">
                    <div class="form-group div-power-cost">
                        <div class="form-group__text div-power">
                            <input id="input-state-focus" type="number" class="input-cost" min="0"
                                (change)="changePowerCost($event)" formControlName="powerCost" autocomplete="off"
                                [ngClass]="(resultsForm.get('powerCost').valid) ?  'select-normal' : 'select-danger'">
                        </div>
                    </div>
                </div>

            </div>
            <div class="row row-project emission-div">

                <div class="col-md-2"><label>Emission Cost per kWhr ($)</label></div>
                <div class="col-md-6">
                    <div class="form-group div-emission-cost">
                        <div class="form-group__text div-power">
                            <input disabled id="input-state-focus" type="number" class="input-cost" min="0"
                                (change)="changeEmissionFactor($event)" formControlName="emissionFactor" autocomplete="off"
                                [ngClass]="(resultsForm.get('emissionFactor').valid) ?  'select-normal' : 'select-danger'">
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>
    <div id="printDiv">
        <div class="row div-server-name">
            <h5>{{'RESULTS' |translate}}</h5>
        </div>
        <!-- dynamic totals -->
        <div class="row">
            <div class="result-details">
                <table class="results-table" id="resultsTable" style="border: 1px solid lightgray;width: 100%;
            padding:10px;border-collapse: collapse;">
                    <tr>
                        <th class="cellColor row-header" style="border: 1px solid lightgray;"></th>
                        <th class="cellColor" style="border: 1px solid lightgray;">{{'SYSTEM_WORKLOAD_FACTOR_W' |
                            translate}}&nbsp;<span
                                *ngIf="projectUnit == 'Imperial'">({{projectData?.power?.imperial?.powerUnit}})</span>
                            <span *ngIf="projectUnit == 'Metric'">({{projectData?.power?.metric?.powerUnit}})</span>
                        </th>
                        <th class="cellColor" style="border: 1px solid lightgray;">{{'MAX_POWER_W' |
                            translate}}&nbsp;<span
                                *ngIf="projectUnit == 'Imperial'">({{projectData?.power?.imperial?.powerUnit}})</span>
                            <span *ngIf="projectUnit == 'Metric'">({{projectData?.power?.metric?.powerUnit}})</span>
                        </th>
                        <th class="cellColor" style="border: 1px solid lightgray;">{{'MAX_CURRENT_DRAW' |
                            translate}}&nbsp;<span
                                *ngIf="projectUnit == 'Imperial'">({{projectData?.power?.imperial?.currentUnit}})</span>
                            <span *ngIf="projectUnit == 'Metric'">({{projectData?.power?.metric?.currentUnit}})</span>
                        </th>
                        <th class="cellColor" style="border: 1px solid lightgray;">{{'IDLE_POWER' |
                            translate}}&nbsp;<span
                                *ngIf="projectUnit == 'Imperial'">({{projectData?.power?.imperial?.powerUnit}})</span>
                            <span *ngIf="projectUnit == 'Metric'">({{projectData?.power?.metric?.powerUnit}})</span>
                        </th>
                        <th class="cellColor" style="border: 1px solid lightgray;">{{'COOLING' |
                            translate}}&nbsp; (BTU/hr)
                        </th>
                        <th class="cellColor" style="border: 1px solid lightgray;">GHG Emissions&nbsp; 
                            <span
                            *ngIf="projectUnit == 'Imperial'">(ton CO2e/year)</span>
                        <span *ngIf="projectUnit == 'Metric'">(metric tonnes CO2e/year)</span>
                        </th>
                    </tr>
                    <tr *ngFor="let partitionTotal of partitionTotals">
                        <td class="cellColor row-header" style="border: 1px solid lightgray;">{{
                            partitionTotal.seriesName | translate}}
                        </td>
                        <td class="cellContent" style="border: 1px solid lightgray;">
                            {{partitionTotal.result.totalSytemWorkLoad |number:'1.1-1'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;">
                            {{partitionTotal.result.totalMaxPower |number:'1.1-1'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;">
                            {{partitionTotal.result.totalMaxCurrent |number:'1.2-2'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;">
                            {{partitionTotal.result.totalIdlePower |number:'1.1-1'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;" *ngIf="projectUnit == 'Imperial'">
                            {{partitionTotal.result.totalCoolingImperial |number:'1.1-1'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;" *ngIf="projectUnit == 'Metric'">
                            {{partitionTotal.result.totalCoolingImperial |number:'1.1-1'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;">
                            <span *ngIf="projectUnit=='Imperial'">{{partitionTotal.result.totalEmissions * imperialEmissionConstt
                                |number:'1.2-2'}}</span>
                            <span *ngIf="projectUnit=='Metric'">{{partitionTotal.result.totalEmissions * metricEmissionConstt
                                |number:'1.2-2'}}</span> 
                        </td>
                    </tr>

                    <tr>
                        <td class="cellColor row-header" style="border: 1px solid lightgray;">{{'TOTAL' | translate}}
                        </td>
                        <td class="cellContent" style="border: 1px solid lightgray;">{{totalSystemWorkLoad
                            |number:'1.1-1'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;">{{totalMaxPower |number:'1.1-1'}}
                        </td>
                        <td class="cellContent" style="border: 1px solid lightgray;">{{totalMaxCurrent |number:'1.2-2'}}
                        </td>
                        <td class="cellContent" style="border: 1px solid lightgray;">{{totalIdlePower |number:'1.1-1'}}
                        </td>
                        <td class="cellContent" style="border: 1px solid lightgray;" *ngIf="projectUnit == 'Imperial'">
                            {{totalImperialCooling |number:'1.1-1'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;" *ngIf="projectUnit == 'Metric'">
                            {{totalImperialCooling |number:'1.1-1'}}</td>
                         <td  class="cellContent" style="border: 1px solid lightgray;">
                            <span *ngIf="projectUnit=='Imperial'">{{annualCostEmissionSystemWorkload * imperialEmissionConstt
                                |number:'1.2-2'}}</span>
                            <span *ngIf="projectUnit=='Metric'">{{annualCostEmissionSystemWorkload * metricEmissionConstt
                                |number:'1.2-2'}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="cellColor row-header" style="border: 1px solid lightgray;">{{'ANNUAL_COST' |
                            translate}} kWh</td>
                        <td class="cellContent" style="border: 1px solid lightgray;">${{annualCostSytemWorload
                            |number:'1.2-2'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;">${{annualCostMaxPower
                            |number:'1.2-2'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;"></td>
                        <td class="cellContent" style="border: 1px solid lightgray;">${{annualCostIdlePower
                            |number:'1.2-2'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;"></td>
                        <td  class="cellContent" style="border: 1px solid lightgray;">

                        </td>
                    </tr>
                    <!-- <tr *ngIf="isUserLoggedIn && projectUnit == 'Imperial'">
                        <td class="cellColor row-header" style="border: 1px solid lightgray;">GHG Emissions</td>
                        <td class="cellContent" style="border: 1px solid lightgray;">${{annualCostEmissionSystemWorkload * imperialEmissionConstt
                            |number:'1.2-2'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;">${{annualCostEmissionMaxPower * imperialEmissionConstt
                            |number:'1.2-2'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;"></td>
                        <td class="cellContent" style="border: 1px solid lightgray;">${{annualCostEmissionIdlePower * imperialEmissionConstt
                            |number:'1.2-2'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;"></td>
                    </tr>
                    <tr *ngIf="isUserLoggedIn && projectUnit == 'Metric'">
                        <td class="cellColor row-header" style="border: 1px solid lightgray;">GHG Emissions</td>
                        <td class="cellContent" style="border: 1px solid lightgray;">${{annualCostEmissionSystemWorkload * metricEmissionConstt
                            |number:'1.2-2'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;">${{annualCostEmissionMaxPower * metricEmissionConstt
                            |number:'1.2-2'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;"></td>
                        <td class="cellContent" style="border: 1px solid lightgray;">${{annualCostEmissionIdlePower * metricEmissionConstt
                            |number:'1.2-2'}}</td>
                        <td class="cellContent" style="border: 1px solid lightgray;"></td>
                    </tr> -->
                </table>
            </div>
        </div>
        <!-- end dynamic -->
        <!-- dynamic individuals -->
        <div *ngFor="let partition of finalPartitions">
            <div class="row div-server-name" *ngIf="partition[0].type == 'FI' || partition[0].type == 'NEXUS'">
                <h5>{{ 'FABRICS' |translate}}</h5>
            </div>
            <div class="row div-server-name" *ngIf="partition[0].type !== 'FI' && partition[0].type !== 'NEXUS'">
                <h5>{{ partition[0].seriesDisplayName | translate}} </h5>
            </div>

            <div class="row" *ngIf="partition.length > 0">
                <div class="result-details">
                    <table class="results-table" style="border: 1px solid lightgray;
                    padding:10px;border-collapse: collapse;">
                        <thead>
                            <th class="cellColor" rowspan="2" style="border: 1px solid lightgray;"
                                *ngIf="partition[0].type == 'FI' || partition[0].type == 'NEXUS'">{{ 'FABRICS' |
                                translate}} </th>
                            <th class="cellColor" rowspan="2" style="border: 1px solid lightgray;"
                                *ngIf="partition[0].type !== 'FI' && partition[0].type !== 'NEXUS'">{{
                                partition[0].seriesDisplayName | translate}} </th>
                            <th colspan="3" class="cellColor" style="border: 1px solid lightgray;">
                                {{'SYSTEM_WORKLOAD_FACTOR' | translate}}</th>
                            <th class="cellColor" rowspan="2" style="border: 1px solid lightgray;">{{'MAX_POWER_W' |
                                translate}}&nbsp;<span
                                    *ngIf="projectUnit == 'Imperial'">({{projectData?.power?.imperial?.powerUnit}})</span>
                                <span *ngIf="projectUnit == 'Metric'">({{projectData?.power?.metric?.powerUnit}})</span>
                            </th>
                            <th class="cellColor" rowspan="2" style="border: 1px solid lightgray;">{{'MAX_CURRENT' |
                                translate}}&nbsp;<span
                                    *ngIf="projectUnit == 'Imperial'">({{projectData?.power?.imperial?.currentUnit}})</span>
                                <span
                                    *ngIf="projectUnit == 'Metric'">({{projectData?.power?.metric?.currentUnit}})</span>
                            </th>
                            <th class="cellColor" rowspan="2" style="border: 1px solid lightgray;">{{'IDLE_POWER' |
                                translate}}&nbsp;<span
                                    *ngIf="projectUnit == 'Imperial'">({{projectData?.power?.imperial?.powerUnit}})</span>
                                <span *ngIf="projectUnit == 'Metric'">({{projectData?.power?.metric?.powerUnit}})</span>
                            </th>
                            <th class="cellColor" rowspan="2" style="border: 1px solid lightgray;">{{'IDLE_CURRENT' |
                                translate}}&nbsp;<span
                                    *ngIf="projectUnit == 'Imperial'">({{projectData?.power?.imperial?.currentUnit}})</span>
                                <span
                                    *ngIf="projectUnit == 'Metric'">({{projectData?.power?.metric?.currentUnit}})</span>
                            </th>
                            <th class="cellColor" rowspan="2" style="border: 1px solid lightgray;">{{'AIR_FLOW' |
                                translate}}&nbsp;<span
                                    *ngIf="projectUnit == 'Imperial'">({{projectData?.power?.imperial?.airflowUnit}})</span>
                                <span
                                    *ngIf="projectUnit == 'Metric'">({{projectData?.power?.metric?.airflowUnit}})</span>
                            </th>
                            <th class="cellColor" rowspan="2" style="border: 1px solid lightgray;">{{'ACOUSTICS' |
                                translate}}&nbsp;<span
                                    *ngIf="projectUnit == 'Imperial'">({{projectData?.power?.imperial?.acousticsUnit}})</span>
                                <span
                                    *ngIf="projectUnit == 'Metric'">({{projectData?.power?.metric?.acousticsUnit}})</span>
                            </th>
                            <th class="cellColor" rowspan="2" style="border: 1px solid lightgray;">{{'WEIGHTLBS' |
                                translate}}&nbsp;<span
                                    *ngIf="projectUnit == 'Imperial'">({{projectData?.power?.imperial?.weightUnit}})</span>
                                <span
                                    *ngIf="projectUnit == 'Metric'">({{projectData?.power?.metric?.weightUnit}})</span>
                            </th>
                            <th class="cellColor" rowspan="2" style="border: 1px solid lightgray;">Redundancy Mode&nbsp;
                            </th>
                            <tr class="sub-header">
                                <th class="cellColor" style="width: 160px;border: 1px solid lightgray;">{{'POWER' |
                                    translate }}</th>
                                <th class="cellColor" style="width: 160px;border: 1px solid lightgray;">{{'CURRENT' |
                                    translate}}</th>
                                <th class="cellColor" style="width: 160px;border: 1px solid lightgray;">{{'COOLING' |
                                    translate}}&nbsp; (BTU/hr) 
                                    <!-- <span
                                        *ngIf="projectUnit == 'Imperial'">({{projectData?.power?.imperial?.coolingUnit}})</span>
                                    <span
                                        *ngIf="projectUnit == 'Metric'">({{projectData?.power?.metric?.coolingUnit}})</span> -->
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let partitionServer of partition; let b = index">
                            <tr>
                                <td class="server-name-wrapper" style="border: 1px solid lightgray;">
                                    <div class="cellColor row-header" style="padding:10px;"
                                        [ngClass]="doesServerHaveRedundancyError(partitionServer) ? 'redundancy-warning': ''">
                                        <span *ngIf="doesServerHaveRedundancyError(partitionServer)"
                                            class="icon-warning redundancy-warning"></span>
                                        {{partitionServer?.config?.quantity}} X {{partitionServer?.displayName}}
                                    </div>
                                    <div class="view-more">
                                        <a (click)="onViewMore( partitionServer,  'bseries')">{{'VIEW_MORE' |
                                            translate}}
                                            <span *ngIf="!partitionServer.opened"
                                                class="icon-chevron-down icon-size-14"></span>
                                            <span *ngIf="partitionServer.opened"
                                                class="icon-chevron-up icon-size-14"></span></a>
                                    </div>
                                </td>

                                <td class="cellContent" style="border: 1px solid lightgray;">
                                    {{partitionServer?.power?.inputPower |number :'1.1-1'}}</td>
                                <td class="cellContent" style="border: 1px solid lightgray;">
                                    {{partitionServer?.power?.inputCurrentLoad |number :'1.2-2'}}</td>
                                <td class="cellContent" *ngIf="projectUnit == 'Imperial'"
                                    style="border: 1px solid lightgray;">
                                    {{partitionServer?.power?.inputCooling?.imperialCooling | number:'1.1-1'}}</td>
                                <td class="cellContent" *ngIf="projectUnit == 'Metric'"
                                    style="border: 1px solid lightgray;">
                                    {{partitionServer?.power?.inputCooling?.imperialCooling | number:'1.1-1'}}</td>
                                <td class="cellContent" style="border: 1px solid lightgray;">
                                    {{partitionServer?.power?.maxInputPower | number:'1.1-1'}}</td>
                                <td class="cellContent" style="border: 1px solid lightgray;">
                                    {{partitionServer?.power?.inputCurrentMax |number:'1.2-2'}}</td>
                                <td class="cellContent" style="border: 1px solid lightgray;">
                                    {{partitionServer?.power?.idleInputPower |number:'1.1-1'}}</td>
                                <td class="cellContent" style="border: 1px solid lightgray;">
                                    {{partitionServer?.power?.inputCurrentIdle |number:'1.2-2'}}</td>
                                <td class="cellContent"
                                    *ngIf="projectUnit == 'Imperial' && partitionServer?.power?.legacyPlatform == true"
                                    style="border: 1px solid lightgray;">{{partitionServer?.power?.airflowImperial
                                    |number:'1.1-1'}} </td>
                                <td class="cellContent"
                                    *ngIf="projectUnit == 'Metric' && partitionServer?.power?.legacyPlatform == true"
                                    style="border: 1px solid lightgray;">{{partitionServer?.power?.airflowMetric
                                    |number:'1.1-1'}} </td>
                                <td class="cellContent"
                                    *ngIf="projectUnit == 'Imperial' && partitionServer?.power?.legacyPlatform == false"
                                    style="border: 1px solid lightgray;">
                                    {{partitionServer?.power?.airFlowRange?.minAirflowImperial |number:'1.1-1'}} -
                                    {{partitionServer?.power?.airFlowRange?.maxAirflowImperial |number:'1.1-1'}} </td>
                                <td class="cellContent"
                                    *ngIf="projectUnit == 'Metric' && partitionServer?.power?.legacyPlatform == false"
                                    style="border: 1px solid lightgray;">
                                    {{partitionServer?.power?.airFlowRange?.minAirflowMetric |number:'1.1-1'}} -
                                    {{partitionServer?.power?.airFlowRange?.maxAirflowMetric |number:'1.1-1'}} </td>
                                <td class="cellContent" style="border: 1px solid lightgray;">
                                    {{partitionServer?.power?.acousticsRange?.minAcoustics |number:'1.1-1'}} -
                                    {{partitionServer?.power?.acousticsRange?.maxAcoustics |number:'1.1-1'}} </td>
                                <td class="cellContent" *ngIf="projectUnit == 'Imperial'"
                                    style="border: 1px solid lightgray;">{{partitionServer?.power?.weightImperial
                                    |number:'1.1-1'}}</td>
                                <td class="cellContent" *ngIf="projectUnit == 'Metric'"
                                    style="border: 1px solid lightgray;">{{partitionServer?.power?.weightMetric
                                    |number:'1.1-1'}}</td>
                                <td class="cellContent" style="border: 1px solid lightgray;">{{
                                    displayFormattedRMode(partitionServer?.config?.redundancyMode) }}</td>
                            </tr>
                            <tr id="detailsRow" [hidden]="!partitionServer.opened">
                                <td class="server-name" style="border: 1px solid lightgray;">
                                    {{partitionServer?.displayName}}
                                </td>
                                <td colspan="12" style="border: 1px solid lightgray;">
                                    <div class="row col-md-12"
                                        *ngIf="partitionServer?.config?.voltageName && partitionServer?.config?.voltageValue > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'INPUT_VOLTAGE' |translate}} </div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">{{partitionServer?.config?.voltageName}} </span>
                                    </div>
                                    <div class="row col-md-12"
                                        *ngIf="partitionServer?.config?.processorCount && partitionServer?.config?.processorCount > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'PROCESSOR' |translate}} </div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">({{partitionServer?.config?.processorCount}})
                                            {{partitionServer?.config?.processorTypeName}} </span>
                                    </div>
                                    <div class="row col-md-12" *ngIf="partitionServer?.config?.memory.length > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'MEMORY' |translate}} </div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">
                                            <span *ngFor="let memory of partitionServer?.config?.memory; let last = last">
                                                ({{memory?.memoryCount}}) {{memory?.memoryTypeName}}
                                                {{getServerMemoryConfig(partitionServer)}} <span *ngIf="!last">,</span> </span>
                                        </span>
                                    </div>
                                    <div class="row col-md-12"
                                        *ngIf="partitionServer?.config?.powerSupplyCount && partitionServer?.config?.powerSupplyCount > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'POWER_SUPPLY' |translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">({{partitionServer?.config?.powerSupplyCount}})
                                            {{partitionServer?.config?.powerSupplyTypeName}} </span>
                                    </div>
                                    <div class="row col-md-12"
                                        *ngIf="partitionServer?.config?.dedicatedStorage.length > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'STORAGE_CONTROLLER'|translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">
                                            <span *ngFor="let ds of partitionServer?.config?.dedicatedStorage; let last = last">
                                                ({{ds.mezzanineControllerCount}}) {{ds.mezzanineControllerTypeName}} <span *ngIf="!last">,</span>
                                            </span>
                                        </span>
                                    </div>

                                    <div class="row col-md-12" *ngIf="partitionServer?.config?.storage.length > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'STORAGE'|translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">
                                            <span *ngFor="let storage of partitionServer?.config?.storage; let last = last">
                                                ({{storage.storageCount}}) {{storage.storageTypeName}} <span *ngIf="!last">,</span> </span>
                                        </span>
                                    </div>
                                    <div class="row col-md-12" *ngIf="partitionServer?.config?.adaptors.length > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'ADAPTER'|translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">
                                            <span *ngFor="let adaptor of partitionServer?.config?.adaptors; let last = last">
                                                ({{adaptor.adaptorCount}}) {{adaptor.adaptorTypeName}} <span *ngIf="!last">,</span> </span>
                                        </span>
                                    </div>
                                    <div class="row col-md-12"
                                        *ngIf="partitionServer?.config?.iomCount && partitionServer?.config?.iomCount > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'IO_MODULE'|translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">({{partitionServer?.config?.iomCount}})
                                            {{partitionServer?.config?.ioModuleTypeName}} </span>
                                    </div>
                                    <div class="row col-md-12"
                                        *ngIf="partitionServer?.config?.activePorts && partitionServer?.config?.activePorts > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'PORTS_CONFIGURED'|translate}} </div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">{{partitionServer?.config?.activePorts}} </span>
                                    </div>
                                    <div class="row col-md-12"
                                        *ngFor="let additionalComp of getAdditionalComponents(partitionServer)">
                                        <span class="col-md-2" *ngIf="additionalComp.length > 0">
                                            <div class="row">
                                                <div class="col-10">{{ additionalComp[0].label |translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span *ngIf="additionalComp.length > 0" class="col-md-10">
                                            <span *ngFor="let comp of additionalComp; let last = last">({{comp.quantity}})
                                                {{comp.configuration}} <span *ngIf="!last">,</span> </span>
                                        </span>
                                    </div>
                                    <div *ngIf="partitionServer?.config?.fanPolicyDto">
                                        <div class="row col-md-12">
                                            <span class="col-md-2">
                                                <div class="row">
                                                    <div class="col-10">{{ 'FAN_POLICY' | translate }} </div>
                                                    <div class="col-2">:</div>
                                                </div>
                                            </span>
                                            <span class="col-md-10">{{partitionServer?.config?.fanPolicyDto?.fanPolicy
                                                }} </span>
                                        </div>
                                        <div class="row col-md-12">
                                            <span class="col-md-2">
                                                <div class="row">
                                                    <div class="col-10">{{ 'FAN_FAILURE' | translate }} </div>
                                                    <div class="col-2">:</div>
                                                </div>
                                            </span>
                                            <span class="col-md-10">{{partitionServer?.config?.fanPolicyDto?.fanFailure
                                                }} </span>
                                        </div>
                                        <div class="row col-md-12">
                                            <span class="col-md-2">
                                                <div class="row">
                                                    <div class="col-10">{{ 'OPERATING_TEMPERATURE' | translate }} </div>
                                                    <div class="col-2">:</div>
                                                </div>
                                            </span>
                                            <span
                                                class="col-md-10">{{partitionServer?.config?.fanPolicyDto?.opTemprature
                                                }}°C </span>
                                        </div>
                                    </div>
                                    <div class="row col-md-12"
                                        *ngIf="partitionServer?.power?.systemWorkloadPercent && partitionServer?.power?.systemWorkloadPercent>0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'SYSTEM_WORKLOAD_PERCENT'|translate}} </div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">{{partitionServer?.power?.systemWorkloadPercent | number:'1.2-2'}} %
                                        </span>
                                    </div>
                                    <div class="row col-md-12"
                                    *ngIf="doesServerHaveRedundancyError(partitionServer, true)[0] == true">
                                    <span class="col-md-2">
                                        <div class="row">
                                            <div class="col-10">{{doesServerHaveRedundancyError(partitionServer, true)[1].title}}</div>
                                            <div class="col-2">:</div>
                                        </div>
                                    </span>
                                    <span class="col-md-10" >{{doesServerHaveRedundancyError(partitionServer, true)[1].message}}
                                    </span>
                                </div>
                                <div class="row col-md-12"
                                    *ngIf="doesServerHaveRedundancyError(partitionServer, true)[0] == true && doesServerHaveRedundancyError(partitionServer, true)[1].recommendation != null">
                                    <span class="col-md-2">
                                        <div class="row">
                                            <div class="col-10">{{doesServerHaveRedundancyError(partitionServer, true)[1].recommendation_title}}</div>
                                            <div class="col-2">:</div>
                                        </div>
                                    </span>
                                    <span class="col-md-10" >{{doesServerHaveRedundancyError(partitionServer, true)[1].recommendation}}
                                    </span>
                                </div>

                                </td>
                            </tr>
                            <tr *ngFor="let blade of partitionServer?.blades" [hidden]="!partitionServer.opened">
                                <td class="server-name" style="border: 1px solid lightgray;">
                                    {{blade?.config?.quantity}} X {{blade?.displayName}}
                                </td>
                                <td colspan="12" style="border: 1px solid lightgray;">
                                    <div class="row col-md-12">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'PROCESSOR'|translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">({{blade?.config?.processorCount}})
                                            {{blade?.config?.processorTypeName}} </span>
                                    </div>
                                    <div class="row col-md-12" *ngIf="blade?.config?.memory.length > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'MEMORY'|translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">
                                            <span *ngFor="let memory of blade?.config?.memory; let last = last">({{memory?.memoryCount}})
                                                {{memory?.memoryTypeName}} {{getServerMemoryConfig(blade)}} <span *ngIf="!last">,</span> </span>
                                        </span>
                                    </div>

                                    <div class="row col-md-12" *ngIf="blade?.config?.dedicatedStorage.length > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'STORAGE_CONTROLLER'|translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">
                                            <span *ngFor="let ds of blade?.config?.dedicatedStorage; let last = last">
                                                ({{ds.mezzanineControllerCount}}) {{ds.mezzanineControllerTypeName}} <span *ngIf="!last">,</span>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="row col-md-12" *ngIf="blade?.config?.storage.length >0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'STORAGE'|translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">
                                            <span
                                                *ngFor="let storage of blade?.config?.storage; let last = last">({{storage.storageCount}})
                                                {{storage.storageTypeName}} <span *ngIf="!last">,</span> </span>
                                        </span>
                                    </div>
                                    <div class="row col-md-12" *ngIf="blade?.config?.adaptors.length > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'ADAPTER'|translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">
                                            <span
                                                *ngFor="let adapter of blade?.config?.adaptors; let last = last">({{adapter.adaptorCount}})
                                                {{adapter.adaptorTypeName}} <span *ngIf="!last">,</span> </span>
                                        </span>
                                    </div>
                                    <div class="row col-md-12"
                                        *ngFor="let additionalComp of getAdditionalComponents(blade)">
                                        <span class="col-md-2" *ngIf="additionalComp.length > 0">
                                            <div class="row">
                                                <div class="col-10">{{ additionalComp[0].label |translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span *ngIf="additionalComp.length > 0" class="col-md-10">
                                            <span *ngFor="let comp of additionalComp; let last = last">({{comp.quantity}})
                                                {{comp.configuration}} <span *ngIf="!last">,</span> </span>
                                        </span>
                                    </div>
                                    <div class="row col-md-12"
                                        *ngIf="blade?.config?.systemWorkloadPercent && blade?.config?.systemWorkloadPercent>0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'SYSTEM_WORKLOAD_PERCENT'|translate}} </div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">{{blade?.config?.systemWorkloadPercent }}%
                                        </span>
                                    </div>
                            </tr>
                            <tr *ngFor="let node of partitionServer?.nodes" [hidden]="!partitionServer.opened">
                                <td class="server-name" style="border: 1px solid lightgray;">
                                    {{node?.config?.quantity}} X {{node?.displayName}}
                                </td>
                                <td colspan="12" style="border: 1px solid lightgray;">
                                    <div class="row col-md-12" *ngIf="node?.config?.processorCount > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'PROCESSOR'|translate}} </div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">({{node?.config?.processorCount}})
                                            {{node?.config?.processorTypeName}} </span>
                                    </div>
                                    <div class="row col-md-12" *ngIf="node?.config?.memory.length > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'MEMORY'|translate}} </div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">
                                            <span *ngFor="let memory of node?.config?.memory; let last = last"> ({{memory?.memoryCount}})
                                                {{memory?.memoryTypeName}} {{getServerMemoryConfig(node)}} <span *ngIf="!last">,</span> </span>
                                        </span>
                                    </div>

                                    <div class="row col-md-12" *ngIf="node?.config?.dedicatedStorage.length > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'STORAGE_CONTROLLER'|translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">
                                            <span *ngFor="let ds of node?.config?.dedicatedStorage; let last = last">
                                                ({{ds.mezzanineControllerCount}}) {{ds.mezzanineControllerTypeName}} <span *ngIf="!last">,</span>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="row col-md-12" *ngIf="node?.config?.storage.length >0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'STORAGE'|translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">
                                            <span
                                                *ngFor="let storage of node?.config?.storage; let last = last">({{storage.storageCount}})
                                                {{storage.storageTypeName}} <span *ngIf="!last">,</span> </span>
                                        </span>
                                    </div>
                                    <div class="row col-md-12" *ngIf="node?.config?.adaptors.length > 0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'ADAPTER'|translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">
                                            <span
                                                *ngFor="let adapter of node?.config?.adaptors; let last = last">({{adapter.adaptorCount}})
                                                {{adapter.adaptorTypeName}} <span *ngIf="!last">,</span> </span>
                                        </span>
                                    </div>
                                    <div class="row col-md-12"
                                        *ngFor="let additionalComp of getAdditionalComponents(node)">
                                        <span class="col-md-2" *ngIf="additionalComp.length > 0">
                                            <div class="row">
                                                <div class="col-10">{{ additionalComp[0].label |translate}}</div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span *ngIf="additionalComp.length > 0" class="col-md-10">
                                            <span *ngFor="let comp of additionalComp; let last = last">({{comp.quantity}})
                                                {{comp.configuration}} <span *ngIf="!last">,</span> </span>
                                        </span>
                                    </div>
                                    <div class="row col-md-12"
                                        *ngIf="node?.config?.systemWorkloadPercent && node?.config?.systemWorkloadPercent>0">
                                        <span class="col-md-2">
                                            <div class="row">
                                                <div class="col-10">{{'SYSTEM_WORKLOAD_PERCENT'|translate}} </div>
                                                <div class="col-2">:</div>
                                            </div>
                                        </span>
                                        <span class="col-md-10">{{node?.config?.systemWorkloadPercent }}%
                                        </span>
                                    </div>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        <!-- end dynamic  -->

    </div>
</div>