import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, from } from "rxjs";
import { ProjectService } from "src/app/services/project.service";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { errorCodes } from "src/constants/errorCodes";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "src/app/services/api.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Component({
  selector: "app-project-details-summary",
  templateUrl: "./project-details-summary.component.html",
  styleUrls: ["./project-details-summary.component.css"],
})
export class ProjectDetailsSummaryComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  projectData: any;
  projectId: string;
  createDate: string;
  updateDate: string;
  globalUnit: string;
  powerCost: number = 0.105;
  emissionFactor = 0.1;
  country: string;
  power: any;
  current: any;
  powerCostForm: UntypedFormGroup;
  istoasterActive: boolean = false;
  isEdit = false;
  isValidName: boolean = true;
  locale: string = 'en';
  isDefaultPowerSet: boolean = false;
  localeString: any = {
    "en": "en-US",
    "ja": "ja-JP",
    "de": "de-DE",
    "fr": "fr-FR",
    "ko": "ko-KR"
  }
  projectError: boolean = false;
  emissionInfo: any;
  countries: string[];
  userData: any;
  isUserLoggedIn: boolean = false;
  projectMethod: string;
  emissionFactorPrev: number;
  powerCostPrev: number;
  powerCostInfo: string = 'User Power Cost Info';
  @BlockUI() blockUI: NgBlockUI;
  projectType: string = 'UCS.UserProjects';
  constructor(private router: Router,
    private toaster: ToasterService,
    private projectService: ProjectService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private apiService: ApiService,
  ) { }


  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.isUserLoggedIn = this.userData ? true : false;
    if (this.isUserLoggedIn) this.getEmissionInfo();
    else {
      this.powerCost = 0.105;
      this.isDefaultPowerSet = true;
    }
    this.projectType = this.router.url.includes('ucs-resizer') ? 'UCS.SizerProjects' : 'UCS.UserProjects';
    this.powerCostForm = this.formBuilder.group({
      country: [this.isUserLoggedIn ? 'DEFAULT' : 'NA', Validators.required],
      powerCost: [this.powerCost, [Validators.required, Validators.min(0.0000001)]],
      emissionFactor: [this.emissionFactor, [Validators.required]]
    })
    this.getProjectDetails();
  }
  getEmissionInfo() {
    this.apiService.getMethod('admin/emission').subscribe(response => {
      this.blockUI.stop();
      this.emissionInfo = response;
      this.countries = Object.keys(this.emissionInfo);
    }, (error) => {
      console.error(error);
      this.blockUI.stop();
      let errorMsg = error?.error
        ? error.error?.error
        : errorCodes.GENERIC_ERROR_MESSAGE;
      this.toaster.show(
        "error",
        errorMsg,
        error ? error?.message : ""
      );
    })
  }
  getProjectDetails() {
    this.subscriptions.add(
      this.projectService.currentUnit.subscribe((unit: any) => {
        this.globalUnit = unit ? unit : localStorage.getItem("UCS.GlobalMeasurmentUnit");
      })
    );
    this.subscriptions.add(
      this.projectService.getProjectMethod().subscribe((method: string) => {
        this.projectMethod = method ? method : 'Manual';
        // if (this.projectMethod == 'Manual' && this.powerCostForm) {
        //   this.powerCostForm.controls['country'].setValue('DEFAULT');
        // } else {
        //   this.powerCostForm.controls['country'].setValue(null);
        // }
      })
    );
    this.subscriptions.add(
      this.projectService.getPowerCost().subscribe((cost: any) => {
        this.getProjectData();
        this.projectData.powerUnitCost = cost ? cost.cost : this.projectData?.powerUnitCost;
        if (cost && cost.fromRegionChange) {
          this.powerCost = cost.displayCost ? cost.displayCost : 0.105;
        } else {
          this.powerCost = this.projectData?.powerUnitCost;
        }
        // this.powerCost = this.projectData?.powerUnitCost;
        this.powerCostForm.controls['powerCost'].setValue(this.powerCost);
      })
    );
    this.subscriptions.add(
      this.projectService.getEmissionFactor().subscribe((emissionFactor: any) => {
        this.getProjectData();
        if (emissionFactor && emissionFactor.fromRegionChange) {
          this.projectData.emissionFactor = emissionFactor ? emissionFactor.factor : this.projectData?.emissionFactor;
          this.emissionFactor = emissionFactor.factor;
          this.powerCostForm.controls['emissionFactor'].setValue(this.emissionFactor);
        } else if (emissionFactor && !emissionFactor.regionChange) {
          this.projectData.emissionFactor = emissionFactor ? emissionFactor.displayFactor : this.projectData?.emissionFactor;
          this.emissionFactor = emissionFactor.displayFactor;
          this.powerCostForm.controls['emissionFactor'].setValue(this.emissionFactor);
        } else {
          this.emissionFactor = this.projectData?.emissionFactor;
          this.powerCostForm.controls['emissionFactor'].setValue(this.emissionFactor);
        }
      })
    );
    this.subscriptions.add(
      this.projectService.getCountry().subscribe((country: any) => {
        if (country && country.length > 0 && this.isUserLoggedIn) {
          this.getProjectData();
          this.projectData.country = country ? country : this.projectData?.country;
          this.country = this.projectData?.country ? this.projectData?.country : localStorage.getItem('UCS.GlobalRegion');;
          this.powerCostForm.controls['country'].setValue(this.country);
        }
      })
    );
    this.subscriptions.add(this.projectService.getCalculatedPower().subscribe(data => {
      this.getProjectData();
      this.powerCost = this.powerCost ? this.powerCost : this.projectData?.powerUnitCost;
      this.emissionFactor = (this.isUserLoggedIn || (!this.isUserLoggedIn && this.projectData.powerCostMethod == 'Manual')) ? this.projectData?.emissionFactor ? this.projectData?.emissionFactor : this.emissionFactor : 399;
      this.powerCostForm.controls['emissionFactor'].setValue(this.emissionFactor);
      this.country = this.country ? this.country : this.projectData?.country ? this.projectData?.country : localStorage.getItem('UCS.GlobalRegion');
      if (this.country && this.country !== 'Select Region' && this.isUserLoggedIn) {
        this.changeCountry(this.country, true);
      } else {
        this.powerCost = 0.105;
        this.isDefaultPowerSet = true;
        this.changePowerCost({ target: { value: this.powerCost } });
      }
      this.powerCostForm.controls['powerCost'].setValue(this.powerCost);
      this.power = data.length ? data[0] : this.projectData?.power?.inputPower;
      this.current = data.length ? data[1] : this.projectData?.power?.inputCurrent;
      this.locale = this.translate.getBrowserLang();
      this.createDate = new Date(this.projectData?.createdDate).toLocaleDateString(this.localeString[this.locale], { year: 'numeric', month: 'short', day: 'numeric' });
      this.updateDate = new Date(this.projectData?.updatedDate).toLocaleDateString(this.localeString[this.locale], { year: 'numeric', month: 'short', day: 'numeric' });
    }));
    this.subscriptions.add(this.projectService.setCalculatedPower([]));
    this.subscriptions.add(this.projectService.getProjectError().subscribe(data => {
      this.projectError = data;
    }))
  }
  getProjectData() {
    // this.projectId = localStorage.getItem("currentProjectId");
    this.projectId = sessionStorage.getItem("myProjectId");
    this.projectData = JSON.parse(localStorage.getItem(`${this.projectType}-${this.projectId}`));
    this.projectMethod = this.projectData.powerCostMethod;
    this.setPrevFactors();
    this.checkProjectExists();
  }
  setPrevFactors() {
    this.powerCostPrev = this.projectData.factorValuesPrev ? this.projectData.factorValuesPrev.powerCost : 0.105;
    this.emissionFactorPrev = this.projectData.factorValuesPrev ? this.projectData.factorValuesPrev.emissionFactor : 399;
  }
  checkProjectExists() {
    let projectType = this.router.url.includes('ucs-resizer') ? 'UCS.SizerProjects' : 'UCS.UserProjects';
    if (!JSON.parse(localStorage.getItem(`${projectType}-${this.projectId}`))) {
      if (projectType.includes('Sizer')) {
        this.router.navigate(["/ucs-resizer/projectList"]);
      } else this.router.navigate(["/projectList"]);
    }
  }
  globalUnitChange(unit: string) {
    this.checkProjectExists();
    this.projectData = JSON.parse(localStorage.getItem(`${this.projectType}-${this.projectId}`));
    if (unit == "Metric") {
      this.projectData.measurementUnit = 'Metric'
      localStorage.setItem(`${this.projectType}-${this.projectId}`, JSON.stringify(this.projectData));
      localStorage.setItem('UCS.GlobalMeasurmentUnit', 'Metric');
    } else {
      this.projectData.measurementUnit = 'Imperial'
      localStorage.setItem(`${this.projectType}-${this.projectId}`, JSON.stringify(this.projectData));
      localStorage.setItem('UCS.GlobalMeasurmentUnit', 'Imperial');
    }
    this.subscriptions.add(this.projectService.changeUnit(unit));
  }
  projectPowerCostMethodChange(method: string) {
    this.checkProjectExists();
    this.projectData = JSON.parse(localStorage.getItem(`${this.projectType}-${this.projectId}`));
    let a, b;
    if (method == "ByRegion") {
      if (this.projectData.country) {
        this.country = this.projectData.country;
        this.powerCostForm.controls['country'].setValue(this.projectData.country);
        let countryInfo: any[] = Object.entries(this.emissionInfo).find(c => c[0] == this.country);
        let factors: any[] = countryInfo[1];
        a = this.powerCost;
        b = this.emissionFactor;
        if (factors["Emission Factor"]) {
          let emissionFactor = factors["Emission Factor"].factorvalue;
          this.changeEmissionFactor({ target: { value: emissionFactor } }, false);
        }
        if (factors["Cost Factor"]) {
          let costFactor = factors["Cost Factor"].factorvalue;
          this.changePowerCost({ target: { value: costFactor } }, false);
        }
        this.powerCostPrev = a, this.emissionFactorPrev = b;
        this.projectData['factorValuesPrev'] = { powerCost: this.powerCostPrev, emissionFactor: this.emissionFactorPrev };
      } else {
        this.powerCostForm.controls['country'].setValue(null);
      }
      this.projectData.powerCostMethod = 'ByRegion';
      localStorage.setItem(`${this.projectType}-${this.projectId}`, JSON.stringify(this.projectData));
    } else {
      this.powerCostForm.controls['country'].setValue('DEFAULT');
      this.changeEmissionFactor({ target: { value: this.emissionFactorPrev ? this.emissionFactorPrev : 399 } }, false);
      this.changePowerCost({ target: { value: this.powerCostPrev ? this.powerCostPrev : 0.105 } }, false);
      this.projectData['factorValuesPrev'] = { powerCost: this.powerCostPrev, emissionFactor: this.emissionFactorPrev };
      this.projectData.powerCostMethod = 'Manual';
      localStorage.setItem(`${this.projectType}-${this.projectId}`, JSON.stringify(this.projectData));
    }
    this.subscriptions.add(this.projectService.setProjectMethod(method));
  }
  viewResults() {
    this.subscriptions.add(this.projectService.setCurrentChassis([null]));
    this.subscriptions.add(this.projectService.setServerEditStatus(false));
    this.subscriptions.add(this.projectService.setCurrentProjectName(null));
    localStorage.setItem("setServerEditStatus", "false");
    localStorage.setItem("setCurrentServer", JSON.stringify(null));

    sessionStorage.setItem("setServerEditStatus", "false");
    sessionStorage.setItem("setCurrentServer", JSON.stringify(null));


    // remove any existing redundancy warnings 
    this.toaster.remove(errorCodes.NON_REDUNDANT);
    this.toaster.remove(errorCodes.INSUFFICIENT_REDUNDANCY);
    this.toaster.remove(errorCodes.POWER_CAPPED);
    this.toaster.remove(errorCodes.WARNING_TITLE);
    this.toaster.remove(errorCodes.ERROR_TITLE);

    if (this.router.url.includes('admin/')) {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/admin/ucs-resizer/finalResults"]);
      } else this.router.navigate(["/admin/finalResults"]);
    } else {
      if (this.router.url.includes('ucs-resizer')) {
        this.router.navigate(["/ucs-resizer/finalResults"]);
      } else this.router.navigate(["/finalResults"]);
    }

  }
  changeCountry($event: any, init?: boolean, fromToggle?: boolean) {
    this.checkProjectExists();
    this.country = $event;
    this.projectService.setCountry(this.country);
    this.projectData.country = $event;
    localStorage.setItem(`${this.projectType}-${this.projectId}`, JSON.stringify(this.projectData));
    localStorage.setItem('UCS.GlobalRegion', this.projectData.country);
    if (init && !this.emissionInfo) {
      this.blockUI.start("Loading...");
      this.apiService.getMethod('admin/emission').subscribe(response => {
        this.blockUI.stop();
        this.emissionInfo = response;
        this.countries = Object.keys(this.emissionInfo);
        this.changeProjectFactors($event, fromToggle);
      }, (error) => {
        console.error(error);
        this.blockUI.stop();
        let errorMsg = error?.error
          ? error.error?.error
          : errorCodes.GENERIC_ERROR_MESSAGE;
        this.toaster.show(
          "error",
          errorMsg,
          error ? error?.message : ""
        );
      })
    } else {
      this.changeProjectFactors($event, fromToggle);
    }

  }
  changeProjectFactors($event, fromToggle?: boolean) {
    let countryInfo: any[] = Object.entries(this.emissionInfo).find(c => c[0] == $event);
    let factors: any[] = countryInfo[1];
    let costFactor = factors["Cost Factor"].factorvalue;
    let emissionFactor = factors["Emission Factor"].factorvalue;
    if (!this.powerCost || this.isDefaultPowerSet || fromToggle) {
      this.changePowerCost({ target: { value: costFactor } }, true);
      if (this.projectData && this.projectData.powerCostMethod == 'ByRegion') {
        this.changeEmissionFactor({ target: { value: emissionFactor } }, true);
      }
    }
  }
  changeEmissionFactor($event: any, fromRegionChange?: boolean) {
    this.checkProjectExists();
    if ($event.target.value > 0) {
      if (!fromRegionChange) {
        this.powerCostForm.get('emissionFactor').patchValue($event.target.value);
        this.emissionFactor = $event.target.value;
        this.projectService.setEmissionFactor(
          {
            factor: this.emissionFactor,
            displayFactor: this.emissionFactor,
            fromRegionChange: false
          }
        );
        this.projectData.emissionFactor = $event.target.value;
        localStorage.setItem(`${this.projectType}-${this.projectId}`, JSON.stringify(this.projectData));
      } else {
        this.powerCostForm.get('emissionFactor').patchValue($event.target.value);
        this.projectService.setEmissionFactor({
          factor: $event.target.value,
          displayFactor: this.emissionFactor,
          fromRegionChange: true
        });
        this.projectData.emissionFactor = $event.target.value;
        localStorage.setItem(`${this.projectType}-${this.projectId}`, JSON.stringify(this.projectData));
      }
    }
    if ($event.target.value <= 0) {
      if (!this.istoasterActive) {
        this.toaster.show("error", errorCodes.NONZERO_POSITIVE_VALUE_EM, '', 10000, false);
        this.istoasterActive = true;
      }
    } else {
      this.istoasterActive = false;
      this.toaster.remove(errorCodes.NONZERO_POSITIVE_VALUE_EM);
    }
  }
  changePowerCost(event: any, fromRegionChange?: boolean) {
    this.checkProjectExists();
    if (event.target.value > 0) {
      this.projectData.powerUnitCost = event.target.value;
      if (!fromRegionChange) {
        this.powerCost = event.target.value;
        this.projectService.setPowerCost({
          cost: this.powerCost,
          displayCost: this.powerCost,
          fromRegionChange: false
        });
      } else {
        this.projectService.setPowerCost({
          cost: event.target.value,
          displayCost: this.powerCost,
          fromRegionChange: true
        });
      }
      localStorage.setItem(`${this.projectType}-${this.projectId}`, JSON.stringify(this.projectData));
    }
    if (event.target.value <= 0) {
      if (!this.istoasterActive) {
        this.toaster.show("error", errorCodes.NONZERO_POSITIVE_VALUE, '', 10000, false);
        this.istoasterActive = true;
      }
    } else if (!fromRegionChange) {
      this.istoasterActive = false;
      this.toaster.remove(errorCodes.NONZERO_POSITIVE_VALUE);
    }
  }
  changeProjectName(event: any) {
    this.checkProjectExists();
    if (event.target.value.trim() == '') {
      this.isValidName = false;
      this.toaster.show("error", errorCodes.PROJECTNAME_EMPTY, '', 5000);
      return;
    } else {
      this.isEdit = false;
      this.isValidName = true;
      this.projectData.projectName = event.target.value;
    }
    this.projectService.setCurrentProjectName(this.projectData.projectName);
    localStorage.setItem(`${this.projectType}-${this.projectId}`, JSON.stringify(this.projectData));
  }
  onBlurName(event) {
    if (event.target.value != '')
      this.isEdit = false;
  }
  toggleEdit() {
    if (!this.isEdit) this.isEdit = true;
    else if (this.isValidName) this.isEdit = !this.isEdit;
    else this.isEdit = true;
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
