import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ProjectService } from "src/app/services/project.service";
import { ToasterService } from "src/app/shared/components/toast/toast.service";
import { AppConstant } from "src/constants/app.constants";
import { errorCodes } from "src/constants/errorCodes";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.css"],
})
export class CardComponent implements OnInit, OnDestroy {
  @Input() seriesValues = [];
  @Input() available = false;
  @Input() template = false;
  @Input('isChild') isChild = false;
  originalSeries: any[];
  defaultIcon = "../../../../assets/images/bladeSlot.jpg";
  defaultImage = "../../../../assets/images/Cisco-logo.png";
  resultChassis = [];
  subscription: Subscription = new Subscription();
  resultchassisLength: number;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private _sanitizer: DomSanitizer,
    public toaster: ToasterService,
  ) { }

  ngOnInit(): void {
    this.originalSeries = this.seriesValues;
    this.getCardData(this.seriesValues, this.available);
  }

  getCardData(seriesValues: any[], available: boolean) {
    if (available == undefined) { //delete condition
      this.resultChassis = seriesValues;
    }
    else {
      this.resultChassis = seriesValues.filter((value) => {
        let avb = value.discont == 0 ? true : false;
        return avb == available;
      });
    }
    for (let chassis of this.resultChassis) {
      if (chassis.attributes) {
        for (let element of chassis.attributes) {
          element.displayName = this.translate.instant(element.label);
          if (element.label == "dimmSlots") {
            element.url = "../../../../assets/images/dimmSlots.jpg";
          }
          if (element.label == "pciSlots" || element.label == "numIomSlots") {
            //I/O slots
            element.url = "../../../../assets/images/iOSlots.jpg";
          }
          if (element.label == "maxPorts" || element.label == "cpuSockets") {
            //ports
            element.url = "../../../../assets/images/cpuSockets.jpg";
          }
          if (element.label == "height") {
            element.url = "../../../../assets/images/height.jpg";
          }
          if (
            element.label == "maxPsus" ||
            element.label == "" ||
            element.label == "numPsuSlots" ||
            element.label == "maxPsuSlots"
          ) {
            //No of powerSupply
            element.url = "../../../../assets/images/noOfPower.jpg";
          }
          if (element.label == "maxDisks") {
            element.url = "../../../../assets/images/storageBays.jpg";
          }
          if (
            element.label == "numBladeSlots" ||
            element.label == "maxCartridgeSlots"
          ) {
            //|| nodes
            element.url = "../../../../assets/images/bladeSlot.jpg";
          }
        }
      }
    }
    this.resultchassisLength = this.resultChassis.length;
  }
  getImage(image) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpeg;base64, ${image}`);
  }
  //to toggle between available and discontinued servers
  ngOnChanges(changes: SimpleChanges) {
    const currentAvailable: SimpleChange = changes.available;
    let bool: boolean = changes.available?.currentValue ? true : false;
    if (changes?.seriesValues) {
      let av = this.available;
      if (currentAvailable?.currentValue) av = currentAvailable?.currentValue;
      this.getCardData(
        changes?.seriesValues?.currentValue,
        av);
    }
    else {
      this.getCardData(this.seriesValues, bool);
    }
  }

  onConfigure(
    basePid: string,
    id: string,
    voltage: string,
    template: any,
    item: any
  ) {
    localStorage.setItem("currentProductId", basePid);
    localStorage.setItem("currentSequenceId", id);
    localStorage.setItem("voltage", voltage);
    localStorage.setItem("setCurrentServer", JSON.stringify(null));

    sessionStorage.setItem("currentProductId", basePid);
    sessionStorage.setItem("currentSequenceId", id);
    sessionStorage.setItem("voltage", voltage);
    sessionStorage.setItem("setCurrentServer", JSON.stringify(null));
    // this.subscription.add(this.projectService.setCurrentServer(null));
    this.router.navigate(["serverDetails"], {
      relativeTo: this.activatedRoute,
    });
    if (template) {
      this.subscription.add(
        this.projectService.setCurrentServer([item, "template"])
      );
      localStorage.setItem("setCurrentServer", JSON.stringify(item));
      sessionStorage.setItem("setCurrentServer", JSON.stringify(item));
      this.projectService.setServerFlag(false); //true
    } else {
      this.projectService.setServerFlag(false);
    }
    // remove any existing redundancy warnings 
    this.toaster.remove(errorCodes.NON_REDUNDANT);
    this.toaster.remove(errorCodes.INSUFFICIENT_REDUNDANCY);
    this.toaster.remove(errorCodes.POWER_CAPPED);
    this.toaster.remove(errorCodes.ERROR_TITLE);
    this.toaster.remove(errorCodes.WARNING_TITLE);
  }

  onDeleteTemplate(templateId, type) {
    const localStorageKey = AppConstant.TEMPLATE + type;
    this.deleteTemplate(localStorageKey, templateId);
  }
  deleteTemplate(localStorageKey: string, templateId: string) {
    let tempTemplates = JSON.parse(localStorage.getItem(localStorageKey));
    this.resultChassis = this.resultChassis.filter(item => item.templateId ? item.templateId !== templateId : true);
    var removeIndex = tempTemplates.templates
      .map((item) => {
        return item.templateId;
      })
      .indexOf(templateId);
    tempTemplates.templates.splice(removeIndex, 1);
    localStorage.setItem(localStorageKey, JSON.stringify(tempTemplates));
    this.subscription.add(this.projectService.setTemplateFlag(true));
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
